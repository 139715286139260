import React from 'react';
import './ActivityContent.scss';
import ActivityImage from '../ActivityImage/ActivityImage';
import ActivityImageCopywrite from '../ActivityImageCopywrite/ActivityImageCopywrite';
import ActivitySection from '../ActivitySection/ActivitySection';
import ActivityInfo from '../ActivityInfo/ActivityInfo';
import ActivityDescription from '../ActivityDescription/ActivityDescription';
import ActivityAttributes from '../ActivityAttributes/ActivityAttributes';
import ActivityOpeningTimes from '../ActivityOpeningTimes/ActivityOpeningTimes';
import ActivityDates from '../ActivityDates/ActivityDates';
import ActivityDetailTitle from '../ActivityDetailTitle/ActivityDetailTitle';
import SocialShare from '../SocialShare/SocialShare';
import Activity from '../../models/Activity.model';
import BookmarkIcon from '../BookmarkIcon/BookmarkIcon';
import ArrowBack from '../ArrowBack/ArrowBack';
import AddToCalendarIcon from '../AddToCalendarIcon/AddToCalendarIcon';
import ChangeRequest from '../ChangeRequest/ChangeRequest';
import Spinner from '../Spinner/Spinner';
import ActivityMap from '../ActivityMap/ActivityMap';
import ActivityImagePlay from '../ActivityImagePlay/ActivityImagePlay';
import _ from 'lodash';

type ActivityContentProps = {
    activity: Activity | null
}

const ActivityContent = ({activity}: ActivityContentProps) => {

    const hasImages = activity && ((activity.images && activity.images.length > 0) || (activity.imageUrls && activity.imageUrls.length >0));
    const hasOpeningTimes = activity && activity.dates && activity.dates.type === "OPENINGTIMES";
    const videoUrl = activity && activity.urls ? _.find(activity.urls, {type: "VIDEO"}) : null;

    return (
        <React.Fragment>
        <div className="ActivityContent d-md-none">
            {activity &&
                <React.Fragment>
                    { videoUrl &&
                        <ActivityImagePlay activity={activity}
                                           imageSizeSm={{height: 241, width: 417}}
                                           imageSizeMd={{height: 241, width: 417}} />
                    }
                    { !videoUrl &&
                        <ActivityImage activity={activity}
                                       imageSizeSm={{height: 241, width: 417}}
                                       imageSizeMd={{height: 241, width: 417}} />
                    }
                    { activity && activity.images && activity.images.length > 0 && activity.images[0].alternativeText &&
                        <ActivityImageCopywrite text={activity.images[0].alternativeText} />
                    }
                  <ActivitySection children={<ActivityInfo activity={activity}/>}/>
                  <ActivitySection children={<ChangeRequest />}/>
                  <ActivitySection children={<ActivityDescription activity={activity}/>}/>
                  <ActivitySection children={<ActivityAttributes activity={activity}/>}/>
                    {hasOpeningTimes &&
                    <ActivitySection children={<ActivityOpeningTimes activity={activity}/>}/>
                    }
                    {!hasOpeningTimes &&
                    <ActivitySection children={<ActivityDates activity={activity}/>}/>
                    }
                    { activity.location &&
                        <ActivitySection>
                          <ActivityMap activity={activity} />
                        </ActivitySection>
                    }
                  <ActivitySection children={
                      <div>
                          <div className="d-md-none"><ActivityDetailTitle text="Diesen Event teilen"/></div>
                          <SocialShare text={activity.title}/>
                      </div>
                  }/>
                </React.Fragment>
            }
            {!activity &&
                <Spinner/>
            }
        </div>
        <div className="d-none d-md-block">
            <div className={hasImages ? "ActivityContent" : "ActivityContent ActivityContent--no-image"}>
                <div className="ActivityContent__white-background">
                </div>
                {activity &&
                    <React.Fragment>
                      <div className="ActivityContent__arrow-back">
                        <ArrowBack/>
                      </div>
                      <div className={hasImages ? "ActivityContent__info" : "ActivityContent__info--no-image"}>
                        <ActivityInfo activity={activity} horizontal={!hasImages}/>
                      </div>
                        { hasImages &&
                            <div className="ActivityContent__image">
                                { !videoUrl &&
                                    <ActivityImage activity={activity}
                                                   imageSizeSm={{height: 540, width: 550}}
                                                   imageSizeMd={{height: 540, width: 550}} />
                                }
                                { videoUrl &&
                                    <ActivityImagePlay activity={activity}
                                                       imageSizeSm={{height: 540, width: 550}}
                                                       imageSizeMd={{height: 540, width: 550}} />
                                }
                                { activity && activity.images && activity.images.length > 0 && activity.images[0].alternativeText &&
                                    <ActivityImageCopywrite text={activity.images[0].alternativeText} />
                                }
                            </div>
                        }
                      <div className="ActivityContent__social-share">
                        <SocialShare text={activity.title} />
                      </div>
                      <div className="ActivityContent__detail-description">
                        <ActivityDescription activity={activity}/>
                          { (activity.futureDates && activity.futureDates.length > 0 || hasOpeningTimes) &&
                          <div className="ActivityContent__divider"></div>
                          }
                          {hasOpeningTimes &&
                          <ActivityOpeningTimes activity={activity}/>
                          }
                          {!hasOpeningTimes &&
                          <ActivityDates activity={activity}/>
                          }
                          <div className="ActivityContent__divider"></div>
                          { activity.location &&
                            <ActivityMap activity={activity} />
                          }
                      </div>
                      <div className="ActivityContent__detail-attributes">
                        <ActivityAttributes activity={activity}/>
                      </div>
                      <div className="ActivityContent__actions">
                        <div className="Action-icons"><AddToCalendarIcon activity={activity} size="lg" /><BookmarkIcon activity={activity} size="lg" /></div>
                      </div>
                      <div className="ActivityContent__change-request">
                        <div className="Action-btn">
                          <ChangeRequest />
                        </div>
                      </div>
                    </React.Fragment>
                }
                {!activity &&
                    <div className="ActivityContent__spinner">
                      <Spinner/>
                    </div>
                }
            </div>
        </div>
        </React.Fragment>
    );
};

export default ActivityContent;
