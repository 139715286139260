import React, { useEffect } from 'react';
import './ListView.scss';
import { DEFAULT_PAGE_SIZE } from '../../utils/SearchUtils';
import Activity from '../../models/Activity.model';
import Activities from '../Activities/Activities';
import SearchData from '../../models/SearchData.model';
import FormResult from '../../models/FormResult.model';
import { Blog, BlogMap } from '../../models/Blog.model';

type ListViewProps = {
    activities: Activity[],
    selectedActivity: Activity | null,
    activitiesLoaded: boolean,
    blogs: BlogMap,
    resultCount: number,
    showMore: () => void,
    isLoadingMore: boolean,
    searchData: SearchData,
    subscribeToNewsletter: (email: string) => Promise<FormResult>,
    mailFeedback: (email: string, subject: string, message: string) => Promise<FormResult>
}


const ListView = ({activities, blogs, selectedActivity, resultCount, showMore, isLoadingMore, activitiesLoaded, searchData, subscribeToNewsletter, mailFeedback }: ListViewProps) => {

  return <div className="ListView">
      <Activities activities={activities} blogs={blogs} subscribeToNewsletter={subscribeToNewsletter}
                  mailFeedback={mailFeedback} selectedActivity={selectedActivity} activitiesLoaded={activitiesLoaded} isLoadingMore={isLoadingMore} />
      {searchData.from + DEFAULT_PAGE_SIZE < resultCount &&
          <div className="btn-more">
              {!isLoadingMore &&
              <button type="button" className="btn btn-primary" onClick={showMore}>mehr anzeigen</button>
              }
              {isLoadingMore &&
              <button type="button" className="btn btn-primary" disabled={true}>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="btn-more__label--spinning">mehr anzeigen</span>
              </button>
              }
          </div>
      }
  </div>
};

export default ListView;
