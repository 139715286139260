import React from 'react';
import './ActivityTeaserCard.scss';
import Activity from '../../models/Activity.model';
import ActivityImage from '../ActivityImage/ActivityImage';
import ActivityInfoHighlight from '../ActivityInfoHighlight/ActivityInfoHighlight';
import { RouteComponentProps, withRouter } from 'react-router';
import { getStyleForCategoryBg } from '../../utils/CategoryUtils';

type ActivityTeaserCardProps = {
    activity: Activity | null
};

const ActivityTeaserCard = ({activity, history}: RouteComponentProps<{}> & ActivityTeaserCardProps) => {

    let link = activity ? `/activity/${activity.id}/${activity.displayDate ? activity.displayDate.format('YYYY-MM-DD') : ''}` : "#"

    return (
        <a className="card-link" href={link}>
        <div className={"ActivityTeaserCard " + getStyleForCategoryBg(activity ? activity.category : '')}>
            <div className="ActivityTeaserCard__image">
                <ActivityImage activity={activity}
                               imageSizeSm={{height: 288, width: 195}}
                               imageSizeMd={{height: 288, width: 195}} />
            </div>
            <ActivityInfoHighlight activity={activity}/>
        </div>
        </a>
    );
};

export default withRouter(ActivityTeaserCard);
