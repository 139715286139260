import React, { Component } from 'react';
import './Layout.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MobileFilter from '../MobileFilter/MobileFilter';

type LayoutProps = {
    narrowHeader?: boolean,
    pageHeaderOnMobile?: boolean,
    pageHeaderTitle?: string,
    searchBar?: boolean,
    hideOnScroll?: boolean,
    hideFooter?: boolean
};

type LayoutState = {
    prevScrollpos: number,
    visible: boolean,
    headerHeight: number
}

class Layout extends Component<LayoutProps, LayoutState> {

    constructor(props: LayoutProps) {
        super(props);
        this.state = {
            prevScrollpos: window.pageYOffset,
            visible: true,
            headerHeight: 0,
        };
    }

    handleScroll = () => {
        const DELTA = 5;
        const currentScrollPos = window.pageYOffset;
        const {prevScrollpos, headerHeight} = this.state;

        if (Math.abs(prevScrollpos - currentScrollPos) <= DELTA) {
            return;
        }

        this.setState({
            prevScrollpos: currentScrollPos,
            visible: !(currentScrollPos > prevScrollpos && currentScrollPos > headerHeight)
        });
    };

    handleHeaderHeight = () => {
        const headerElement = document.getElementById('Header-container');
        this.setState({ headerHeight: headerElement ? headerElement.clientHeight : 0 });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener("scroll", this.handleScroll);
        this.handleHeaderHeight();
    };

    componentDidUpdate(prevProps: LayoutProps) {
        if (prevProps.narrowHeader !== this.props.narrowHeader
            || prevProps.searchBar !== this.props.searchBar
            || prevProps.pageHeaderOnMobile !== this.props.pageHeaderOnMobile) {
            this.handleHeaderHeight();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    };

    render () {
        const { narrowHeader=false, pageHeaderOnMobile=false, pageHeaderTitle="", searchBar=false, hideOnScroll=true, hideFooter=false, children} = this.props;
        const { headerHeight, visible } = this.state;
        const mainContainerStyle = {
            marginTop: headerHeight,
        };
        const headerContainerStyle = {
            top: !visible && hideOnScroll ? -headerHeight : 0
        };

        return (
            <div className={searchBar ? "Layout Layout--white" : "Layout"}>
                <div id="Header-container" className="Header-container" style={headerContainerStyle}>
                    <Header narrow={narrowHeader} searchBar={searchBar} pageHeaderOnMobile={pageHeaderOnMobile} pageHeaderTitle={pageHeaderTitle} isVisible={visible}/>
                </div>
                <div className="Main-container" style={mainContainerStyle}>
                    {children}
                </div>
                {!hideFooter &&
                <Footer/>
                }
                {searchBar &&
                    <div className="Layout__filter d-md-none">
                      <MobileFilter></MobileFilter>
                    </div>
                }
            </div>
        )}
};

export default Layout;
