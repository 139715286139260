import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import Activity from '../models/Activity.model';
import { AppContext } from '../Provider';
import ActivityContent from '../components/ActivityContent/ActivityContent';
import Layout from '../components/Layout/Layout';
import moment from 'moment';

const ActivityPage = (props: any) => {
    const [activity, setActivity] = React.useState<Activity | null>(null);
    const {getActivity} = useContext(AppContext)!;
    const {history, match: {params}} = props;

    useEffect(() => {
        if (!activity) {
            if (params.activityId) {
                getActivity(params.activityId, moment(params.date, 'YYYY-MM-DD')).then((data: Activity | null) => {
                    if (data) {
                        setActivity(data);
                    } else {
                        history.push('/404');
                    }
                });
            } else {
                history.push('/404');
            }
        }
    });

    return (
        <Layout pageHeaderOnMobile={true} pageHeaderTitle="Infos zur Veranstaltung">
            <div className="fullwidth colored">
                <ActivityContent activity={activity} />
            </div>
        </Layout>
    );
};

export default withRouter(ActivityPage);
