import React, { useContext } from 'react';
import './BookmarkIcon.scss';
import Activity from '../../models/Activity.model';
import { AppContext } from '../../Provider';

type BookmarkIconProps = {
    activity: Activity,
    size?: string
}

const BookmarkIcon = ({ activity, size }: BookmarkIconProps) => {

    const {isBookmarked, addBookmark, removeBookmark} = useContext(AppContext)!;
    const activityIsBookmarked = isBookmarked(activity);

    return (
        <React.Fragment>
            { activityIsBookmarked &&
            <div className="BookmarkIcon" onClick={(e) => {
                e.stopPropagation();
                removeBookmark(activity);
            }} >
              <div className={size === "lg" ? "icon-button lg" : "icon-button"}>
                <svg viewBox="0 0 22 24">
                  <path id="a" d="M21.5572225,0 L2.44277754,0 C1.64752107,0 1,0.63 1,1.401 L1,22.599 C1,23.115 1.29232748,23.589 1.7606801,23.832 C2.22588941,24.072 2.80740106,24.042 3.24117731,23.769 L12.0487212,18.12 L20.7588227,23.766 C20.997714,23.916 21.2774682,24 21.5572225,24 C21.799257,24 22.0287184,23.94 22.2361766,23.832 C22.7108158,23.589 23,23.118 23,22.599 L23,1.401 C23,0.63 22.3524789,0 21.5572225,0 L21.5572225,0 Z"/>
                </svg>
              </div>
            </div>
            }
            { !activityIsBookmarked &&
            <div className="BookmarkIcon" onClick={(e) => {
                e.stopPropagation();
                addBookmark(activity);
            }} >
              <div className={size === "lg" ? "icon-button lg" : "icon-button"}>
                  <svg viewBox="0 0 24 24">
                    <path id="a" d="M21.4786398,1.476 L21.4786398,22.458 L12.0990141,16.377 L12.0518646,16.347 L2.52136019,22.458 L2.52136019,1.476 L21.4786398,1.476 Z M21.5572225,0 L2.44277754,0 C1.64752107,0 1,0.63 1,1.401 L1,22.599 C1,23.115 1.29232748,23.589 1.7606801,23.832 C2.22588941,24.072 2.80740106,24.042 3.24117731,23.769 L12.0487212,18.12 L20.7588227,23.766 C20.997714,23.916 21.2774682,24 21.5572225,24 C21.799257,24 22.0287184,23.94 22.2361766,23.832 C22.7108158,23.589 23,23.118 23,22.599 L23,1.401 C23,0.63 22.3524789,0 21.5572225,0 L21.5572225,0 Z"/>
                  </svg>
              </div>
            </div>
            }
        </React.Fragment>
    )
};

export default BookmarkIcon;
