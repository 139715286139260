import React from 'react';
import './SearchFieldDate.scss';
import moment, { Moment } from 'moment';
import ModalDateFilter from '../ModalDateFilter/ModalDateFilter';
import Query from '../../models/Query.model';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import DateFilter from '../DateFilter/DateFilter';
import PopupFilterFooter from '../PopupFilterFooter/PopupFilterFooter';
import { DEFAULT_QUERY } from '../../utils/SearchUtils';

type SearchFieldProps = {
    query: Query,
    defaultQuery: Query,
    setQuery: (query: Query) => void,
    isSearchbarVisible: boolean
};

const SearchFieldDate = ({query, defaultQuery, setQuery} : SearchFieldProps) => {

    const overlayRef: any = React.createRef();
    const [showDateFilter, setShowDateFilter] = React.useState(false);
    const [date, setDate] = React.useState(query.date);
    const [weekdays, setWeekdays] = React.useState(query.weekdays);
    const { t } = useTranslation();
    const openDateFilter = () => setShowDateFilter(true);
    const closeDateFilter = () => setShowDateFilter(false);

    const isDefaultQuery = date === DEFAULT_QUERY.date && weekdays === DEFAULT_QUERY.weekdays;

    React.useEffect(() => {
        setQuery({
            ...query,
            date: date,
            weekdays: weekdays
        })
    }, [date, weekdays]);

    const reset = () => {
        setWeekdays(DEFAULT_QUERY.weekdays);
        setDate(DEFAULT_QUERY.date);
        closeDateFilter();
    };

    const closeFilter = () => {
        if (overlayRef && overlayRef.current) {
            overlayRef.current.handleHide();
        }
        closeDateFilter();
    };

    const getDisplayValue = () => {
        if (date) {
            return moment(query.date).format('DD.MM.YY');
        }
        if (weekdays) {
            return _.map(weekdays.sort(), (weekday: number) => {
                return t(`WEEKDAY_${weekday}_SHORT`);
            }).join(', ');
        }
        return '';
    };

    const handleDateChange = (date: Moment | undefined) => {
        console.log('Hier', date);
        setDate(date);
        closeFilter();
    };

    const handleWeekdaysChange = (weekdays: number[] | undefined) => {
        setWeekdays(weekdays);
        closeFilter();
    };

    return (
        <div className="SearchFieldText">
            <div className="SearchFieldText__label">Wann</div>
            <div className="d-md-none">
                <input placeholder="Alle"
                       readOnly={true}
                       onClick={openDateFilter}
                       value={getDisplayValue()} />
                <ModalDateFilter
                    query={query}
                    show={showDateFilter}
                    close={closeDateFilter}
                    setDate={handleDateChange}
                    setWeekdays={handleWeekdaysChange}
                    reset={reset} />
            </div>
            <div className="d-none d-md-flex">
                <OverlayTrigger
                    ref={overlayRef}
                    trigger="click"
                    placement='bottom'
                    overlay={<Popover id={`popover-positioned-bottom`}>
                        <Popover.Content>
                            <DateFilter date={date} weekdays={weekdays} setDate={handleDateChange} setWeekdays={handleWeekdaysChange}/>
                            <PopupFilterFooter isDefaultValue={isDefaultQuery} onReset={reset}/>
                        </Popover.Content>
                    </Popover>}
                    rootClose={true}
                    defaultShow={false}
                >
                    <div>
                        <input placeholder="Alle"
                               readOnly={true}
                               value={getDisplayValue()} />
                    </div>
                </OverlayTrigger>
            </div>
        </div>
  );
};

export default SearchFieldDate;
