import React from 'react';
import './ActivityDescription.scss';
import Activity from '../../models/Activity.model';
import UnderlineButton from '../UnderlineButton/UnderlineButton';
import _ from 'lodash';
import ActivityDetailTitle from '../ActivityDetailTitle/ActivityDetailTitle';
import { getStyleForCategory } from '../../utils/CategoryUtils';

type ActivityDescriptionProps = {
    activity: Activity
};

const ActivityDescription = ({ activity }: ActivityDescriptionProps) => {

    const [showMoreDescription, setShowMoreDescription] = React.useState(false);
    const [description] = React.useState(activity.description.replace(/(?:\r\n|\r|\n)/g, '<br>'));

    const getFullUrl = (url: string) : string => _.startsWith(url.toLowerCase(), 'http', ) ? url : `http://${url}`;

    return (
        <div className="ActivityDescription">
            <ActivityDetailTitle text="Beschreibung" />
            { description.length < 200 &&
                <div className="ActivityDescription__text" dangerouslySetInnerHTML={{__html: description}}></div>
            }
            { description.length > 200 && !showMoreDescription &&
            <React.Fragment>
              <div className="ActivityDescription__text" dangerouslySetInnerHTML={{__html: _.truncate(description, {
                      length: 200})}}></div>
              <UnderlineButton onClick={() => setShowMoreDescription(true)} label="Ganzen Text einblenden"/>
            </React.Fragment>
            }
            { description.length > 200 && showMoreDescription &&
            <React.Fragment>
              <div className="ActivityDescription__text" dangerouslySetInnerHTML={{__html: description}}></div>
              <UnderlineButton onClick={() => setShowMoreDescription(false)} label="Weniger Text einblenden"/>
            </React.Fragment>
            }
            { activity && activity.urls && activity.urls.length > 0 &&
                <div className="ActivityDescription__links">
                    {_.map(activity.urls, (url, index) =>
                        <a className={getStyleForCategory(activity.category)} key={index} href={getFullUrl(url.value)} target="_blank">{url.value}</a>
                    )}
                </div>
            }
        </div>
    )
};

export default ActivityDescription;
