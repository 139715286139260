import Bookmark from '../models/Bookmark.model';

const BOOKMARKS = "bookmarks";

export const getBookmarks = (): Bookmark[] => {
    let storedData = localStorage.getItem(BOOKMARKS);
    if (storedData) {
        return JSON.parse(storedData);
    } else {
        return [];
    }
};

export const persistBookmarks = (bookmarks: Bookmark[]) => {
    localStorage.setItem(BOOKMARKS, JSON.stringify(bookmarks));
};
