import React, { useEffect } from 'react';
import './ActivityImage.scss';
import Activity from '../../models/Activity.model';
import { CloudinaryContext, Image, Transformation } from "cloudinary-react";
import ReactPlaceholder from 'react-placeholder';

export type ImageSize = {
    height: number,
    width: number
};

type ActivityImageProps = {
    activity: Activity | null,
    imageSizeSm: ImageSize,
    imageSizeMd: ImageSize,
    slideshow?: boolean,
};

const ActivityImage = ({activity, imageSizeMd, imageSizeSm, slideshow = false}: ActivityImageProps) => {
    const [mQuery, setMQuery] = React.useState<any>({
        matches: window.innerWidth > 768 ? true : false,
    });

    useEffect(() => {
        let mediaQuery = window.matchMedia("(min-width: 768px)");
        mediaQuery.addListener(setMQuery);
        // this is the cleanup function to remove the listener
        return () => mediaQuery.removeListener(setMQuery);
    }, []);

    const {width, height} = mQuery && !mQuery.matches ? imageSizeSm : imageSizeMd;

    return (
        <div className="ActivityImage">
            <ReactPlaceholder showLoadingAnimation type='rect' style={{width, height, marginRight: 0}}
                              ready={!!activity} color="#E0E0E0">
                <React.Fragment>
                    {activity && activity.images && activity.images.length > 0 &&
                    <div className="ActivityImage__img-container" style={{maxHeight: height, maxWidth: width}}>
                      <CloudinaryContext cloudName="wow-wow">
                        <Image publicId={activity.images[0].publicId}>
                          <Transformation dpr="2.0" gravity="face:center" height={height} width={width} crop="fill"/>
                        </Image>
                      </CloudinaryContext>
                    </div>
                    }
                    {activity && activity.imageUrls && activity.imageUrls.length > 0 &&
                    <div className="ActivityImage__img-container" style={{height, width}}>
                      <img src={mQuery && !mQuery.matches ?
                          activity.imageUrls[0].medium : activity.imageUrls[0].large}/>
                    </div>
                    }
                </React.Fragment>
            </ReactPlaceholder>
        </div>
    );
};

export default ActivityImage;
