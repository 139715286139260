import GeoLocation from '../models/GeoLocation.model';
import config from '../config';

const axios = require("axios");

export const getCity = (geolocation: GeoLocation) => {
    return axios({
        method: 'post',
        data: geolocation,
        url: config.urlBackend + '/geolocationCity',
    }).then((response:any) => {
        if (response.error) {
            return null;
        } else {
            return response.data;
        }
    });
};
