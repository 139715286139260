import React from 'react';
import './BlogThumbnail.scss';
import {Image, Transformation, CloudinaryContext} from 'cloudinary-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Blog } from '../../models/Blog.model';
import UnderlineButton from '../UnderlineButton/UnderlineButton';
import ReactPlaceholder from 'react-placeholder';


type BlogThumbnailProps = {
    blog: Blog | null
}

const BlogThumbnail = ({blog, history}: RouteComponentProps<{}> & BlogThumbnailProps) => {

  let link = blog ? `/blog/${blog.slug}` : "#";
  return (
      <a className="card-link" href={link}>
      <div className="BlogThumbnail">
          <div className="BlogThumbnail__image">
              <ReactPlaceholder type='round' ready={!!blog} color='#E0E0E0' style={{ width: 72, height: 72 }}>
                  { blog && blog.author && blog.author.image &&
                      <CloudinaryContext cloudName="wow-wow">
                        <Image publicId={blog.author.image.url}>
                          <Transformation height="72" width="72" dpr="2.0" gravity="face" crop="fill" />
                        </Image>
                      </CloudinaryContext>
                  }
              </ReactPlaceholder>
          </div>
          <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!blog} color="#E0E0E0" style={{marginTop: 0, width: 40}}>
            <div className="BlogThumbnail__title">Blog</div>
          </ReactPlaceholder>
          <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!blog} color="#E0E0E0" style={{marginTop: 0}}>
            <div className="BlogThumbnail__text">{blog ? blog.title : ''}</div>
          </ReactPlaceholder>
          <div className="BlogThumbnail__link">
              <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!blog} color="#E0E0E0" style={{marginTop: 0, width: 80}}>
                  { blog && <UnderlineButton onClick={() => history.push(`/blog/${blog?.slug}`)} label={"Weiterlesen"}/> }
              </ReactPlaceholder>
          </div>
      </div>
      </a>
  );
};

export default withRouter(BlogThumbnail);
