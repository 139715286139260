import React from 'react';
import './BlogDate.scss';
import moment  from "moment";

type BlogDateProps = {
    date: Date
}

const BlogDate = ({ date }: BlogDateProps) => {
  moment.locale('de-CH');

  return (
      <div className="BlogDate">
          {`BLOG | ${moment(date).format('DD. MMMM YYYY')} `}
      </div>
  );
};

export default BlogDate;
