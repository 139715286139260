import GeoLocation from '../models/GeoLocation.model';
import React from 'react';

export const DEFAULT_MAP_LOCATION: GeoLocation = {
    lat: 47.371725, // Spiegelgasse 5, 8001 Zürich
    lon: 8.544229399999999
};

export const DEFAULT_MAP_ZOOM = 12;

export const toGooglePoint = (geolocation: GeoLocation) => ({lat: geolocation.lat, lng: geolocation.lon});

export const importAll = (r: any) => {
    let images = {};
    r.keys().map((item: any, index: any) => {
        // @ts-ignore
        images[item.replace('./', '')] = r(item); });
    return images;
};

// @ts-ignore
export const PIN_IMAGES = importAll(require.context('../assets/images/pins', false, /\.(png|jpe?g|svg)$/));
