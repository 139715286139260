import React from 'react';
import './ActivityTeaserCardMini.scss';
import Activity from '../../models/Activity.model';
import ActivityImage from '../ActivityImage/ActivityImage';
import { RouteComponentProps, withRouter } from 'react-router';
import ReactPlaceholder from 'react-placeholder';

type ActivityTeaserCardMiniProps = {
    activity: Activity | null
};

const ActivityTeaserCardMini = ({activity, history}: RouteComponentProps<{}> & ActivityTeaserCardMiniProps) => {

    let link = activity ? `/activity/${activity.id}/${activity.displayDate ? activity.displayDate.format('YYYY-MM-DD') : ''}` : "#"

    return (
        <a className="card-link" href={link}>
        <div className="ActivityTeaserCardMini">
            <div className="ActivityTeaserCardMini__image">
                <ActivityImage activity={activity}
                               imageSizeSm={{height: 60, width: 60}}
                               imageSizeMd={{height: 60, width: 60}} />
            </div>
            <div className="ActivityTeaserCardMini__title">
                <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!activity} color="#E0E0E0" style={{marginTop: 0, minWidth: 180}}>
                    {activity && activity.title}
                </ReactPlaceholder>
            </div>
        </div>
        </a>
    );
};

export default withRouter(ActivityTeaserCardMini);
