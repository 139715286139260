import config from '../config';
import { BlogMap } from '../models/Blog.model';

const axios = require("axios");


export const loadBlogs = (): Promise<BlogMap> => {
    return axios({
        method: 'get',
        url: config.urlBackend + '/blogData',
    }).then((response:any) => {
        if (response.error) {
            return null;
        } else {
            return response.data;
        }
    });
};


