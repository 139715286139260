import React from 'react';
import './ActivityImagePlay.scss';
import Activity from '../../models/Activity.model';
import ActivityImage, { ImageSize } from '../ActivityImage/ActivityImage';
import play from './play-button.svg';

type ActivityImageProps = {
    activity: Activity,
    imageSizeSm: ImageSize,
    imageSizeMd: ImageSize,
};

const ActivityImagePlay = ({ activity, imageSizeMd, imageSizeSm }: ActivityImageProps) => {

  const hasImage = (activity.images && activity.images.length > 0) || (activity.imageUrls && activity.imageUrls.length > 0);

  return (
      <a href={activity.urls && activity.urls.length > 0 ? activity.urls[0].value : '#'} target="_blank">
          <div className="ActivityImagePlay">
              <ActivityImage activity={activity} imageSizeMd={imageSizeMd} imageSizeSm={imageSizeSm} />
              { hasImage &&
                  <div className="ActivityImagePlay__btn">
                    <img src={play} alt="play-button" />
                  </div>
              }
          </div>
      </a>
  );
};

export default ActivityImagePlay;
