import React from 'react';
import './Chip.scss';

type ChipProps = {
    title: string,
    active: boolean,
    onSelect?: () => void
}

const Chip = ({ title, active, onSelect }: ChipProps) => {
  return (
      <div key={title} className={active ? 'Chip--active' : 'Chip'} onClick={onSelect}>
          {title}
      </div>
  );
};

export default Chip;
