import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Blog from './containers/Blog';
import Activity from './containers/Activity';
import Home from './containers/Home';
import Bookmarks from './containers/Bookmarks';
import './App.scss';
import AGB from './containers/AGB';
import Dataprotection from './containers/Dataprotection';
import Impressum from './containers/Impressum';
import Provider from './Provider';
import NotFound404 from './containers/NotFound404';
import Snackbar from './components/Snackbar/Snackbar';

const App: React.FC = () => {
  return (
    <Provider>
      <BrowserRouter>
          <div className="App">
              <Snackbar />
              <Switch>
                  <Route path="/blog/:blogId" component={Blog}></Route>
                  <Route path="/activity/:activityId/:date" component={Activity}></Route>
                  <Route path="/activity/:activityId" component={Activity}></Route>
                  <Route path="/bookmarks" component={Bookmarks}></Route>
                  <Route path="/agb" component={AGB}></Route>
                  <Route path="/impressum" component={Impressum}></Route>
                  <Route path="/dataprotection" component={Dataprotection}></Route>
                  <Route path="/" exact component={Home}></Route>
                  <Route path="/404" exact component={NotFound404}></Route>
                  <Route path="/activity/*" component={NotFound404} />
              </Switch>
          </div>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
