import React, { useEffect } from 'react';
import './SearchResults.scss';
import Activity from '../../models/Activity.model';
import SearchData from '../../models/SearchData.model';
import FormResult from '../../models/FormResult.model';
import { Blog, BlogMap } from '../../models/Blog.model';
import Highlights from '../Highlights/Highlights';
import ListView from '../ListView/ListView';
import MapView from '../MapView/MapView';
import GeoLocation from '../../models/GeoLocation.model';
import BoundingBox from '../../models/BoundingBox.model';
import MobileFilter from '../MobileFilter/MobileFilter';

type SearchResultsProps = {
    highlights: Activity[],
    activities: Activity[],
    selectedActivity: Activity | null,
    activitiesLoaded: boolean,
    blogs: BlogMap,
    highlightsLoaded: boolean,
    resultCount: number,
    showMore: () => void,
    isLoadingMore: boolean,
    viewState: string,
    applyBoundingBox: (geoLocation: GeoLocation, boundingBox: BoundingBox | undefined) => void,
    searchData: SearchData,
    subscribeToNewsletter: (email: string) => Promise<FormResult>,
    mailFeedback: (email: string, subject: string, message: string) => Promise<FormResult>,
    updateMapData: (zoom: number, geoLocation: GeoLocation) => void,
    showHighlights: boolean
}


const SearchResults = ({
                           highlights, activities, blogs, selectedActivity, activitiesLoaded, resultCount, showMore, isLoadingMore,
                           viewState, searchData, subscribeToNewsletter, mailFeedback, updateMapData, applyBoundingBox,
                           showHighlights = false
                       }: SearchResultsProps) => {

    const [mQuery, setMQuery] = React.useState<any>({
        matches: window.innerWidth > 768 ? true : false,
    });

    useEffect(() => {
        let mediaQuery = window.matchMedia("(min-width: 768px)");
        mediaQuery.addListener(setMQuery);
        // this is the cleanup function to remove the listener
        return () => mediaQuery.removeListener(setMQuery);
    }, []);

    return <div className="SearchResults">
        {mQuery && !mQuery.matches ?
            <div className="Toggle-view d-md-none">
                {viewState === "LIST" &&
                <div>
                    {showHighlights &&
                    <Highlights activities={highlights}/>
                    }
                  <ListView
                    activities={activities}
                    selectedActivity={selectedActivity}
                    blogs={blogs}
                    resultCount={resultCount}
                    showMore={showMore}
                    isLoadingMore={isLoadingMore}
                    searchData={searchData}
                    subscribeToNewsletter={subscribeToNewsletter}
                    mailFeedback={mailFeedback}
                    activitiesLoaded={activitiesLoaded}
                  />
                </div>
                }
                {viewState === "MAP" &&
                <MapView
                  activities={activities}
                  updateMapData={updateMapData}
                  applyBoundingBox={applyBoundingBox}
                  searchData={searchData}
                  activitiesLoaded={activitiesLoaded}
                />
                }
            </div>
            :
            <div className="centered">
                <div className="Split-view d-none d-md-flex">
                    <div className="Split-view__list">
                        <ListView
                            activities={activities}
                            selectedActivity={selectedActivity}
                            blogs={blogs}
                            resultCount={resultCount}
                            showMore={showMore}
                            isLoadingMore={isLoadingMore}
                            searchData={searchData}
                            subscribeToNewsletter={subscribeToNewsletter}
                            mailFeedback={mailFeedback}
                            activitiesLoaded={activitiesLoaded}
                        />
                    </div>
                    <div className="Split-view__map">
                        <div className="Split-view__map__wrapper" id="Split-view">
                            <MapView
                                activities={activities}
                                updateMapData={updateMapData}
                                applyBoundingBox={applyBoundingBox}
                                searchData={searchData}
                                activitiesLoaded={activitiesLoaded}
                            />
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
};

export default SearchResults;
