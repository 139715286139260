import React, { useContext } from 'react';
import './DonationSticky.scss';
import { AppContext } from '../../Provider';
import ModalDonation from '../ModalDonation/ModalDonation';

const DonationSticky = () => {
    const [showModalDonation, setShowModalDonation] = React.useState(false);
    const closeModelDonation = () => setShowModalDonation(false);
    const openModalDonation = () => setShowModalDonation(true);

    const {mailFeedback} = useContext(AppContext)!;

    return (
        <React.Fragment>
        <div className="DonationSticky" onClick={() => openModalDonation()}>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.2231 12.7926C21.2314 9.22237 17.3636 6.8422 12.6033 6.8422C11.7107 6.8422 10.8182 6.94137 10.0248 7.13972C9.42975 5.94964 8.33884 5.15625 7.04959 5.15625C6.55372 5.15625 6.05785 5.55294 6.05785 6.14799V8.82567C5.06612 9.51989 4.27273 10.3133 3.67769 11.2058H2.38843C1.59504 11.2058 1 11.8009 1 12.5943V15.5695C1 15.9662 1.29752 16.3629 1.69421 16.462L3.28099 16.8587C3.87603 18.148 4.86777 19.3381 6.2562 20.2306L6.95041 23.305C7.04959 23.7017 7.44628 23.9992 7.84298 23.9992H9.52893C9.92562 23.9992 10.3223 23.7017 10.4215 23.305L10.719 22.2141C11.9091 22.4124 13.0992 22.4124 14.2893 22.2141L14.5868 23.305C14.686 23.7017 15.0826 23.9992 15.4793 23.9992H17.1653C17.562 23.9992 17.9587 23.7017 18.0579 23.305L18.7521 20.7265C20.8347 19.4372 22.0248 17.6521 22.3223 15.4703C22.8182 15.272 23.2149 14.7761 23.2149 14.181C23.314 13.4868 22.8182 12.8918 22.2231 12.7926ZM2.09091 12.3959C2.09091 12.1976 2.19008 12.0984 2.38843 12.0984H4.3719V11.9992C4.96694 10.9083 5.8595 10.0158 7.04959 9.22237V6.14799C8.04132 6.24716 8.83471 7.04055 9.23141 8.23063V8.3298H9.33058C10.3223 8.03228 11.4132 7.93311 12.5041 7.93311C17.1653 7.93311 20.7355 10.3133 21.2314 13.7843V13.8835H21.8264C22.0248 13.8835 22.124 13.9827 22.124 14.181C22.124 14.3794 22.0248 14.4786 21.8264 14.4786H21.3306V14.5777C21.3306 16.8587 20.1405 18.743 17.8595 20.0323L17.1653 23.0075H15.5785L15.0826 21.1232V21.024H14.9835C13.3967 21.3215 11.7107 21.4207 10.0248 20.9248H9.92562L9.42975 23.0075H7.94215L7.14876 19.7348C5.66116 18.743 4.57025 17.5529 4.07438 16.1645L2.09091 15.4703V12.3959Z" />
                <path d="M8.93395 10.6123C9.03313 10.6123 9.03313 10.6123 9.1323 10.6123C9.23147 10.6123 9.23147 10.6123 9.33065 10.6123C11.4133 9.81894 13.6943 9.81894 15.7769 10.6123C16.0744 10.7115 16.372 10.6123 16.4711 10.3148C16.5703 10.2156 16.4711 10.0173 16.4711 9.91811C16.372 9.81894 16.2728 9.71977 16.1736 9.62059C13.8926 8.72803 11.3141 8.72803 8.93395 9.62059C8.83478 9.62059 8.73561 9.71977 8.73561 9.91811C8.73561 10.1165 8.63643 10.2156 8.73561 10.3148C8.73561 10.414 8.83478 10.5132 8.93395 10.6123Z" />
                <path d="M12.6033 6.14876C14.2893 6.14876 15.6777 4.76033 15.6777 3.07438C15.6777 1.38843 14.2893 0 12.6033 0C10.9174 0 9.52893 1.38843 9.52893 3.07438C9.52893 4.76033 10.9174 6.14876 12.6033 6.14876ZM10.5207 3.07438C10.5207 1.98347 11.4132 1.09091 12.5041 1.09091C13.595 1.09091 14.4876 1.98347 14.4876 3.07438C14.4876 4.16529 13.595 5.05785 12.5041 5.05785C11.4132 5.05785 10.5207 4.16529 10.5207 3.07438Z" />
            </svg>
        </div>
        <ModalDonation show={showModalDonation} close={closeModelDonation} />
        </React.Fragment>
    )
};

export default DonationSticky;
