import React from 'react';
import './FullwidthButton.scss';

type FullwidthButtonProps = {
    text: string,
    onSelect: () => void,
    loading?: boolean
}

const FullwidthButton = ({ text, onSelect, loading = false }: FullwidthButtonProps) => {
  return (
      <div className="FullwidthButton" onClick={onSelect}>
          { !loading &&
              <div className="FullwidthButton__text">
                  {text}
              </div>
          }
          { loading &&
          <div className="FullwidthButton__spinner">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          }
      </div>
  );
};

export default FullwidthButton;
