import React, { useEffect } from 'react';
import './Activities.scss';
import Activity from '../../models/Activity.model';
import _ from 'lodash';
import BlogThumbnail from '../BlogThumbnail/BlogThumbnail';
import Newsletter from '../Newsletter/Newsletter';
import Feedback from '../Feedback/Feedback';
import FormResult from '../../models/FormResult.model';
import ActivityInfoThumbnail from '../ActivityInfoThumbnail/ActivityInfoThumbnail';
import { Blog, BlogMap } from '../../models/Blog.model';

type ActivitiesProps = {
    activities: Activity[],
    activitiesLoaded: boolean,
    isLoadingMore: boolean,
    subscribeToNewsletter: (email: string) => Promise<FormResult>,
    mailFeedback: (email: string, subject: string, message: string) => Promise<FormResult>
    blogs: BlogMap,
    selectedActivity: Activity | null
}

const Activities = ({ activities, activitiesLoaded, isLoadingMore, subscribeToNewsletter, mailFeedback, blogs, selectedActivity }: ActivitiesProps) => {

    const refs = activities.reduce((acc, value) => {
        acc[value.id] = React.createRef();
        return acc;
    }, {} as any);

    useEffect(() => {
        if (selectedActivity && refs[selectedActivity.id]) {
            refs[selectedActivity.id].current.scrollIntoView();
        }
    });

    const blogsSortedDesc = _.orderBy(_.values(blogs), 'date', 'desc');

  return (
      <div className="Activities">
          {_.map(activitiesLoaded || isLoadingMore ? activities : _.times(5, _.constant(null)), (activity, index) => (
              <div key={index}>
                  { index % 10 === 1 && _.size(blogsSortedDesc) > Math.floor(index / 10) &&
                      <div className="Activities__blog d-md-none">
                        <BlogThumbnail blog={blogsSortedDesc[Math.floor(index / 10)]}/>
                      </div>
                  }
                  { index === 4 &&

                      <div className="Activities__newsletter d-md-none">
                        <Newsletter subscribeToNewsletter={subscribeToNewsletter}/>
                      </div>
                  }
                  { index === 6 &&
                      <div className="Activities__feedback d-md-none">
                        <Feedback mailFeedback={mailFeedback} />
                      </div>
                  }
                  <div className="Activities__thumbnail" key={index} id={activity ? activity.id : 'placeholder'+index} ref={activity ? refs[activity.id] : null}>
                      <ActivityInfoThumbnail activity={activity} withImage={true} />
                  </div>
            </div>
          ))}
          { activitiesLoaded && activities.length === 0 &&
          <div className="No-results">
            <p>Für deine Suche konnten wir leider keine Ergebnisse finden.</p>
            <p>Bitte versuche es mit einem anderen Suchbegriff.</p>
          </div>
          }
      </div>
  );
};

export default Activities;
