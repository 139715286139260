import React from 'react';
import './ActivityPartner.scss';
import Activity from '../../models/Activity.model';
import { CloudinaryContext, Image, Transformation } from "cloudinary-react";

type ActivityPartnerProps = {
    activity: Activity
};

const ActivityPartner = ({ activity }: ActivityPartnerProps) => {
    return (
        <div className="ActivityPartner">
            <div className="ActivityPartner__text">in freundlicher Kooperation mit</div>
            <div className="ActivityPartner__logo">
            <a href={activity.partner_url} target="_blank">
                <CloudinaryContext cloudName="wow-wow">
                    <Image publicId={activity.partner_logo}>
                    </Image>
                </CloudinaryContext>
            </a>
            </div>
        </div>
    )
};

export default ActivityPartner;
