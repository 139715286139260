import React from 'react';
import './Newsletter.scss';
import cat from '../../assets/images/WowWows_Vectors_Katze.svg';
import FormResult from '../../models/FormResult.model';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';

type NewsletterProps = {
    subscribeToNewsletter: (email: string) => Promise<FormResult>
}

const Newsletter = ({ subscribeToNewsletter }: NewsletterProps) => {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [status, setStatus] = React.useState("OPEN");

    const validationSchema = Yup.object().shape({
        email: Yup.string()
        .email("Bitte eine gültige Email Adresse angeben")
        .max(100, "*Email muss kürzer sein als 100 Zeichen")
        .required("*Email erforderlich"),
    });

  return (
    <Formik initialValues={{ email:""}}
            validationSchema={validationSchema}
            onSubmit={(values, {setSubmitting, resetForm}) => {
        setSubmitting(true);
        subscribeToNewsletter(values.email).then((result: FormResult) => {
            setSubmitting(false);
            setErrorMessage(result.errorMessage);
            setStatus(result.status);
        })
    }}>
        {( {values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
                <div className="Newsletter">
                    <div className="Newsletter__title">
                        wow-wow know how – unregelmässig, inspirierend, per Mail.
                    </div>
                    {status !== "SUCCESS" &&
                        <React.Fragment>
                          <div className="Newsletter__email-field">
                            {status === "FAILED" ? (
                                  <div className="error-message">{errorMessage ? errorMessage : "Ein unerwarteter Fehler ist aufgetreten."}</div>
                            ): null}
                            <input name="email" type="email" placeholder="Deine Mailadresse*"
                                   onChange={handleChange}
                                   onBlur={handleBlur}
                                   value={values.email}
                                   className={touched.email && errors.email ? "form-control error" : "form-control"}
                            />
                            {touched.email && errors.email ? (
                                <div className="error-message">{errors.email}</div>
                            ): null}
                          </div>
                          <div className="Newsletter__action">
                              { !isSubmitting &&
                                <button type="submit" className="btn btn-primary">Klar, her damit!</button>
                              }
                              { isSubmitting &&
                                  <button type="button" className="btn btn-primary" disabled={true}>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="btn-more__label--spinning">Klar, her damit!</span>
                                  </button>
                              }
                          </div>
                        </React.Fragment>
                    }

                    {status === "SUCCESS" &&
                        <div className="Newsletter__success">Danke für dein Interesse – jetzt bleibst du auf dem Laufenden!</div>
                    }
                    <div className="Newsletter__image d-md-none">
                        <img src={cat} alt="" />
                    </div>
                </div>
            </Form>
        )}
    </Formik>
  );
};

export default Newsletter;
