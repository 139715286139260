import React from 'react';
import './SearchFieldLocation.scss';
import {cityData} from './cities';
import GeoLocation from '../../models/GeoLocation.model';
import Autosuggest, { RenderSuggestionsContainerParams } from 'react-autosuggest';
import CityData from '../../models/CityData.model';
import _ from 'lodash';
import * as geolocationService from '../../services/GeolocationService';

type SearchFieldProps = {
    setLocation: (value: any) => void,
    currentCity: string | undefined,
    locationType: string | undefined,
    showUserMessage: (message: string) => void,
    resetLocation: () => void
};

const SearchFieldLocation = ({setLocation, locationType, currentCity, showUserMessage, resetLocation} : SearchFieldProps) => {

    const [suggestions, setSuggestions] = React.useState<CityData[]>([]);
    const [value, setValue] = React.useState<string>(currentCity || '');
    const [noResult, setNoResult] = React.useState(false);
    const [type, setType] = React.useState(locationType);
    const [locating, setLocating] = React.useState(false);
    const [geolocation, setGeolocation] = React.useState<GeoLocation | undefined>(undefined);

    const CURRENT_LOCATION = {
        city: 'In meiner Umgebung',
        id: 'current',
    };

    const getCityName = (cityData: CityData): string => `${cityData.city} (${cityData.canton})`;

    const reset = () => {
        setValue('');
        setNoResult(false);
        setGeolocation(undefined);
        resetLocation();
    };

    /* React.useEffect(() => {
        if (!locating) {
            // Value typed - select first suggestion
            if (!geolocation && value !== '') {
                const suggestions = getSuggestions(value);
                if (suggestions.length > 1) {
                    const suggestion: CityData = suggestions[1];
                    setSuggestionValues(suggestion);
                    setLocation({
                        type: 'CUSTOM',
                        geolocation: {
                            lon: suggestion.geolocation.lng,
                            lat: suggestion.geolocation.lat
                        },
                        currentCity: getCityName(suggestion)});
                }
            } else {
                setLocation({
                    type,
                    geolocation,
                    currentCity: value
                });
            }
        }
    }, [geolocation, value]); */

    const locateMe = () => {
        setLocating(true);
        setType('USER');
        navigator.geolocation.getCurrentPosition((geolocationPosition) => {
            const geolocation = {
                lon: geolocationPosition.coords.longitude,
                lat: geolocationPosition.coords.latitude
            };
            geolocationService.getCity(geolocation).then((data:string) => {
                setValue(data);
            });
            setLocating(false);
            setGeolocation(geolocation);
        }, (error) => {
            setValue('');
            showUserMessage('In deiner Browser-Einstellung ist die automatische Lokalisierung deaktiviert.');
        });
    };

    const setSuggestionValues = (cityData: CityData) => {
        if (cityData && cityData.id === 'current') {
            locateMe();
        } else {
            if (cityData.geolocation) {
                setType('CUSTOM');
                setGeolocation({
                    lon: cityData.geolocation.lng,
                    lat: cityData.geolocation.lat
                });
            } else {
                console.log('**** Error: no Geolocation');
            }
        }
    };

    const selectSuggestion = (e: any, value: any) => {
        setSuggestionValues(value.suggestion);
    };

    const getSuggestions = (value: string) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (inputLength > 0) {
            const filteredSuggestions = inputLength === 0 ? [] : cityData.filter(item =>
                item.city.toLowerCase().slice(0, inputLength) === inputValue
            );
            return _.concat([CURRENT_LOCATION], filteredSuggestions);
        } else {
            return [CURRENT_LOCATION];
        }
    };

    const getSuggestionValue = (suggestion: CityData) => suggestion.id === 'current' ? 'Lokalisierung...' : getCityName(suggestion);

    const renderSuggestion = (suggestion: CityData) => {
      if (suggestion.id === 'current') {
          return (
              <div className="Option-current">
                  <div className="Option-current__icon">
                      <svg viewBox="0 0 16 16">
                          <path id="a" d="M0.105863466,7.42505929 L6.24594447,9.75405553 L8.57494071,15.8941365 C8.61022854,15.9647122 8.68080418,16 8.75137982,16 C8.75137982,16 8.75137982,16 8.75137982,16 C8.82195547,16 8.89253111,15.9647122 8.92781893,15.8941365 L15.9853833,0.261631447 C16.0206711,0.191055804 15.9853833,0.12048016 15.9500955,0.0499045163 C15.9148077,0.0146166944 15.8089442,-0.0206711275 15.7383686,0.0146166944 L0.105863466,7.07218107 C0.0352878219,7.10746889 0,7.17804453 0,7.24862018 C0,7.35448364 0.0352878219,7.42505929 0.105863466,7.42505929 Z"/>
                      </svg>
                  </div>
                  <div className="Option-current__title">{suggestion.city}</div>
              </div>
          )
      } else {
          return (
              <div>
                  {getCityName(suggestion)}
              </div>
          );
      }
    };

    const renderSuggestionsContainer = ({containerProps, children, query}: RenderSuggestionsContainerParams) => (
        <div {...containerProps}>
            {children}
            { noResult && value !== '' &&
                <div className="Option-footer">
                    <strong>{query}</strong> konnte nicht gefunden werden.
                </div>
            }
        </div>
    );

    const onChange = (change: any) => {
        if (change.method === 'type') {
            const hasNoResult = getSuggestions(change.newValue).length === 1;
            if (hasNoResult) {
                setGeolocation(undefined);
                setType('DISABLED');
                setNoResult(true);
            } else {
                setNoResult(false);
            }
        }
        setValue(change.newValue);
        setFirstChoice(change.newValue);
    };

    const setFirstChoice = (newValue: string) => {
        if (!locating) {
            // Value typed - select first suggestion
            if (newValue !== '') {
                const suggestions = getSuggestions(newValue);
                if (suggestions.length > 1) {
                    const suggestion: CityData = suggestions[1];
                    setSuggestionValues(suggestion);
                    const currentCity = getCityName(suggestion);
                    setLocation({
                        type: 'CUSTOM',
                        geolocation: {
                            lon: suggestion.geolocation.lng,
                            lat: suggestion.geolocation.lat
                        },
                        currentCity});
                }
            } else {
                setLocation({
                    type,
                    geolocation,
                    currentCity: newValue
                });
            }
        }
    };

    const renderInputComponent = (inputProps: any) => (
        <div className="SearchFieldLocation__input-group">
            <div className="SearchFieldLocation__input-group__label">Wo</div>
            <input {...inputProps} />
        </div>
    );

    return (
        <div className="SearchFieldLocation">
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={({ value }) => setSuggestions(getSuggestions(value))}
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    getSuggestionValue={getSuggestionValue}
                    shouldRenderSuggestions={() => true}
                    renderSuggestion={renderSuggestion}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    onSuggestionSelected={selectSuggestion}
                    renderInputComponent={renderInputComponent}
                    inputProps={{
                        placeholder: "Zürich, Bern, Basel",
                        value,
                        onChange: (event, change) => onChange(change)
                    }}
                />
                { value &&
                <div className="SearchFieldLocation__reset-btn" onClick={() => reset()}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM8.35594 7.01311L12 10.6572L15.6441 7.01311L17 8.36904L13.3559 12.0131L17 15.6572L15.6441 17.0131L12 13.369L8.35594 17.0131L7 15.6572L10.6441 12.0131L7 8.36904L8.35594 7.01311Z" fill="#B0B0B0"/>
                  </svg>
                </div>
                }
        </div>
  );
};

export default SearchFieldLocation;
