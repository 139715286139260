import React from 'react';
import doggy from '../../assets/images/WowWow_PipiDoggi.png';
import './NotFoundContent.scss';
import { RouteComponentProps, withRouter } from 'react-router';


const NotFoundContent = ({history}: RouteComponentProps<{}>) => {

    return (
        <div className="NotFoundContent">
            <h1>«wow-wow» bring mich zurück!</h1>
            <p>Yep, hier geht’s nicht mehr weiter. Auch «Fehlermeldung 404» genannt. Aber keine Panik, das kommt in den besten Familien vor. </p>
            <div className="NotFoundContent__actions">
                <div className="NotFoundContent__actions__btn">
                    <button type="button" className="btn btn-primary" onClick={() => history.push('/')}>Zurück zur Startseite</button>
                </div>
                <div className="NotFoundContent__actions__image">
                    <img src={doggy} alt="" />
                </div>
            </div>
        </div>
    );
};

export default withRouter(NotFoundContent);
