import React from 'react';
import './ActivityInfoMapOverlay.scss';
import Activity from '../../models/Activity.model';
import DateTime from '../DateTime/DateTime';
import _ from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BookmarkIcon from '../BookmarkIcon/BookmarkIcon';
import { getAgesText } from '../../utils/AttributeUtils';
import ActivityImage from '../ActivityImage/ActivityImage';
import Distance from '../Distance/Distance';
import Category from '../Category/Category';

type ActivityInfoMapOverlayProps = {
    activity: Activity
};

const ActivityInfoMapOverlay = ({ activity, history }:
                          RouteComponentProps<{}> & ActivityInfoMapOverlayProps) => {

  const hasImage = (activity.images && activity.images.length > 0) || (activity.imageUrls && activity.imageUrls.length > 0);

  return (
      <div className="ActivityInfoMapOverlay" onClick={() => {
          history.push(`/activity/${activity.id}/${activity.displayDate ? activity.displayDate.format('YYYY-MM-DD') : ''}`);
      }}>
          <div className={hasImage ? "ActivityInfoMapOverlay__datetime--withimage" : "ActivityInfoOverlay__datetime--noimage"}>
              <DateTime dates={activity.dates} displayDate={activity.displayDate} />
          </div>
          { hasImage &&
          <React.Fragment>
            <div className="ActivityInfoMapOverlay__title--withimage">{activity.title}</div>
            <div className="ActivityInfoMapOverlay__image">
                <ActivityImage activity={activity}
                               imageSizeSm={{height: 88, width: 96}}
                               imageSizeMd={{height: 88, width: 96}} />
            </div>
            <div className="ActivityInfoMapOverlay__actions--withimage">
              <BookmarkIcon activity={activity} />
            </div>
          </React.Fragment>
          }
          { !hasImage &&
              <React.Fragment>
                <div className="ActivityInfoMapOverlay__title--noimage">{activity.title}</div>
                <div className="ActivityInfoMapOverlay__actions--noimage">
                  <BookmarkIcon activity={activity} />
                </div>
              </React.Fragment>
          }
          <div className="ActivityInfoMapOverlay__category">
              <Category activity={activity}/>
          </div>
          <div className="ActivityInfoMapOverlay__ages">{getAgesText(activity.ages)}</div>
          <div className="ActivityInfoMapOverlay__distance d-none d-md-flex">
              <Distance activity={activity}/>
          </div>
      </div>
  );
};

export default withRouter(ActivityInfoMapOverlay);
