import React from 'react';
import './ActivityDates.scss';
import Activity from '../../models/Activity.model';
import _ from 'lodash';
import DateItem from '../DateItem/DateItem';
import UnderlineButton from '../UnderlineButton/UnderlineButton';
import ActivityDetailTitle from '../ActivityDetailTitle/ActivityDetailTitle';
import { getDateData } from '../../utils/DateUtils';
import moment from 'moment';
import { RequestData } from '../../models/Dates.model';

type ActivityDatesProps = {
    activity: Activity
};

const ActivityDates = ({ activity }: ActivityDatesProps) => {

    const [showMoreDates, setShowMoreDates] = React.useState(false);

    const isDaily = () => {
        const freq = _.get(activity, 'dates.data.rule.freq', 0);
        const type = _.get(activity, 'dates.type', null);
        return type === "RECURRING" && freq === 3;
    };

    const isAnytime = () => {
        return activity && activity.dates && activity.dates.type === "ANYTIME";
    };

    const isOnRequest = () => {
        return activity && activity.dates && activity.dates.type === "REQUEST";
    };

    const getRequestValue = (): string => {
      const {value} = activity.dates.data as RequestData;
      return value ? ` - ${value}` : '';
    };

    return (
        <React.Fragment>
            { activity.futureDates && activity.futureDates.length > 0 &&
            <div className="ActivityDates">
                <ActivityDetailTitle text="Weitere Daten" />
                { !isDaily() && !isAnytime() && !isOnRequest() &&
                    <div className="ActivityDates__content">
                        { activity.futureDates.length <= 5 &&
                        <React.Fragment>
                            {_.map(activity.futureDates, (date: Date, index: string) => (
                                <DateItem activity={activity} dateObject={getDateData(activity.dates, moment(date))} key={index}/>
                            ))}
                        </React.Fragment>
                        }
                        { activity.futureDates.length > 5 && !showMoreDates &&
                        <React.Fragment>
                            {_.map(_.slice(activity.futureDates, 0, 5), (date: Date, index: string) => (
                                <DateItem activity={activity} dateObject={getDateData(activity.dates, moment(date))} key={index}/>
                            ))}
                          <UnderlineButton onClick={() => setShowMoreDates(true)} label="Weitere Daten einblenden" />
                        </React.Fragment>
                        }
                        { activity.futureDates.length > 5 && showMoreDates &&
                        <React.Fragment>
                            {_.map(activity.futureDates, (date: Date, index: string) => (
                                <DateItem activity={activity} dateObject={getDateData(activity.dates, moment(date))} key={index} />
                            ))}
                          <UnderlineButton onClick={() => setShowMoreDates(false)} label="Weniger Daten einblenden" />
                        </React.Fragment>
                        }
                    </div>
                }
                { isDaily() &&
                    <div className="ActivityDates__content">
                        Täglich
                    </div>
                }
                { isAnytime() &&
                <div className="ActivityDates__content">
                  Jederzeit
                </div>
                }
                { isOnRequest() &&
                <div className="ActivityDates__content">
                    {`Auf Anfrage ${getRequestValue()}`}
                </div>
                }
            </div>
            }
        </React.Fragment>
    )
};

export default ActivityDates;
