import { Moment } from 'moment';
import { Dates, MultipleDate, OpeningTime, OpeningTimeData, RecurringData, SingleDate } from '../models/Dates.model';
import DateObject from '../models/DateObject.model';
import _ from 'lodash';
import moment from 'moment';

export const getDateData = (dates: Dates, date: Moment): DateObject => {
    switch (dates.type) {
        case 'MULTIPLE':
            const multipleData: MultipleDate = dates.data as MultipleDate;
            const dateObj: SingleDate | undefined = _.find(multipleData.dates, (singleDate: SingleDate) => {
                return moment(singleDate.date).isSame(date, 'day');
            });
            return {
                date: date,
                from: dateObj ? dateObj.from : '',
                to: dateObj ? dateObj.to : '',
                displayTime: dateObj ? `${dateObj.from} - ${dateObj.to}`: ''
            };
        case 'RECURRING':
            const recurringData = dates.data as RecurringData;
            return {
                date: date,
                from: recurringData.from,
                to: recurringData.to,
                displayTime: `${recurringData.from} - ${recurringData.to}`
            };
        case 'OPENINGTIMES':
            const openingData = (dates.data as OpeningTimeData).dates as OpeningTime[];
            let weekday = moment(date).isoWeekday() -1;
            let hours = '';
            if (openingData[weekday] && openingData[weekday].open === true) {
                hours = _.reduce(openingData[weekday].times, function(times, time) {
                    return times === "" ? time.from + "-" + time.to : times + ", " + time.from + "-" + time.to;
                }, "");
            }
            return {
                from: '-',
                to: '-',
                date: date,
                displayTime: hours,
            };
        case 'SINGLE':
            const singleData = dates.data as SingleDate;
            return {
                from: singleData.from,
                to: singleData.to,
                date: date,
                displayTime: `${singleData.from} - ${singleData.to}`,
            };
        case 'REQUEST':
            return {
                date: date,
                from: "",
                to: "",
                displayTime: "Auf Anfrage"
            };
        default:
            return {
                date: date,
                from: "",
                to: "",
                displayTime: "jederzeit"
            };
    }
};
