import React, { useContext, useEffect } from 'react';
import { AppContext } from '../Provider';
import { Blog } from '../models/Blog.model';
import BlogContent from '../components/BlogContent/BlogContent';
import Spinner from '../components/Spinner/Spinner';
import _ from 'lodash';
import Layout from '../components/Layout/Layout';

const BlogPage = (props: any) => {
    const [blog, setBlog] = React.useState<Blog | null>(null);
    const [nextBlog, setNextBlog] = React.useState<Blog | null>(null);
    const {blogs, subscribeToNewsletter, blogsLoaded} = useContext(AppContext)!;
    const {history, match: {params}} = props;

    const loadCurrentBlog = () => {
        if (params.blogId && blogs[params.blogId]) {
            const currentBlog = blogs[params.blogId];
            const indexCurrent = _.indexOf(_.values(blogs), currentBlog);
            const indexNext = (indexCurrent + 1) % _.size(blogs);
            setBlog(currentBlog);
            setNextBlog(_.values(blogs)[indexNext]);
        } else {
            history.push('/404');
        }
    };

    useEffect(() => {
        if (blogsLoaded && !blog) {
            loadCurrentBlog();
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        if (blogsLoaded) {
            loadCurrentBlog();
        }
    }, [params, blogsLoaded, loadCurrentBlog]);

    return (
        <Layout pageHeaderOnMobile={true} pageHeaderTitle="Blog">
            <div className="fullwidth white">
              <div className="centered">
                  { blogsLoaded && blog &&
                    <BlogContent blog={blog} nextBlog={nextBlog} subscribeToNewsletter={subscribeToNewsletter}/>
                  }
                  { !blogsLoaded &&
                    <Spinner />
                  }
              </div>
            </div>
        </Layout>
    );
};

export default BlogPage;
