import React, { useContext } from 'react';
import './MobileFilter.scss';
import { AppContext } from '../../Provider';
import { DEFAULT_QUERY } from '../../utils/SearchUtils';
import {ReactComponent as MapIcon} from '../../assets/icons/Map.svg';
import {ReactComponent as FilterIcon} from '../../assets/icons/Filter.svg';
import {ReactComponent as ListIcon} from '../../assets/icons/Liste.svg';
import ModalAttributeFilter from '../ModalAttributeFilter/ModalAttributeFilter';
import Query from '../../models/Query.model';


const MobileFilter = () => {
    const {searchData: {query},
        resultCount,
        getResultCount,
        applyFilter,
        viewState,
        setViewState,
    } = useContext(AppContext)!;

    const [showAttributeFilter, setShowAttributeFilter] = React.useState(false);
    const closeAttributeFilter = () => setShowAttributeFilter(false);
    const openAttributeFilter = () => setShowAttributeFilter(true);


    return (
        <div className="MobileFilter">
            <div className="MobileFilter__chip MobileFilter__chip--left" onClick={openAttributeFilter}>
                <div className="MobileFilter__chip__icon">
                    <FilterIcon />
                </div>
                <div className="MobileFilter__chip__text">Filter</div>
            </div>
            { viewState === 'MAP' &&
                <div className="MobileFilter__chip MobileFilter__chip--right" onClick={() => setViewState('LIST')}>
                  <div className="MobileFilter__chip__icon">
                    <ListIcon />
                  </div>
                  <div className="MobileFilter__chip__text">Liste</div>
                </div>
            }
            { viewState === 'LIST' &&
                <div className="MobileFilter__chip MobileFilter__chip--right" onClick={() => setViewState('MAP')}>
                  <div className="MobileFilter__chip__icon">
                    <MapIcon />
                  </div>
                  <div className="MobileFilter__chip__text">Karte</div>
                </div>
            }
            <ModalAttributeFilter
                query={query}
                defaultQuery={DEFAULT_QUERY}
                resultCount={resultCount}
                getResultCount={getResultCount}
                show={showAttributeFilter}
                close={closeAttributeFilter}
                closeAndApplyFilter={(query: Query) => {
                    applyFilter(query);
                    closeAttributeFilter();
                }} />
        </div>
  );
};

export default MobileFilter;
