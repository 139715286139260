import React from 'react';
import './ModalHeader.scss';

type PageHeaderProps = {
    title: string,
    onClose: () => void
    onReset?: () => void,
    isDefaultValue?: boolean,
}

const ModalHeader = ({ title, onClose, onReset, isDefaultValue = false}: PageHeaderProps) => {
  return (
      <header className="ModalHeader">
          <div className="ModalHeader__icon-back" onClick={onClose}>
              <div className="icon-button" onClick={onClose}>
                  <svg viewBox="0 0 16 16">
                      <path id="a" d="M8.68998647,8.02780807 C8.68998647,8.01380841 8.70398613,8.0058086 8.70398613,7.99180893 C8.70398613,7.98380912 8.69398637,7.97780927 8.69398637,7.96780951 L15.821816,0.995976188 C15.9338133,0.891978675 15.9958118,0.747982117 15.9998117,0.591985847 C16.0038116,0.437989529 15.943813,0.287993115 15.8458154,0.179995697 C15.7438178,0.0699983265 15.6018212,0.00399990437 15.4538248,0 L15.4398251,0 C15.2958285,0 15.1578318,0.0599985656 15.0598342,0.159996175 L8,7.06383112 L0.940171748,0.159996175 C0.840174138,0.0599985656 0.702177437,0 0.55818088,0 L0.546181167,0 C0.398184705,0.00399990437 0.2561881,0.0699983265 0.154190539,0.179995697 C0.0541929294,0.287993115 -0.00380568399,0.437989529 0.000194220382,0.591985847 C0.00419412475,0.747982117 0.0661926425,0.891978675 0.176190013,0.995976188 L7.30601955,7.96780951 C7.3040196,7.97780927 7.29401984,7.98380912 7.29401984,7.99180893 C7.29401984,8.0058086 7.30801951,8.01380841 7.31001946,8.02780807 L0.174190061,15.0056412 C0.0301935032,15.1496378 -0.0298050624,15.3596328 0.0161938379,15.559628 C0.0621927381,15.7616232 0.2101892,15.9216193 0.406184514,15.977618 C0.454183366,15.9916177 0.504182171,15.9996175 0.556180928,15.9996175 C0.692177677,15.9996175 0.830174377,15.9476187 0.938171795,15.8436212 L8,8.93778632 L15.0618341,15.8436212 C15.1698315,15.9476187 15.3078282,15.9996175 15.441825,15.9996175 C15.4958238,15.9996175 15.5438226,15.9916177 15.5938214,15.977618 C15.7878168,15.9216193 15.9378132,15.7616232 15.9838121,15.559628 C16.027811,15.3596328 15.9678125,15.1496378 15.8258159,15.0056412 L8.68998647,8.02780807 Z"/>
                  </svg>
              </div>
          </div>
          <div className={onReset ? "ModalHeader__title" : (onClose ? "ModalHeader__title--right" : "ModalHeader__title--fullwidth")}>
              {title}
          </div>
          {onReset &&
              <div className="ModalHeader__reset">
                <button type="button" className="btn btn-link" onClick={onReset} disabled={isDefaultValue}>Löschen</button>
              </div>
          }
      </header>
  );
};

export default ModalHeader;
