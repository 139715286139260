import React from 'react';
import './ActivityAttributes.scss';
import Activity from '../../models/Activity.model';
import { useTranslation } from 'react-i18next';
import ActivityDetailTitle from '../ActivityDetailTitle/ActivityDetailTitle';
import ActivityDetailSubtitle from '../ActivityDetailSubtitle/ActivityDetailSubtitle';
import _ from 'lodash';
import ActivityPartner from '../ActivityPartner/ActivityPartner';

type ActivityAttributesProps = {
    activity: Activity
};

const ActivityAttributes = ({ activity }: ActivityAttributesProps) => {
    const { t } = useTranslation();
    const website = _.startsWith(activity.org_website, "http") ? activity.org_website : "http://" + activity.org_website;

    return (
        <div className="ActivityAttributes">
            <ActivityDetailTitle text="Weitere Informationen"/>
            <ActivityDetailSubtitle text="Veranstaltungsort:" category={activity.category}/>
            { activity.venue_address && activity.venue_city && activity.venue_zip &&
                <div className="ActivityAttributes__content">
                    {activity.venue_name} <br/>
                    {activity.venue_address} <br/>
                    {activity.venue_zip} {activity.venue_city}<br/>
                </div>
            }
            { activity.venue_address_full &&
                <div className="ActivityAttributes__content">
                    {activity.venue_name} <br/>
                    {activity.venue_address_full} <br/>
                </div>
            }
            <ActivityDetailSubtitle text="Veranstalter:" category={activity.category}/>
            <div className="ActivityAttributes__content">
                {activity.org_name}
                { activity.org_website &&
                <div><a href={website} target="_blank" className="ActivityAttributes__content">{website}</a></div>
                }
            </div>
            <ActivityDetailSubtitle text="Veranstaltungstyp:" category={activity.category}/>
            <div className="ActivityAttributes__content">{t("ACTIVITY_TYPE." + activity.activityType)}</div>
            <ActivityDetailSubtitle text="Anwesenheit der Eltern:" category={activity.category}/>
            <div className="ActivityAttributes__content">{activity.parents ? t("PARENTS." + activity.parents) : '-'}</div>
            { activity.surrounding &&
                <React.Fragment>
                    <ActivityDetailSubtitle text="Umgebung:" category={activity.category}/>
                    <div className="ActivityAttributes__content">{t("SURROUNDING." + activity.surrounding)}</div>
                </React.Fragment>
            }
            { !activity.costFree &&
                <React.Fragment>
                  <ActivityDetailSubtitle text="Kosten:" category={activity.category}/>
                  <div className="ActivityAttributes__content">{activity.costDescription ? activity.costDescription : 'keine Angaben'}</div>
                </React.Fragment>
            }
            { activity.partner_id &&
                <ActivityPartner activity={activity} />
            }
        </div>
    )
};

export default ActivityAttributes;
