import React from 'react';
import './DateItem.scss';
import moment  from "moment";
import DateObject from '../../models/DateObject.model';
import { getStyleForCategory } from '../../utils/CategoryUtils';
import 'moment/locale/de-ch';
import BookmarkIcon from '../BookmarkIcon/BookmarkIcon';
import Activity from '../../models/Activity.model';
import ShareIcon from '../ShareIcon/ShareIcon';
import AddToCalendarIcon from '../AddToCalendarIcon/AddToCalendarIcon';

type DateItemProps = {
    dateObject: DateObject,
    activity: Activity
}

const DateItem = ({ dateObject, activity }: DateItemProps) => {
  moment.locale('de-CH');

  return (
      <div className="DateItem">
          <div className={"DateItem__date " + getStyleForCategory(activity.category)}>{moment(dateObject.date).format('dd, D. MMMM YYYY')}</div>
          <div className="DateItem__time">
              {dateObject.displayTime}
          </div>
          <div className="DateItem__actions">
              <AddToCalendarIcon activity={activity} dateObj={dateObject}/>
              <ShareIcon activity={activity} date={dateObject.date.toDate()}/>
              <BookmarkIcon activity={{...activity, displayDate: dateObject.date}}/>
          </div>
      </div>
  );
};

export default DateItem;
