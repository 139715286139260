import React, { useContext } from 'react';
import './Header.scss';
import AppHeader from '../AppHeader/AppHeader';
import { DEFAULT_QUERY } from '../../utils/SearchUtils';
import { AppContext } from '../../Provider';
import PageHeader from '../PageHeader/PageHeader';
import SearchBarHeader from '../SearchBarHeader/SearchBarHeader';

type HeaderProps = {
    narrow?: boolean,
    pageHeaderOnMobile?: boolean,
    pageHeaderTitle?: string,
    searchBar?: boolean,
    isVisible: boolean,
};

const Header = ({narrow = false, searchBar = false, pageHeaderOnMobile = false, pageHeaderTitle = "", isVisible}: HeaderProps) => {
    const {searchData,
        resultCount,
        applyFilter,
        getResultCount,
        setIsSearching,
        toggleView,
        showUserMessage,
        resetLocation,
    } = useContext(AppContext)!;

    return (
      <div className="Header">
          { pageHeaderOnMobile &&
          <React.Fragment>
              <div className="d-md-none">
                <PageHeader title={pageHeaderTitle} />
                <div className="Header__divider"></div>
              </div>
              <div className="d-none d-md-flex">
                <AppHeader narrow={narrow} />
              </div>
          </React.Fragment>
          }
          { !pageHeaderOnMobile &&
          <React.Fragment>
                <div className="d-md-none">
                  <AppHeader />
                </div>
                <div className="d-none d-md-flex">
                  <AppHeader narrow={narrow} />
                </div>
          </React.Fragment>
          }
          { searchBar &&
              <div className="Search-container">
                <SearchBarHeader applyFilter={applyFilter}
                           query={searchData.query}
                           resultCount={resultCount}
                           getResultCount={getResultCount}
                           defaultQuery={{...DEFAULT_QUERY}}
                           setIsSearching={setIsSearching}
                           toggleView={toggleView}
                           isVisible={isVisible}
                           showUserMessage={showUserMessage}
                           resetLocation={resetLocation}
                />
              </div>
          }
      </div>
  );
};

export default Header;
