import React from 'react';
import './ActivitySection.scss';

type ActivitySectionDatesProps = {
    children: any
};

const ActivitySection = ({ children }: ActivitySectionDatesProps) => {

    return (
        <div className="ActivitySection">
            {children}
        </div>
    )
};

export default ActivitySection;
