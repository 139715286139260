import Query from '../models/Query.model';
import SearchData from '../models/SearchData.model';
import _ from 'lodash';
import moment from 'moment';
import GeoLocation from '../models/GeoLocation.model';
import { DEFAULT_MAP_ZOOM } from './LocationUtils';

export const ALL_AGES = [0,1,2,3,4,5,6,7,8,9,10,11,12];

export const DEFAULT_PAGE_SIZE: number = 50;

export const DEFAULT_QUERY: Query = {
    text: "",
    date: undefined,
    buggyFriendly: undefined,
    ages: undefined,
    parents: undefined,
    costFree: undefined,
    category: undefined,
    surrounding: undefined,
    weekdays: undefined,
    location: undefined,
    boundingBox: undefined,
    highlight: undefined,
    mapZoom: DEFAULT_MAP_ZOOM
};

export const DEFAULT_SEARCH: SearchData = {
    query: {...DEFAULT_QUERY},
    from: 0,
    size: DEFAULT_PAGE_SIZE,
};

export const DEFAULT_HIGHLIGHT_SEARCH: SearchData = {
    query: {...DEFAULT_QUERY},
    from: 0,
    size: DEFAULT_PAGE_SIZE,
};

const buildQuery = (query: Query) => {
    let queryTerms: any[] = [
        {"exists": {
            "field": "futureDates"
        }}
        ];
    let queryParam: any = {
        bool: {}
    };

    if (query.images) {
        queryTerms.push( {"exists": {
                "field": "images"
            }})
    }

    if (query.text && query.text !== "") {
        queryTerms.push({
            multi_match: {
                query: query.text,
                type: "best_fields",
                fields: [ "title", "org_name", "venue_name", "venue_city", "partner_name", "category", "subCategory", "description"]
            }
        })
    }

    // TODO: create more generic query builder
    if (query.date) {
        queryTerms.push({ term: { futureDates: moment(query.date).toDate()}})
    }

    if (query.category) {
        queryTerms.push({ term: { category: query.category}})
    }

    if (query.surrounding) {
        queryTerms.push({ term: { surrounding: query.surrounding}})
    }

    if (query.buggyFriendly) {
        queryTerms.push({ term: { buggyFriendly: query.buggyFriendly}})
    }

    if (query.costFree !== undefined) {
        queryTerms.push({ term: { costFree: query.costFree}})
    }

    if (query.parents) {
        queryTerms.push({ term: { parents: query.parents}})
    }

    if (query.weekdays) {
        queryTerms.push({
            bool: {
                should: _.map(query.weekdays, (weekday) => {
                    return {term: {
                        weekdays: weekday}
                    }
                }),
                minimum_should_match: 1
            }
        })
    }

    if (query.ages && query.ages.length > 0 && query.ages !== ALL_AGES) {
        queryTerms.push({
            bool: {
                should: _.map(query.ages, (age) => {
                    return {term: {
                            ages: age}
                    }
                }),
                minimum_should_match: 1
            }
        })
    }

    if (query.highlight) {
        queryTerms.push({ term: { highlight: true}})
    }

    if (query.boundingBox) {
        queryParam.bool.filter = {
            geo_bounding_box : {
                location : {
                    top_right: query.boundingBox.topRight,
                    bottom_left: query.boundingBox.bottomLeft
                }
            }
        }
    }

    if (queryTerms.length > 0) {
        queryParam.bool.must = queryTerms;
    }
    return queryParam;
};

export const buildSearchQuery = (search: SearchData) => {
    const query = buildQuery(search.query);
    const searchParam: any = {
        query: query,
        from: search.from,
        size: search.size,
        sort : [],
    };
    if (search.query.location) {
        searchParam.sort = [
            {
                "_geo_distance" : {
                    "location" : search.query.location,
                    "order" : "asc",
                    "unit" : "km",
                    "mode" : "min",
                    "distance_type" : "arc",
                    "ignore_unmapped": true
                }
            },
            { "futureDates" :  "asc"},
            { "sortValue" :  "desc"},
            "_score"
        ];
    } else {
        searchParam.sort = [
            { "futureDates" :  "asc"},
            { "sortValue" :  "desc"},
            "_score"
        ];
    }
    return searchParam;
};

export const buildSearchQueryById = (id: string, location?: GeoLocation) => {
    const searchParam: any = {
        query: {
            terms: {
                _id: [id]
            }
        }
    };
    if (location) {
        searchParam.sort = [
            {
                "_geo_distance" : {
                    "location" : location,
                    "order" : "asc",
                    "unit" : "km",
                    "mode" : "min",
                    "distance_type" : "arc",
                    "ignore_unmapped": true
                }
            },
            { "futureDates" :  "asc"},
            { "sortValue" :  "desc"},
            "_score"
        ];
    } else {
        searchParam.sort = [
            { "futureDates" :  "asc"},
            { "sortValue" :  "desc"},
            "_score"
        ];
    }
    return searchParam;
};

export const buildCountQuery = (searchQuery: Query) => {
    let query = buildQuery(searchQuery);
    if (query) {
        return {
            query: query,
        }
    } else {
        return null;
    }
};
