import React from 'react';
import './ActivityDetailTitle.scss';

type ActivityDetailTitleProps = {
    text: string,
}

const ActivityDetailTitle = ({ text }: ActivityDetailTitleProps) => {
  return (
      <div className="ActivityDetailTitle">{text}</div>
  );
};

export default ActivityDetailTitle;
