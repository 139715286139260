import React from 'react';
import './ModalFeedback.scss';
import Modal from "react-bootstrap/Modal";
import ModalHeader from '../ModalHeader/ModalHeader';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormResult from '../../models/FormResult.model';
import FullwidthButton from '../ButtonFullwidth/FullwidthButton';
import donkeys from '../../assets/images/WowWow_esel_kuss.png';
import { useTranslation } from 'react-i18next';

type ModalFeedbackProps = {
    show: boolean,
    type: string,
    close: () => void,
    mailFeedback: (email: string, subject: string, message: string) => Promise<FormResult>
}

const ModalFeedback = ({show, type, close, mailFeedback}: ModalFeedbackProps) => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [status, setStatus] = React.useState("OPEN");
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Bitte eine gültige Email Adresse angeben")
            .max(100, "*Email muss kürzer sein als 100 Zeichen")
            .required("*Email erforderlich"),
        message: Yup.string().required("*Nachricht erforderlich")
    });

    return (
        <Modal className="modal" size="lg" show={show} onHide={close}>
            <ModalHeader title={t(`MODAL.${type}.TITLE`)} onClose={close}/>
            <div className="ModalFeedback__content">
                <Formik initialValues={{email: "", message: ""}}
                        validationSchema={validationSchema}
                        onSubmit={(values, {setSubmitting, resetForm}) => {
                            setSubmitting(true);
                            mailFeedback(values.email, t(`MODAL.${type}.SUBJECT`), values.message).then((result: FormResult) => {
                                setSubmitting(false);
                                setErrorMessage(result.errorMessage);
                                setStatus(result.status);
                            })
                        }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                {status === "OPEN" &&
                                <React.Fragment>
                                  { type !== "CHANGE_REQUEST" &&
                                    <div className="ModalFeedback__content__text">{t(`MODAL.${type}.INTRO`)}</div>
                                  }
                                  <div className="ModalFeedback__content__email">
                                    <input name="email" type="email" placeholder={t(`MODAL.${type}.EMAIL`)}
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.email}
                                           className={touched.email && errors.email ? "form-control error" : "form-control"}
                                    />
                                      {touched.email && errors.email ? (
                                          <div className="error-message">{errors.email}</div>
                                      ) : null}
                                  </div>
                                  <div className="ModalFeedback__content__message">
                                  <textarea rows={8} name="message"
                                            placeholder={t(`MODAL.${type}.MESSAGE`)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.message}
                                            className={touched.message && errors.message ? "form-control error" : "form-control"}
                                  ></textarea>
                                      {touched.message && errors.message ? (
                                          <div className="error-message">{errors.message}</div>
                                      ) : null}
                                  </div>
                                  <div className="ModalFeedback__content__action">
                                      {!isSubmitting &&
                                      <button type="submit" className="btn btn-primary">{t(`MODAL.${type}.SEND_BTN`)}</button>
                                      }
                                      {isSubmitting &&
                                      <button type="button" className="btn btn-primary" disabled={true}>
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                        <span className="btn-more__label--spinning">{t(`MODAL.${type}.SEND_BTN`)}</span>
                                      </button>
                                      }
                                  </div>
                                </React.Fragment>
                                }

                                {status === "SUCCESS" &&
                                <React.Fragment>
                                  <div className="ModalFeedback__content__text">{t(`MODAL.${type}.SUCCESS`)}</div>
                                  <div className="ModalFeedback__content__image">
                                      <img src={donkeys} alt="kissing donkeys" />
                                  </div>
                                  <div className="ModalFeedback__content__home-btn d-md-none">
                                    <FullwidthButton text="Zurück zur Startseite" onSelect={close}/>
                                  </div>
                                </React.Fragment>
                                }
                                {status === "FAILED" &&
                                <React.Fragment>
                                  <div className="ModalFeedback__content__text">
                                      {errorMessage ? errorMessage : t(`MODAL.${type}.ERROR`)}
                                    <br/><br/>
                                    {t(`MODAL.${type}.CONTACT`)} <a href={`mailto:${t(`MODAL.${type}.CONTACT_EMAIL`)}`}>{t(`MODAL.${type}.CONTACT_EMAIL`)}</a>
                                  </div>
                                  <div className="ModalFeedback__content__home-btn d-md-none">
                                    <FullwidthButton text="Zurück zur Startseite" onSelect={close}/>
                                  </div>
                                </React.Fragment>
                                }
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
};

export default ModalFeedback;
