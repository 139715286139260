import React from 'react';
import './ArrowBack.scss';
import { RouteComponentProps, withRouter } from 'react-router';

const ArrowBack = ({ history }: RouteComponentProps<{}>) => {

  return (
      <div className="ArrowBack" onClick={() => history.push("/")}>
          <div className="icon-button lg">
              <svg width="32" height="32" viewBox="0 0 16 16">
                  <path id="a" d="M5.41308337,7.998 L12.8164716,0.996 C12.9300633,0.89 12.9958269,0.748 12.9998126,0.592 C13.0037982,0.438 12.9440131,0.288 12.8403856,0.18 C12.7347653,0.068 12.5872954,0.004 12.4338469,0 L12.4238828,0 C12.2724272,0 12.1309358,0.056 12.0273083,0.158 L4.17951748,7.582 C4.06791862,7.69 4.00215501,7.838 4.00016218,7.99 C3.9961765,8.154 4.06592579,8.312 4.17951748,8.416 L12.0293011,15.844 C12.1409,15.946 12.2823914,16 12.4258756,16 C12.4776894,16 12.5295031,15.992 12.579324,15.974 C12.7806005,15.922 12.9340489,15.76 12.9838699,15.558 C13.0297051,15.36 12.9679272,15.148 12.8204573,15.006 L5.41308337,7.998 Z"/>
              </svg>
          </div>
      </div>
  );
};

export default withRouter(ArrowBack);
