import _ from 'lodash';
import Query from '../models/Query.model';
import SearchData from '../models/SearchData.model';
import {
    buildCountQuery,
    buildSearchQuery,
    buildSearchQueryById,
    DEFAULT_HIGHLIGHT_SEARCH
} from '../utils/SearchUtils';
import Activity from '../models/Activity.model';
import { Moment } from 'moment';
import moment  from "moment";
import Bookmark from '../models/Bookmark.model';
import config from '../config';
import GeoLocation from '../models/GeoLocation.model';

const axios = require("axios");
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const getDisplayDate = (activity: Activity, displayDate?: Moment): Moment | null => {
    if (displayDate) {
        const existingDate: Date | undefined = _.find(activity.futureDates, (date: Date) => {
            return moment(date).isSame(moment(displayDate), 'day')}
            );
        return existingDate ? moment(existingDate) : null;
    } else {
        if (activity.futureDates && activity.futureDates.length > 0) {
            return moment(activity.futureDates[0]);
        } else {
            return null;
        }
    }
};

const applyDisplaydate = (activity: Activity, displayDate?: Moment): Activity => {
    activity.displayDate = getDisplayDate(activity, displayDate);
    return activity;
};

const applyDisplaydates = (activities: Activity[], displayDate?: Moment): Activity[] => {
    return _.map(activities, (activity) => applyDisplaydate(activity, displayDate));
};

const toActivity = (data: any) : Activity => {
    const activity: Activity = data._source as Activity;
    activity.distance = data.sort && data.sort.length === 4 ? data.sort[0] : null;
    const descriptionParts = _.split(activity.description, "###");
    activity.description = descriptionParts[0];
    if (activity.partner_id) {
        activity.description = activity.description ? activity.description.replace(/<br\s*[\/]>/gi, " ").replace(/(?:\r\n|\r|\n)/g, '<br>') : '';
        if (activity.partner_id === 'skaterpark-guide') {
            activity.description = `${activity.description}<br>Weitere Informationen zu Öffnungszeiten und Angebot zum jeweiligen Betrieb findest du auf <a href='${activity.externalLink}'>${activity.externalLink}</a>`;
        }
        if (activity.partner_id === 'grillstelle.ch') {
            activity.description = `${activity.description}<br><br>Weitere Informationen zur Grillstelle findest du auf <a href='${activity.externalLink}'>${activity.externalLink}</a>`;
        }
    }
    return activity;
};

const toActivities = (data: any[]) => _.map(data, (item) => toActivity(item));

export const loadActivitiesByCategory = (): Promise<{[key: string]: Activity[]}> => {
    const activityByCategory: {[key: string]: Activity[]} = {};
    return axios({
        method: 'post',
        data: {
            "query":{
                "function_score":{
                    "query": {
                        "bool":{
                            "must":[{"exists":{"field":"futureDates"}},{"exists":{"field":"images"}}]
                        }
                    },
                    "random_score":{}
                }
            },
            "aggs":{
                "random_sample_groups":{
                    "terms":{
                        "field":"category",
                        "size":20
                    },
                    "aggs":{
                        "random_samples":{
                            "top_hits":{
                                "size":4
                            }
                        }
                    }
                }
            }
        },
        url: config.urlBackend + '/aggregateElasticData',
    }).then((response:any) => {
        if (!response.error) {
            let result = response.data;
            let buckets = result.random_sample_groups.buckets;
            if (buckets) {
                _.each(buckets, bucket => {
                    activityByCategory[bucket.key] = applyDisplaydates(toActivities(bucket.random_samples.hits.hits));
                })
            }
        }
        return activityByCategory;
    }).catch(function (thrown: any) {
        if (axios.isCancel(thrown)) {
            console.log('Request canceled', thrown.message);
        } else {
            // handle error
        }
    });
}

export const loadActivities = (searchData: SearchData): Promise<Activity> => {
    return axios({
        method: 'post',
        data: buildSearchQuery(searchData),
        url: config.urlBackend + '/searchElasticData',
    },{
        cancelToken: source.token
    }).then((response:any) => {
        if (response.error) {
            return null;
        } else {
            let result = response.data;
            result.hits = applyDisplaydates(toActivities(response.data.hits), searchData.query.date);
            return result;
        }
    }).catch(function (thrown: any) {
        if (axios.isCancel(thrown)) {
            console.log('Request canceled', thrown.message);
        } else {
            // handle error
        }
    });
};

export const loadHighlights = (location?: GeoLocation): Promise<Activity[]> => {
    const searchData = { ...DEFAULT_HIGHLIGHT_SEARCH};
    searchData.query.location = location;
    const searchQuery = {...DEFAULT_HIGHLIGHT_SEARCH};
    searchQuery.query.highlight = true;
    return axios({
        method: 'post',
        url: config.urlBackend + '/searchElasticData',
        data: buildSearchQuery(searchQuery)
    },{
        cancelToken: source.token
    }).then((response:any) => {
        if (response.error) {
            return null;
        } else {
            return _.slice(applyDisplaydates(toActivities(response.data.hits)), 0, 12);
        }
    }).catch(function (thrown: any) {
        if (axios.isCancel(thrown)) {
            console.log('Request canceled', thrown.message);
        } else {
            // handle error
        }
    });
};

export const getActivityCount = (query: Query):Promise<number> => {
    return axios({
        method: 'post',
        data: buildCountQuery(query),
        url: config.urlBackend + '/countElasticData',
    }).then((response:any) => {
        if (response.error) {
            return null;
        } else {
            return response.data;
        }
    });
};

export const loadActivityById = (id: string, location?: GeoLocation, date?: Moment): Promise<Activity | null> => {
    return axios({
        method: 'post',
        url: config.urlBackend + '/searchElasticData',
        data: buildSearchQueryById(id, location)
    },{
        cancelToken: source.token
    }).then((response:any) => {
        if (response.error) {
            return null;
        } else {
            const activities = applyDisplaydates(toActivities(response.data.hits), date);
            return activities.length > 0 ? activities[0] : null;
        }
    }).catch(function (thrown: any) {
        if (axios.isCancel(thrown)) {
            console.log('Request canceled', thrown.message);
        } else {
            // handle error
        }
    });
};

export const loadBookmarkedActivites = (bookmarks: Bookmark[]) => {
    const data = {
        query: {
            terms: {
                _id: _.map(bookmarks, (bookmark) => bookmark.activityId)
            }
        }
    };
    return axios({
        method: 'post',
        url: config.urlBackend + '/searchElasticData',
        data: data
    }).then((response:any) => {
        if (response.error) {
            return null;
        } else {
            return _.map(toActivities(response.data.hits), (activity) => {
                const bookmark = _.find(bookmarks, {activityId: activity.id});
                return applyDisplaydate(activity, bookmark && bookmark.date ? moment(bookmark.date) : undefined);
            });
        }
    });
};


