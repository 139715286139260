import React from 'react';
import './BlogAuthor.scss';
import {Image, Transformation, CloudinaryContext} from 'cloudinary-react';
import { Author } from '../../models/Blog.model';

type BlogAuthorProps = {
    author: Author
}

const BlogAuthor = ({author}: BlogAuthorProps) => {

    return (
      <div className="BlogAuthor">
          { author.image &&
            <React.Fragment>
              <div className="BlogAuthor__image d-md-none" style={{height: 48, width: 48}}>
                <CloudinaryContext cloudName="wow-wow">
                  <Image publicId={author.image.url}>
                    <Transformation dpr="2.0" height="48" width="48" gravity="face" crop="fill" />
                  </Image>
                </CloudinaryContext>
              </div>
              <div className="BlogAuthor__image d-none d-md-flex" style={{height: 72, width: 72}}>
                <CloudinaryContext cloudName="wow-wow">
                  <Image publicId={author.image.url}>
                    <Transformation dpr="2.0" height="72" width="72" gravity="face" crop="fill" />
                  </Image>
                </CloudinaryContext>
              </div>
            </React.Fragment>
          }
          <div className="BlogAuthor__title">{author.name}</div>
          <div className="BlogAuthor__text">{author.about}</div>
          { author.website &&
            <div className="BlogAuthor__link"><a  href={author.website} target="_blank">{author.website}</a></div>
          }
      </div>
  );
};

export default BlogAuthor;
