import React from 'react';
import './Feedback.scss';
import worm from '../../assets/images/WowWows_Vectors_Wurm.svg';
import ModalFeedback from '../ModalFeedback/ModalFeedback';
import FormResult from '../../models/FormResult.model';

type FeedbackProps = {
    mailFeedback: (email: string, subject: string, message: string) => Promise<FormResult>
}

const Feedback = ({mailFeedback}: FeedbackProps) => {
  const [showFeedbackForm, setShowFeedbackForm] = React.useState(false);
  const closeFeedbackForm = () => setShowFeedbackForm(false);
  const openFeedbackForm = () => setShowFeedbackForm(true);

  return (
      <div className="Feedback">
          <div className="Feedback__title">
              Wurmt dich was? Raus damit. Der beste Moment ist immer – jetzt.
          </div>
          <div className="Feedback__action">
              <button type="button" className="btn btn-primary" onClick={() => openFeedbackForm()}>wurmwurmwurm</button>
          </div>
          <div className="Feedback__image">
              <img src={worm} alt="" />
          </div>
          <ModalFeedback type="FEEDBACK" show={showFeedbackForm} close={closeFeedbackForm} mailFeedback={mailFeedback}/>
      </div>
  );
};

export default Feedback;
