import React, { useEffect } from 'react';
import './SearchBar.scss';
import Query from '../../models/Query.model';
import SearchFieldLocation from '../SearchFieldLocation/SearchFieldLocation';
import SearchFieldText from '../SearchFieldText/SearchFieldText';
import SearchFieldDate from '../SearchFieldDate/SearchFieldDate';
import SearchFieldFilter from '../SearchFieldFilter/SearchFieldFilter';

type SearchBarProps = {
    query: Query,
    defaultQuery: Query,
    applyFilter: (query: Query) => void,
    setIsSearching: (value: boolean) => void,
    toggleView: () => void,
    showUserMessage: (message: string) => void,
    isVisible: boolean,
    resetLocation: () => void
}

const SearchBar = (props: SearchBarProps) => {
  const {applyFilter, isVisible, showUserMessage, defaultQuery, resetLocation} = props;
  const [query, setQuery] = React.useState(props.query);
  const [location, setLocation] = React.useState({
     geolocation: query.location,
     currentCity: query.currentCity,
     type: query.locationType
  });

  React.useEffect(() => {
      setQuery(props.query);
      setLocation({
          geolocation: query.location,
          currentCity: query.currentCity,
          type: query.locationType
      });
  }, [props.query]);

  const applySearch = (event: any) => {
      applyFilter({ ...query, location: location.geolocation, locationType: location.type, currentCity: location.currentCity ? location.currentCity : ''});
      event.preventDefault();
  };

    return (
      <form className="SearchBar" onSubmit={applySearch}>
          <div className="SearchBar__search-field-text">
            <SearchFieldText searchText={query.text} setSearchText={(text: string) => setQuery({...query, text})} />
          </div>
          <div className="SearchBar__search-field-location">
              <SearchFieldLocation setLocation={setLocation}
                           currentCity={query.currentCity}
                           locationType={query.locationType}
                           showUserMessage={showUserMessage}
                                   resetLocation={resetLocation}
              />
          </div>
          <div className="SearchBar__search-field-date">
              <SearchFieldDate query={query}
                               defaultQuery={defaultQuery}
                               setQuery={setQuery} isSearchbarVisible={isVisible} />
          </div>
          <div className="SearchBar__search-filter d-none d-md-flex">
              <SearchFieldFilter query={query}
                                 defaultQuery={defaultQuery}
                                 setQuery={setQuery}
                                 isSearchbarVisible={isVisible}/>
          </div>
          <button className="SearchBar__search-btn" type="submit">
              <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-md-none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M14.6821 13.7988C15.8493 12.4108 16.5748 10.6442 16.5748 8.68839C16.5748 4.36662 13.0417 0.833496 8.68839 0.833496C4.33507 0.833496 0.833496 4.36662 0.833496 8.68839C0.833496 13.0102 4.36662 16.5433 8.68839 16.5433C10.6442 16.5433 12.4108 15.8493 13.7988 14.6505L17.9248 18.9131C18.167 19.1634 18.5673 19.1666 18.8136 18.9204C19.0549 18.6791 19.0574 18.2887 18.8193 18.0444L14.6821 13.7988ZM8.68839 15.3131C5.06063 15.3131 2.09533 12.3478 2.09533 8.68847C2.09533 5.02917 5.06063 2.09541 8.68839 2.09541C12.3162 2.09541 15.2815 5.06071 15.2815 8.68847C15.2815 12.3162 12.3477 15.3131 8.68839 15.3131Z" fill="white"/>
              </svg>
              <span className="d-none d-md-flex">
                        Und Los!
                    </span>
          </button>
      </form>
  );
};

export default SearchBar;
