import React, { useContext } from 'react';
import './ChangeRequest.scss';
import ModalFeedback from '../ModalFeedback/ModalFeedback';
import { AppContext } from '../../Provider';
import UnderlineButton from '../UnderlineButton/UnderlineButton';

const ChangeRequest = () => {
    const [showFeedbackForm, setShowFeedbackForm] = React.useState(false);
    const closeFeedbackForm = () => setShowFeedbackForm(false);
    const openFeedbackForm = () => setShowFeedbackForm(true);

    const {mailChangeRequest} = useContext(AppContext)!;

    return (
        <React.Fragment>
        <div className="ChangeRequest" onClick={() => openFeedbackForm()}>
            <UnderlineButton onClick={() => showFeedbackForm} label="Änderungen vorschlagen"/>
        </div>
        <ModalFeedback type="CHANGE_REQUEST" show={showFeedbackForm} close={closeFeedbackForm} mailFeedback={mailChangeRequest}/>
        </React.Fragment>
    )
};

export default ChangeRequest;
