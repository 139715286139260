import React from 'react';
import './AttributeChip.scss';

type AttributeChipProps = {
    title: string,
    active: boolean,
    onSelect?: () => void
}

const AttributeChip = ({ title, active, onSelect }: AttributeChipProps) => {
  return (
      <div className={active ? 'AttributeChip--active' : 'AttributeChip'} onClick={onSelect}>
          <div className="AttributeChip__label">{title}</div>
      </div>
  );
};

export default AttributeChip;
