import FormResult from '../models/FormResult.model';
import config from '../config';

const axios = require("axios");

export const subscribe = (email: string): Promise<FormResult> => {
    return axios({
        method: 'post',
        data: { email: email },
        url: config.urlBackend + '/subscribeNewsletter',
    }).then(
        (response: any) => {
            return {
                status: "SUCCESS",
                errorMessage: ""
            }
        },
        (error: any) => {
            return {
                status: "FAILED",
                errorMessage: error.response && error.response.data ? error.response && error.response.data.message : ""
            }
        });
};

