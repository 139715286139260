import AttributeOption from '../models/AttributeOption.model';
import _ from 'lodash';

export const CATEGORIES: string[] = [
    "Handarbeit & Werken",
    "Bewegung & Spiel",
    "Musik, Kunst & Kultur",
    "Feste & Anlässe",
    "Naturerlebnis",
    "Shoppen",
    "Wissen",
    "Baby & Me"
];


export const CATEGORY_OPTIONS: AttributeOption[] = [
    {label: "Handarbeit & Werken", value: "Handarbeit & Werken"},
    {label: "Bewegung & Spiel", value: "Bewegung & Spiel"},
    {label: "Musik, Kunst & Kultur", value: "Musik, Kunst & Kultur"},
    {label: "Naturerlebnis", value: "Naturerlebnis"},
    {label: "Shoppen", value: "Shoppen"},
    {label: "Wissen", value: "Wissen"},
    {label: "Baby & Me", value: "Baby & Me"}
];

export const SURROUNDING_OPTIONS: AttributeOption[] = [
    {label: "Draussen", value: "OUTDOOR"},
    {label: "Drinnen", value: "INDOOR"}
];

export const BUGGYFRIENDLY_OPTIONS: AttributeOption[] = [
    {label: "Nicht nötig", value: false},
    {label: "Pflicht", value: true}
];

export const COSTFREE_OPTIONS: AttributeOption[] = [
    {label: "Kostenpflichtig", value: false},
    {label: "Gratis", value: true}
];

export const PARENTS_OPTIONS: AttributeOption[] = [
    {label: "Nicht nötig", value: "NONE"},
    {label: "Pflicht", value: "REQUIRED"},
    {label: "Nur Eltern", value: "ONLY"}
];

const groupArray = (a: any) => {
    const ret: number[] = [];
    if (!a.length) return ret;
    let ixf = 0;
    for (let ixc = 1; ixc < a.length; ixc += 1) {
        if (a[ixc] !== a[ixc-1] + 1) {
            ret.push(a.slice(ixf, ixc));
            ixf = ixc;
        }
    }
    ret.push(a.slice(ixf, a.length));
    return ret;
};

export const getAgesText = (ages: number[]): string => {
    let agesSorted = _.uniq(ages).sort(function(a: number, b: number){return a-b}) as number[];
    return _.map(groupArray(agesSorted), (value: number[]) => {
        if (value.length === 1) {
            return value[0];
        } else if (value.length > 1) {
            return `${value[0]}-${value[value.length-1]}`;
        }
        return "";
    }).join(' / ') + " Jahre";
};
