import React from 'react';
import './Spinner.scss';
import pingu from '../../assets/images/WowWow_pingu_500.gif';
import pinguSmall from '../../assets/images/WowWow_pingu_250.gif';

const Spinner = () => {

  return (
      <div className="Spinner">
          <img className="d-none d-md-flex" src={pinguSmall} alt="loading..." />
          <img className="d-md-none" src={pinguSmall} alt="loading..." />
      </div>
  )
};

export default Spinner;
