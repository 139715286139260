import React, { useEffect } from 'react';
import './MapView.scss';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import Spinner from '../Spinner/Spinner';
import Activity from '../../models/Activity.model';
import { DEFAULT_MAP_LOCATION, DEFAULT_MAP_ZOOM, PIN_IMAGES, toGooglePoint } from '../../utils/LocationUtils';
import GeoLocation from '../../models/GeoLocation.model';
import currentMarker from '../../assets/images/pins/currentplace.svg';
import _ from 'lodash';
import config from '../../config';
import ActivityInfoMapOverlay from '../ActivityInfoMapOverlay/ActivityInfoMapOverlay';
import { getPinForCategory } from '../../utils/CategoryUtils';
import Chip from '../Chip/Chip';
import BoundingBox from '../../models/BoundingBox.model';
import SearchData from '../../models/SearchData.model';

const mapStyles = require("../../mapStyles.json");

type MapViewProps = {
  activities: Activity[],
  updateMapData: (zoom: number, geoLocation: GeoLocation) => void,
  applyBoundingBox: (geoLocation: GeoLocation, boundingBox: BoundingBox | undefined) => void,
  activitiesLoaded: boolean,
  searchData: SearchData
}

const MapView = ({activities, activitiesLoaded, updateMapData, applyBoundingBox, searchData}: MapViewProps) => {
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [activitiesWithLocation, setActivitiesWithLocation] = React.useState<Activity[]>([]);
  const [mapChanged, setMapChanged] = React.useState(false);
  const [center] = React.useState<any>(toGooglePoint(DEFAULT_MAP_LOCATION));
  const [zoom] = React.useState(searchData.query.mapZoom ? searchData.query.mapZoom : DEFAULT_MAP_ZOOM);
  const [selected, setSelected] = React.useState<Activity | null>(null);
  const [selectedVisable, setSelectedVisable] = React.useState(true);
  const [mapHeight, setMapHeight] = React.useState(window.innerHeight - 176);

  useEffect(() => {
    const resizeListener = () => {
      setMapHeight(window.innerHeight - 176);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  const options = {
    styles: mapStyles,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    maxZoom: 18
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey,
  });

  const [pins] = React.useState<any>(PIN_IMAGES);

  const onMapChange = (map: google.maps.Map | null) => {
    if (map) {
      setMapChanged(true);
      updateMapData(map.getZoom(), {
        lat: map.getCenter().lat(),
        lon: map.getCenter().lng()
      });
    }
  };

  const searchInMapBounds = (map: google.maps.Map | null) => {
    setMapChanged(false);
    if (map) {
      const center: GeoLocation = {
        lat: map.getCenter().lat(),
        lon: map.getCenter().lng()
      };
      const googleMapBoundingBox = map.getBounds();
      let boundingBox;
      if (googleMapBoundingBox) {
        boundingBox = {
          topRight: {
            lon: googleMapBoundingBox.getNorthEast().lng(),
            lat: googleMapBoundingBox.getNorthEast().lat()
          },
          bottomLeft: {
            lon: googleMapBoundingBox.getSouthWest().lng(),
            lat: googleMapBoundingBox.getSouthWest().lat()
          }
        };
        applyBoundingBox(center, boundingBox);
      }
    }
  };

  React.useEffect(
      () => {
        const newActivities = _.filter(_.uniqBy(activities, 'id'), (activity: Activity) => activity.location !== undefined);
        setActivitiesWithLocation(newActivities);
        if (map && newActivities && newActivities.length > 0) {
          let bounds = new google.maps.LatLngBounds();
          activities.forEach(activity => {
            if (activity.location) {
              bounds.extend (toGooglePoint(activity.location));
            }
          });
          map.fitBounds(bounds);
        }
        setMapChanged(false);
      }, [activities, map]);

  const renderMap = () => {
    return (
        <React.Fragment>
          <div className="MapView__map">
            { mapChanged &&
              <div className="MapView__map__action">
                <Chip title={"Hier suchen"} active={false} onSelect={() => searchInMapBounds(map)} />
              </div>
            }
            <GoogleMap
                center={center}
                mapContainerStyle={{
                  height: mapHeight,
                  width: '100%',
                  boxSizing: 'border-box'
                }}
                options={options}
                extraMapTypes={[]}
                zoom={zoom}
                onClick={() => setSelected(null)}
                onDragEnd={() => onMapChange(map)}
                onZoomChanged={() => onMapChange(map)}
                onLoad={(map: google.maps.Map) => {
                  setMap(map);
                }}
            >
              <Marker key={"current"}
                      position={center}
                      icon={currentMarker}
              />
              {activitiesLoaded && _.map(activitiesWithLocation, (activity: Activity) => (
                  <Marker key={activity.id}
                          position={toGooglePoint(activity.location)}
                          icon={pins[`${getPinForCategory(activity.category)}-${activity === selected ? "active" : "default"}.svg`]}
                          onClick={() => {
                            setSelected(activity);
                            setSelectedVisable(true);
                          }}
                  />
              ))}
            </GoogleMap>
          </div>
          <div className={selectedVisable && selected ? "MapView__selected" : "MapView__selected--hidden"} id="MapView__selected">
            { selected &&
              <ActivityInfoMapOverlay activity={selected} />
            }
          </div>
      </React.Fragment>
    )
  };

  if (loadError) {
    return <div>Die Karte kann zur Zeit nicht angezeigt werden.</div>
  }

  return isLoaded ? renderMap() : <Spinner />
};

export default MapView;
