import React from 'react';
import './BookmarkContent.scss';
import _ from 'lodash';
import Activity from '../../models/Activity.model';
import ActivityInfoThumbnail from '../ActivityInfoThumbnail/ActivityInfoThumbnail';
import piggy from '../../assets/images/WowWow-MuemmelSchwein.png';
import FullwidthButton from '../ButtonFullwidth/FullwidthButton';
import { RouteComponentProps, withRouter } from 'react-router';
import ArrowBack from '../ArrowBack/ArrowBack';

type BookmarkContentProps = {
    bookmarkedActivities: Activity[]
}

const BookmarkContent = ({ bookmarkedActivities, history }: RouteComponentProps<{}> & BookmarkContentProps) => {
    const [tab, setTab] = React.useState<"CURRENT" | "PAST">("CURRENT");

    const [currentActivities, setCurrentActivities] = React.useState<Activity[]>(_.filter(bookmarkedActivities,
        (activity) => activity.displayDate !== null));
    const [pastActivities, setPastActivities] = React.useState<Activity[]>(_.filter(bookmarkedActivities,
        (activity) => activity.displayDate === null));

    React.useEffect(
        () => {
            setCurrentActivities(_.filter(bookmarkedActivities,
                (activity) => activity.displayDate !== null));
            setPastActivities(_.filter(bookmarkedActivities,
                (activity) => activity.displayDate === null));
        }, [bookmarkedActivities]);

    return (
        <div className="BookmarkContent">
            <div className="BookmarkContent__arrow-back d-none d-md-flex">
                <ArrowBack/>
            </div>
            <div className="BookmarkContent__header">
                <div className="BookmarkContent__header__title d-none d-md-flex">Meine Merkliste</div>
                <div className="BookmarkContent__header__tabs">
                    <div className={tab === "CURRENT" ? "BookmarkContent__header__tabs__item BookmarkContent__header__tabs__item--active"
                        : "BookmarkContent__header__tabs__item"} onClick={() => setTab("CURRENT")}>
                        Aktuelle Veranstaltungen
                    </div>
                    { pastActivities.length > 0 &&
                        <div className={tab === "PAST" ? "BookmarkContent__header__tabs__item BookmarkContent__header__tabs__item--active"
                            : "BookmarkContent__header__tabs__item"}  onClick={() => setTab("PAST")}>
                          Vergangene Veranstaltungen
                        </div>
                    }
                    { pastActivities.length === 0 &&
                    <div className="BookmarkContent__header__tabs__item BookmarkContent__header__tabs__item--disabled">
                      Vergangene Veranstaltungen
                    </div>
                    }
                </div>
            </div>
            <div className="BookmarkContent__content">
                {_.map(tab === "CURRENT" ? currentActivities : pastActivities, (activity, index) => (
                    <div key={index}>
                        <div className="Activities__thumbnail" key={activity.id}>
                            <ActivityInfoThumbnail activity={activity} withImage={true} />
                        </div>
                    </div>
                ))}
                { currentActivities.length === 0 && tab !== "PAST" &&
                <div className="BookmarkContent__content__no-results">
                  <div className="BookmarkContent__content__no-results__text">
                    <h1>Hier hat es noch Platz!</h1>
                    <p>Markiere Veranstaltungen, die dein Interesse geweckt haben oder du auf keinen Fall verpassen möchtest. Sie erscheinen dann zur besseren Übersicht hier in deiner Merkliste.</p>
                  </div>
                  <div className="BookmarkContent__content__no-results__image">
                    <img src={piggy} alt="" />
                  </div>
                    <div className="d-md-none">
                        <FullwidthButton text="Zurück zur Startseite" onSelect={() => history.push("/")} />
                    </div>
                </div>
                }
            </div>
        </div>
    );
};

export default withRouter(BookmarkContent);
