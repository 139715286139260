import React from 'react';
import './ActivityImageCopywrite.scss';

type ActivityImageCopywriteProps = {
    text: string
};

const ActivityImageCopywrite = ({ text }: ActivityImageCopywriteProps) => {
  return (
      <div className="ActivityImageCopywrite">
          {text}
      </div>
  );
};

export default ActivityImageCopywrite;
