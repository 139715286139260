import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// TODO: move them in a JSON file and import them
const resources = {
  de: {
    translation: {
        "WEEKDAY_0": "Montag",
        "WEEKDAY_1": "Dienstag",
        "WEEKDAY_2": "Mittwoch",
        "WEEKDAY_3": "Donnerstag",
        "WEEKDAY_4": "Freitag",
        "WEEKDAY_5": "Samstag",
        "WEEKDAY_6": "Sonntag",
        "WEEKDAY_0_SHORT": "Mo",
        "WEEKDAY_1_SHORT": "Di",
        "WEEKDAY_2_SHORT": "Mi",
        "WEEKDAY_3_SHORT": "Do",
        "WEEKDAY_4_SHORT": "Fr",
        "WEEKDAY_5_SHORT": "Sa",
        "WEEKDAY_6_SHORT": "So",
        "SURROUNDING.INDOOR": "Drinnen",
        "SURROUNDING.OUTDOOR": "Draussen",
        "SURROUNDING.UNKNOWN": "Drinnen & Draussen",
        "PARENTS.ONLY": "Nur die Eltern",
        "PARENTS.REQUIRED": "Nötig",
        "PARENTS.NONE": "Nicht nötig",
        "ACTIVITY_TYPE.COURSE": "Kurs",
        "ACTIVITY_TYPE.MEETING": "Treffen",
        "ACTIVITY_TYPE.PRESENTATION": "Vorstellung",
        "ACTIVITY_TYPE.EXHIBITION": "Ausstellung",
        "ACTIVITY_TYPE.EVENT": "Ereignis",
        "ACTIVITY_TYPE.PLACE": "Ort",
        "ACTIVITY_TYPE.ONLINE": "Online",
        "ACTIVITY_TYPE.MANUAL": "Anleitung",
        "ACTIVITY_TYPE.SHOP": "Shop",
        "LOCATION_TYPE.LOCATION": "Vor Ort",
        "LOCATION_TYPE.ONLINE": "Online",
        "LOCATION_TYPE.ONLINE_LOCATION": "Ort",
        "MODAL.FEEDBACK.TITLE": "Feedback an wow-wow",
        "MODAL.FEEDBACK.INTRO": "Jede Rückmeldung macht uns besser!",
        "MODAL.FEEDBACK.MESSAGE": "Sag uns was dir gefällt oder eben nicht. Hau ruhig in die Tasten, alle Ideen stossen bei uns auf offene Ohren.",
        "MODAL.FEEDBACK.SUBJECT": "wow-wow Feedback",
        "MODAL.FEEDBACK.EMAIL": "Deine Emailadresse",
        "MODAL.FEEDBACK.SUCCESS": "Jedes Feedback macht uns besser. Danke für deine ehrliche Meinung!",
        "MODAL.FEEDBACK.ERROR": "Es ist ein unerwarteter Fehler aufgetreten.",
        "MODAL.FEEDBACK.CONTACT": "Du kannst es später nochmal probieren oder dein Feedback jetzt direkt senden an",
        "MODAL.FEEDBACK.CONTACT_EMAIL": "feedback@wow-wow.ch",
        "MODAL.FEEDBACK.SEND_BTN": "und ab die Post!",
        "MODAL.CHANGE_REQUEST.TITLE": "Änderung vorschlagen",
        "MODAL.CHANGE_REQUEST.MESSAGE": "Änderung vorschlagen oder als Veranstalter Kontakt aufnehmen.",
        "MODAL.CHANGE_REQUEST.SUBJECT": "wow-wow Änderung vorschlagen",
        "MODAL.CHANGE_REQUEST.EMAIL": "Deine Emailadresse",
        "MODAL.CHANGE_REQUEST.SUCCESS": "Dein Änderungsvorschlag hat uns erreicht – Danke dafür! Wir prüfen diesen und melden uns im Anschluss wenn der Fall gegeben ist zurück.",
        "MODAL.CHANGE_REQUEST.ERROR": "Es ist ein unerwarteter Fehler aufgetreten.",
        "MODAL.CHANGE_REQUEST.CONTACT": "Du kannst es später nochmal probieren oder dein Feedback jetzt direkt senden an",
        "MODAL.CHANGE_REQUEST.CONTACT_EMAIL": "anfrage@wow-wow.ch",
        "MODAL.CHANGE_REQUEST.SEND_BTN": "und ab die Post!",
        "URL_TYPE.WEBSITE":"Webseite",
        "URL_TYPE.SHOP":"Shop",
        "URL_TYPE.STREAMING":"Streaming",
        "URL_TYPE.VIDEO":"Video",
        "URL_TYPE.DOWNLOAD":"Download-Material",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "de",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
