import FormResult from '../models/FormResult.model';
import config from '../config';
import { browserName, fullBrowserVersion, osName, osVersion } from 'react-device-detect';

const axios = require("axios");

export const mail = (email: string, subject: string, message: string): Promise<FormResult> => {
    const messageWithDeviceInfo = `${message} \n\n ${osName} ${osVersion} / ${browserName} ${fullBrowserVersion}`;
    return axios({
        method: 'post',
        data: { email: email, message: messageWithDeviceInfo, subject: subject},
        url: config.urlBackend + '/mailFeedback',
    }).then(
        (response: any) => {
            return {
                status: "SUCCESS",
                errorMessage: ""
            }
        },
        (error: any) => {
            return {
                status: "FAILED",
                errorMessage: error.response && error.response.data ? error.response && error.response.data.message : ""
            }
        });
};

