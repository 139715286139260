import React from 'react';
import LegalContent from '../components/LegalContent/LegalContent';
import Layout from '../components/Layout/Layout';

const Dataprotection = () => {

    return (
        <Layout pageHeaderOnMobile={true} pageHeaderTitle="Datenschutz">
            <div className="fullwidth grey">
                <div className="centered">
                <LegalContent title="Datenschutz" children={
                    <React.Fragment>
                        <br />
                        <p>
                            wow-wow (nachfolgend “wir”, “uns” oder “wow-wow”) freut sich über dein Interesse an unseren Dienstleistungen.
                        </p>
                        <p>
                            Diese Datenschutzerklärung klärt dich über die Art, den Umfang sowie die Zwecke der Erhebung und Verwendung personenbezogener Daten auf der Website wow-wow.ch (nachfolgend “Webseite”) durch wow-wow auf. Mit der Nutzung der Webseite bzw. dem Bestellen unseres Newsletters erklärst du dich mit den nachfolgenden Bedingungen einverstanden. Diese Datenschutzerklärung gilt für alle Dienste von wow-wow.
                        </p>
                        <h1>1. Allgemeines zum Datenschutz</h1>
                        <p>
                            Wir nehmen den Schutz deiner Daten sehr ernst. Wir bearbeiten diese vertraulich, nur soweit nötig und stets im Einklang mit den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                        </p>
                        <p>
                            Wir haben diese Datenschutzerklärung an der europäischen Datenschutz-Grundverordnung (nachfolgend “DSGVO”) ausgerichtet. Es entspricht unserem Anspruch, bei der Bearbeitung von Personendaten dem hohen Schutzniveau der DSGVO zu genügen.
                        </p>
                        <p>
                          Du hast im Rahmen der gesetzlichen Bestimmungen das Recht, über die personenbezogenen Daten, die über dich gespeichert wurden, auf Antrag unentgeltlich Auskunft zu erhalten. Zusätzlich hast du das Recht auf Berichtigung unrichtiger Daten, die Einschränkung der Verarbeitung von zu umfangreich verarbeiteten Daten zu verlangen und auf Löschung von unrechtmässig verarbeiteten bzw. zu lange gespeicherten personenbezogenen Daten zu bestehen. Soweit du deine Einwilligungserklärung zu einer Datenbearbeitung an uns übergeben hast, kannst du diese jederzeit mit einer formlosen Mitteilung per Email widerrufen. Darüber hinaus hast du das Recht zur Beschwerde bei einer Datenschutzaufsichtsbehörde.
                        </p>
                        <p>
                            Diese Datenschutzerklärung gilt für die Datenverarbeitung durch folgenden Verantwortlichen:
                        </p>
                        <p>Verein wow-wow<br/>
                            Spiegelgasse 5<br/>
                            8001 Zürich<br/>
                            <a href={"mailto:info@wow-wow.ch"}>info@wow-wow.ch</a><br/>
                        </p>
                        <h1>2. Erhebung und Nutzung personenbezogener Daten</h1>
                        <p>
                          Gegenstand des Datenschutzes sind „personenbezogene Daten“. Darunter fallen alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (sog. betroffene Person) beziehen. “Bearbeitung” (oder auch “Verarbeitung”) bezeichnet den Umgang mit den Personendaten.
                        </p>
                        <p>
                          Als Personendaten können folgende Informationen gelten:
                            <ul>
                            <li>Kontaktinformationen wie Name, Vorname, Postadresse, Telefonnummer</li>
                            <li>Berufsbezogene Angaben wie Funktion, Arbeitgeber</li>
                            <li>Bild-, oder Videoaufzeichnungen</li>
                            <li>Aufzeichnungen deiner Besuche auf Webseiten oder Apps</li>
                            <li>Deine Standort- und Bewegungsdaten</li>
                            </ul>
                        </p>
                        <p>
                          Als Bearbeitung gelten sodann folgende Handlungen:
                            <ul>
                                <li>Erhebung und Aufbewahrung sowie Organisation und Verwaltung</li>
                                <li>Anpassung Veränderung oder Verknüpfung</li>
                                <li>Verwendung und Nutzung sowie Weitergabe und Offenlegung</li>
                                <li>Einschränkung, Löschung oder Vernichtung</li>
                            </ul>
                        </p>
                        <p>
                          Wir bearbeiten nicht in jedem Fall sämtliche der vorerwähnten Daten. Spezifische Angaben zu den jeweils von uns verarbeiteten personenbezogenen Daten findest du nachfolgend bei den im Einzelnen aufgeführten Datenverarbeitungsvorgängen.
                        </p>
                        <h2>a. Kommunikation im Allgemeinen</h2>
                        <p>
                            Wenn du mit uns (oder wir mit dir) in Kontakt treten, bearbeiten wir deine Personendaten. Also beispielsweise wenn du uns eine E-Mail oder einen Brief schickst. Dabei genügen in der Regel dein Name, die Kontaktdaten, der Zeitpunkt der Mitteilung sowie ihr dazu gehöriger Inhalt. Wir bearbeiten diese Daten um dir Antwort und/oder Auskunft erteilen zu können, sowie um deine Anliegen zu bearbeiten.
                        </p>
                        <p>
                          Wenn eine Kommunikation von dir ausgeht, verstehen wir das als Einwilligung in die Bearbeitung der Personendaten. Des Weiteren liegt die Bearbeitung oftmals in unserem berechtigten Interesse, um mit dir zu kommunizieren, die Qualität unserer Services zu verbessern und somit eine höhere Nutzerzufriedenheit zu erreichen.
                        </p>
                        <h2>b. Besuch der Website</h2>
                        <p>
                            Beim Aufrufen unserer Webseite werden durch den Browser auf deinem Endgerät automatisch Informationen an den Server unserer Webseite übermittelt. Diese Informationen werden temporär automatisch in einem sog. Logfile bis zur Erreichung der unten genannten Zwecke anonymisiert, übermittelt und gespeichert:
                            <ul>
                                <li>IP-Adresse des anfragenden Rechners</li>
                                <li>Datum und Uhrzeit des Zugriffs</li>
                                <li>Name und URL der abgerufenen Datei</li>
                                <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
                                <li>Website, die über unsere Website aufgerufen wird</li>
                                <li>verwendeter Browser und ggf. das Betriebssystem deines Rechners sowie der Name deines Access-Providers</li>
                                <li>technische Daten (u.a. URL-Identifikation, IP-Adresse, Informationen über die von dir verwendeten Gerätetypen)</li>
                                <li>Suchanfragen und Suchverlauf</li>
                                <li>Standortabfrage, um Angebote/Veranstaltungen in deiner Nähe zu sehen</li>
                            </ul>
                        </p>
                        <p>
                            Diese Informationen werden durch uns für folgende Zwecke verarbeitet:
                            <ul>
                                <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website</li>
                                <li>Gewährleistung einer komfortablen Nutzung unserer Webseite</li>
                                <li>Auswertung der Systemsicherheit und -stabilität sowie zu weiteren administrativen Zwecken</li>
                            </ul>
                        </p>
                        <p>
                            Die Bearbeitung selbst erfolgt aus unserem berechtigten Interesse. Sie ist erforderlich um die Nutzung der Webseite zu garantieren. Des Weiteren können wir so die Webseite und das Nutzerverhalten analysieren. Dies liegt auch in deinem Interesse, denn so können wir unser Angebot und die Qualität ständig verbessern.
                        </p>
                        <p>
                            Bei der Durchführung von Wettbewerben, Befragungen und Gewinnspielen werden wir diejenigen personenbezogenen Daten, die von dir im Rahmen zur Verfügung gestellten Daten nur für die jeweils notwendige Dauer gespeichert. Dabei können die Daten, die gesammelt werden je nach Wettbewerb variieren.
                        </p>
                        <h2>c. Bestellung des Newsletters</h2>
                        <p>
                          Wenn du möchtest, kannst du bei uns einen Newsletter abonnieren. Sofern du uns deine Einwilligung erteilt hast, verwenden wir deine E-Mail-Adresse, um dir in unregelmässigen Abständen einen Newsletter zuzusenden. Durch das Erteilen der Einwilligung akzeptierst du auch diese Datenschutzerklärung.
                        </p>
                        <p>
                          Wenn du zudem auf unserer Webseite Waren oder Dienstleistungen erwerben möchtest und uns dazu deine E-Mail-Adresse hinterlegst, erteilst du uns ebenfalls die Einwilligung, dir ebenfalls Newsletter zuzustellen.
                        </p>
                        <p>
                          Die Abmeldung des Newsletters ist jederzeit möglich, unabhängig davon ob die Zusendung des Newsletters auf Einwilligung oder gesetzlicher Erlaubnis basiert, zum Beispiel über einen Link am Ende eines jeden Newsletters. Alternativ kannst du deinen Abmeldewunsch auch jederzeit an info@wow-wow.ch per E-Mail senden.
                        </p>
                        <p>
                          Die für den Versand des Newsletters benötigten Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind und soweit keine andere gesetzliche Ermächtigungsgrundlage zur weiteren Verarbeitung greift. Deine E-Mail-Adresse wird nur solange für die Versendung des Newsletters gespeichert, bis du deine Einwilligung widerrufst bzw. du der Zusendung des Newsletters widersprichst.
                        </p>
                            <h2>d. Verwendung des Feedbackformulars</h2>
                        <p>
                            Du kannst dich mit deinem Anliegen jederzeit über das Feedbackformular an uns wenden. Hierfür benötigen wir die Angabe einer gültigen Email-Adresse um diese einem Absender zuzuordnen. Diese E-Mail-Adresse wird für die Beantwortung der Anfrage verwendet. Zusätzliche Angaben deinerseits erfolgen freiwillig, ohne dass wir dich dazu auffordern.
                        </p>
                        <p>
                            Wenn du dich mittels dem Feedbackformular an uns wendest, verstehen wir das als Einwilligung in die Datenbearbeitung. Des Weiteren liegt die Bearbeitung in unserem berechtigten Interesse, denn so können wir mit dir kommunizieren, die Qualität unseres Services verbessern und somit eine höhere Nutzerzufriedenheit erreichen.
                        </p>
                        <h2>e. Erstellung eines Benutzerkontos</h2>
                        <p>
                            Als Benutzer steht es dir frei, im Rahmen des Registrierungsvorgangs ein Benutzerkonto zu erstellen. Mit Angabe deiner Emailadresse und deinem selbst gewählten Passwort erhältst du Zugriff auf deine personenbezogenen Kontoangaben und Angebote. Wenn du ein Benutzerkonto erstellst, verstehen wir dies als Einwilligung in die Bearbeitung der Personendaten.
                        </p>
                        <p>
                            Das von dir angelegte Konto wird auf deinen Wunsch hin gelöscht. Dazu schickst du uns eine E-Mail an info@wow-wow.ch. Bis zu diesem Zeitpunkt speichern wir die entsprechenden Daten, um dir den Zugriff auf dein Konto jederzeit sicher zu stellen.
                        </p>
                        <h2>f. Einhaltung rechtlicher Anforderungen</h2>
                        <p>
                            Wir bearbeiten Personendaten auch um rechtliche Vorgaben einzuhalten und Verstösse zu verhindern bzw. aufzudecken. Dazu gehören unter anderem die Entgegennahme von Beschwerden, oder die Offenlegung von Unterlagen an ein Gericht oder eine Behörde. Dies geschieht nur wenn wir dazu einen sachlichen Grund haben, oder rechtlich dazu verpflichtet sind. In diesem Zusammenhang können wir auch Personendaten von Dritten bearbeiten. Die Bearbeitung erfolgt stets im Rahmen unserer berechtigten Interessen und/oder der Einhaltung rechtlicher Vorgaben.
                        </p>
                        <h2>g. Rechtswahrung</h2>
                        <p>
                            Wir bearbeiten Personendaten auch, um unsere Rechte zu wahren. Dazu gehören die Durchsetzung von Ansprüchen vor Gericht und vor Behörden, sowie die Verteidigung gegenüber Ansprüchen. Dabei können wir deine Personendaten oder die Personendaten von Dritten bearbeiten und/oder Personendaten an Dritte im In- und Ausland weitergeben, soweit dies erforderlich und zulässig ist.
                        </p>
                        <h1>3. Offenlegung an Dritte</h1>
                        <p>
                            Wir geben personenbezogene Daten an Dienstanbieter weiter, welche sich auf die Verarbeitung von Informationen, Verwaltung und Pflege von Kundendaten, Erbringung eines Kundendienstes oder die Durchführung von Umfragen zur Kundenzufriedenheit spezialisiert haben. Wir geben deine personenbezogenen Daten jedoch nur dann an Dritte (Empfänger) weiter, wenn wir dazu nach den datenschutzrechtlichen Bestimmungen berechtigt sind. Dies kann unter anderem in folgenden Fällen zutreffen, nämlich wenn:
                            <ul>
                                <li>du uns deine Einwilligung für einen oder mehrere bestimmte Zwecke erteilt hast</li>
                                <li>die Verarbeitung für die Erfüllung eines Vertrags mit dir, oder zur Durchführung vorvertraglicher Massnahmen erforderlich ist, die auf deine Anfrage hin erfolgen</li>
                                <li>die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung, der wir unterliegen, dies erforderlich macht (siehe dazu Lit. f.)</li>
                                <li>die Verarbeitung zur Wahrung unserer berechtigten Interessen oder eines Dritten erforderlich ist, sofern nicht deine Interessen oder Grundrechte, die den Schutz deiner personenbezogenen Daten erfordern, überwiegen</li>
                            </ul>
                        </p>
                        <p>
                            Wir arbeiten mit Dienstleistern (auch“ Auftragsverarbeitern”) zusammen, denen wir deine personenbezogenen Daten übermitteln, und die deine Daten in unserem Auftrag und nach unserer Weisung für uns verarbeiten. Dabei handelt es sich um folgende Dienstleister:
                            <ul>
                                <li>Google, Inc.</li>
                                <li>The Rocket Science Group LLC d/b/a Mailchimp</li>
                                <li>Cloudinary Ltd.</li>
                            </ul>
                        </p>
                        <p>
                          Findet eine Datenübermittlung an Stellen statt, deren Sitz oder deren Ort der Datenverarbeitung nicht in einem Mitgliedstaat der Europäischen Union oder in einem anderen Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum gelegen ist, stellen wir vor der Weitergabe sicher, dass ausserhalb von gesetzlich erlaubten Ausnahmefällen beim Empfänger entweder ein angemessenes Datenschutzniveau besteht (z.B. durch ein Angemessenheitsbeschluss der Europäischen Kommission, durch geeignete Garantien wie eine Selbst-Zertifizierung des Empfängers für das EU-US Privacy Shield oder die Vereinbarung sogenannter EU-Standardvertragsklauseln der Europäischen Union mit dem Empfänger) bzw. ihre hinreichende Einwilligung vorliegt.
                        </p>
                        <p>
                          Es besteht die Möglichkeit, dass wir Informationen an Dritte weitergeben, um unsere Produkte und Dienste anzubieten bzw. zu verbessern. In einem solchen Fall verlangen wir von den Dritten, dass sie die personenbezogenen Daten unter Anwendung der jeweils anwendbaren gesetzlichen Bestimmungen handhaben.
                        </p>
                        <p>
                            Weiter behalten wir uns das Recht vor, im Falle einer Sanierung, eines Verkaufs oder eines Zusammenschlusses mit einem anderen Verein oder einer Firma sämtliche personenbezogene Daten bereitzustellen.
                        </p>
                        <h1>4. Cookies</h1>
                        <p>
                            Wir setzen auf unserer Website Cookies ein. Hierbei handelt es sich um kleine Dateien, die dein Browser automatisch erstellt und die auf deinem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn du unsere Website besuchst.
                        </p>
                        <p>
                            In einem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von deiner Identität erhalten. Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für dich angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass du einzelne Seiten unserer Website bereits besucht hast. Diese werden nach Verlassen unserer Website automatisch gelöscht.
                        </p>
                        <p>
                            Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf deinem Endgerät gespeichert werden. Besuchst du unsere Website erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass du bereits bei uns warst und welche Eingaben und Einstellungen du getätigt hast, um diese nicht noch einmal eingeben zu müssen.
                        </p>
                        <p>
                            Zum anderen setzen wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für dich auszuwerten. Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Website automatisch zu erkennen, dass du bereits bei uns warst. Diese Cookies werden gelöscht, wenn du dich ausloggst oder den Browser schliesst.
                        </p>
                        <p>
                            Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen erforderlich. So können sie z.B. erforderlich sein, um individuelle Einstellungen zu speichern. Diese Individualisierung liegt auch in deinem Interesse.
                        </p>
                        <p>
                            Die meisten Browser akzeptieren Cookies automatisch. Du kannst deinen Browser jedoch so konfigurieren, dass keine Cookies auf deinem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass nicht mehr alle Teile und/oder Funktionen unserer Webseite genutzt werden  können. Mehr dazu auf <a href="www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</a> und <a href="www.youronlinechoices.eu" target="_blank">www.youronlinechoices.eu</a>.
                        </p>
                        <h1>5. Analysetools</h1>
                        <p>
                            Mit den folgenden, zum Einsatz kommenden Tracking-Massnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unseres Angebots sicherstellen. Zum anderen setzen wir die Tracking-Massnahmen ein, um die Nutzung unseres Angebots statistisch zu erfassen und zum Zwecke der Optimierung auszuwerten.
                        </p>
                        <p>
                            Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tracking-Tools in dieser Ziffer zu entnehmen:
                            <ul>
                                <li>Google Analytics mit Anonymisierungsfunktion</li>
                                <li>Google Adwords Conversion Tracking</li>
                                <li>Google Remarketing</li>
                            </ul>
                        </p>
                        <p>
                          Die vorgenannten Bearbeitungszwecke liegen in unserem berechtigten Interesse. Unsere Webseite ist für uns einziges Mittel zur Kundenkommunikation und zum Anbieten der Dienstleistungen. Es ist für uns somit von essentieller Bedeutung, die Webseite funktionell, attraktiv und persönlich zu halten.
                        </p>
                        <h1>6. Social Media</h1>
                        <p>
                            Wir setzen auf unserer Webseite einfache HTML-Links zu sozialen Netzwerken (Facebook, Instagram sowie Youtube). Es werden dabei keine Skripte eingebunden, die deine IP-Adresse oder andere Daten direkt an die Betreiber der sozialen Netzwerke übermittelt. Wir tauschen keine Daten über deine Nutzung unserer Webseite bzw. der Webseiten der sozialen Netzwerke mit deren Betreibern aus. Klickst du auf die Links, rufst du darüber die Webseite des jeweiligen sozialen Netzwerks auf. Die Verantwortung für die dortige Datenerhebung und -verarbeitung liegt in jedem Fall beim jeweiligen Betreiber selbst.
                        </p>
                        <p>
                            Nähere Informationen zum Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook Inc., Instagram LLC und Youtube LLC sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre findest du in deren gültigen Datenschutzbestimmungen.
                        </p>
                        <p>
                            Die oben genannten Bearbeitungszwecke liegen in unseren berechtigten Interessen. Es ist für uns zentral, die Webseite attraktiv zu gestalten und demzufolge die Interaktion mit dem Besucher der Webseite zu steigern.
                        </p>
                        <h1>7. Deine Rechte</h1>
                        <p>
                            Du hast das Recht Datenbearbeitungen zu widersprechen, wenn wir deine Personendaten auf Grundlage des berechtigten Interesses bearbeiten.
                        </p>
                        <p>
                            Sind die jeweils gesetzlichen Anforderungen erfüllt und keine gesetzlichen Ausnahmen anwendbar, hast du ausserdem die folgenden Rechte:
                            <ul>
                                <li>Recht auf Information über die Art und Weise sowie die Gründe für die Bearbeitung der Personendaten</li>
                                <li>Recht auf Auskunft über die Bearbeitung und Speicherung der Personendaten</li>
                                <li>Recht auf Berichtigung der Datenbearbeitung</li>
                                <li>Recht auf Löschung der Personendaten</li>
                                <li>Recht auf Einschränkung der Bearbeitung der Personendaten</li>
                                <li>Recht auf Datenübertragung der Personendaten zu ihnen mittels einem strukturierten, gängigen Format</li>
                                <li>Widerrufsrecht der Bearbeitung von Personendaten. Voraussetzung dafür ist, dass du uns die Einwilligung zur Bearbeitung der Personendaten erteilt hast.</li>
                            </ul>
                        </p>
                        <p>
                            Wenn du eines der obengenannten Rechte ausüben möchtest, kontaktiere uns diesbezüglich bitte via info@wow-wow.ch. Es steht dir ausserdem frei, dich bei einer zuständigen Aufsichtsbehörde zu melden, wenn du der Ansicht bist, dass die Datenbearbeitung gegen geltendes Recht verstösst. In der Schweiz ist die zuständige Stelle der Eidgenössische Datenschutz und Öffentlichkeitsbeauftragte (EDÖB). Mehr Informationen dazu findest du auf <a href="www.edoeb.admin.ch/edoeb/de/home.html" target="_blank">www.edoeb.admin.ch/edoeb/de/home.html</a>.
                        </p>
                        <h1>8. Übermittlung in andere Länder</h1>
                        <p>
                            Personenbezogene Daten, die innerhalb der Europäischen Union und der Schweiz erhoben werden, können an Dritte, die sich ausserhalb der Europäischen Union oder der Schweiz befinden, übermittelt werden. Wir stellen sicher, dass die Übermittlung der Daten jederzeit sicher und gemäss den jeweils geltenden Datenschutzgesetzen erfolgt. Wir stellen zudem sicher, dass in einem solchen Fall angemessene technische und organisatorische Massnahmen vorhanden sind.
                        </p>
                        <h1>9. Links</h1>
                        <p>
                            Wir zeigen Inhalte an, die auf Webseiten Dritter verweisen. Wir können die Datenschutzrichtlinien und Inhalte dieser Webseiten weder kontrollieren noch für sie verantwortlich gemacht werden. Wenn du folglich auf einen Link oder eine Anzeige Dritter klicken, beachte bitte, dass du die Webseite und den Service von wow-wow.ch verlässt. Alle personenbezogenen Daten, die du darauffolgend angibst, unterstehen nicht mehr der vorliegenden Richtlinie.
                        </p>
                        <h1>10. Spenden</h1>
                        <p>
                            Auf unserer Webseite kannst du einfach und sicher via Internet eine Spende tätigen. Dies kannst du mittels PayPal oder Kreditkarten tun. Wir arbeiten dafür mit der zertifizierten E-Payment-Plattform Donorbox.org zusammen. Deine im Zusammenhang mit der Spende angegebenen Daten, legen wir in unserer Spenderdatenbank ab.
                        </p>
                        <h1>11. Schutz personenbezogener Daten</h1>
                        <p>
                            Wir setzen angemessene technische und organisatorische Massnahmen ein, um deine personenbezogenen Daten zu schützen. Dazu haben wir einen Massnahmenkatalog implementiert, um unbefugten Zugriff auf die Daten zu verhindern. Bitte beachte jedoch, dass eine vollständige Sicherheit niemals gewährleistet werden kann.
                        </p>
                        <p>
                            Dein Passwort schützt dein Benutzerkonto, daher empfehlen wir dir ein möglichst sicheres Passwort zu verwenden, welches nur für dieses Benutzerkonto gilt. Es sollte niemals an Dritte weitergegeben werden. Des Weiteren sollte der Zugang zu deinem Computer und Browser mittels eines Passworts geschützt werden. Logge dich nach der Nutzung unserer Webseite jeweils aus.
                        </p>
                        <h1>12. Änderungen und Aktualität dieser Datenschutzerklärung</h1>
                        <p>
                            Durch die Weiterentwicklung unser Webseite und Dienstleistungen darüber oder aufgrund geänderter gesetzlicher oder behördlicher Vorgaben behalten wir uns das Recht vor, diese Datenschutzerklärung fortlaufend anzupassen. Die jeweils aktuelle Datenschutzerklärung kannst du jederzeit auf der Website unter <a href="https://wow-wow.ch/#/dataprotection" target="_blank">https://wow-wow.ch/#/dataprotection</a> abrufen.
                        </p>
                        <p>
                            Sollten wir wesentliche Änderungen vornehmen, werden wir dich in deutlicher Form benachrichtigen, entweder durch Versand einer Email an dich oder unter Einblendung einer Mitteilung innerhalb des von dir genutzten Services. Lese solche Mitteilungen bitte stets sorgfältig durch, bevor du dieser zustimmst.
                        </p>
                        <p>
                            Stand: Mai 2020
                        </p>
                    </React.Fragment>
                }></LegalContent>
                </div>
            </div>
        </Layout>
    );
};

export default Dataprotection;
