import React from 'react';
import './LegalContent.scss';
import ArrowBack from '../ArrowBack/ArrowBack';

type LegalPageComponentProps = {
    children: any,
    title: string,
};

const LegalContent = ({ children, title }: LegalPageComponentProps) => {
    return (
        <div className="LegalContent">
            <div className="LegalContent__arrow-back d-none d-md-flex">
                <ArrowBack/>
            </div>
            <div className="LegalContent__content">
                <div className="LegalContent__content__title">{title}</div>
                {children}
            </div>
        </div>
    )
};

export default LegalContent;
