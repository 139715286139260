const dev = {
  urlFrontend: "https://beta.wow-wow.ch",
  urlBackend: "https://us-central1-wow-wow-ch.cloudfunctions.net"
};

const prod = {
  urlFrontend: "https://wow-wow.ch",
  urlBackend: "https://us-central1-wow-wow-ch.cloudfunctions.net"
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  gtmKey: process.env.REACT_APP_GTM_KEY,
  googleMapsApiKey: "AIzaSyD3dW9897SUuHPiiS2qAV6Qz3GTyUx9Ck4",
  ...config
};
