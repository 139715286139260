import React from 'react';
import LegalContent from '../components/LegalContent/LegalContent';
import Layout from '../components/Layout/Layout';

const AGB = () => {

    return (
        <Layout pageHeaderOnMobile={true} pageHeaderTitle="AGB">
            <div className="fullwidth grey">
                <div className="centered">
                <LegalContent title="Allgemeine Geschäftsbedingungen" children={
                    <React.Fragment>
                        <h1>1. Geltungsbereich</h1>
                        <p>Die Allgemeinen Geschäftsbedingungen (nachfolgend «AGB») gelten für sämtliche Dienstleistungen
                            und Rechtsbeziehungen (nachfolgend «Dienstleistungen»), welche von und mit wow-wow (nachfolgend
                            «Verein») abgeschlossen werden. Davon umfasst sind insbesondere das Abonnement des Newsletters
                            sowie das Inserieren eines Angebots.
                        </p>
                        <p>Durch das Eingehen einer Dienstleistung akzeptiert der Benutzer vorliegende AGB in vollständiger
                            Weise.
                        </p>
                        <h1>2. Datenschutz</h1>
                        <p>Für die Bearbeitung von Personendaten gilt die Datenschutzerklärung. Die jeweils aktuelle Fassung
                            ist unter wow-wow.ch/datenschutz einsehbar. Die Datenschutzerklärung ist integraler Bestandteil
                            dieser AGB.
                        </p>
                        <h1>3. Angebot des Vereins</h1>
                        <p>Der Verein agiert als Vermittler von kulturellen, kindgerechten Ereignissen und Veranstaltungen
                            in der Schweiz, die politisch und konfessionell neutral sind. Der Verein fördert, verbreitet,
                            betreut und organisiert Freizeitprogramme für alle Kindern im Alter von 0-12 Jahren. Das
                            Inserieren eines Angebots vom Veranstalter nachfolgend «Veranstalter» sowie das Informieren (für
                            den Benutzer) ist kostenlos.
                        </p>
                        <p>Der Verein kann mit dem Veranstalter eines Angebots einen direkten Vertragsschluss tätigen und
                            Dienstleistungen gegen Entgelt zum Erfassen einer Veranstaltung anbieten.
                        </p>
                        <h1>4. Abonnement des Newsletters</h1>
                        <p>Auf wow-wow.ch wird dem Benutzer die Möglichkeit eingeräumt, einen Newsletter des Vereins zu
                            abonnieren. Dieser Newsletter dient dazu, den Benutzer in unregelmässigen Abständen über
                            Freizeitangebote im Bereich von kulturellen, kindgerechten Ereignissen und Veranstaltungen zu
                            informieren (siehe Ziffer 3. Angebot des Vereins). Der Newsletter kann vom Benutzer nur
                            empfangen werden, wenn er sich für den Versand mittels einer gültigen Email-Adresse registriert.
                            Aus rechtlichen Gründen wird dem Benutzer bei der erstmaligen Registrierung eine Email zwecks
                            Bestätigung im Double-Opt-In-Verfahren zugestellt. Diese Email dient der Überprüfung, ob der
                            Benutzer den Empfang des Newsletters tatsächlich autorisiert hat.
                        </p>
                        <p>Bei der Anmeldung zum Newsletter speichert der Verein die Email-Adresse und den Namen des
                            Benutzers, sowie das Datum der Anmeldung. Die Erhebung dieser Daten ist notwendig, um einen
                            möglichen Missbrauch der Email-Adresse eines Benutzers zu einem späteren Zeitpunkt
                            nachvollziehen zu können.
                        </p>
                        <p>Des Weiteren kann der Verein den Benutzer über Änderungen des Angebots oder über Veränderungen
                            der technischen Gegebenheiten informieren.
                        </p>
                        <p>Es erfolgt keine Weitergabe der im Rahmen des Newsletter-Dienstes erhobenen personenbezogenen
                            Daten an Dritte. Das Abonnement des Newsletters kann vom Benutzer jederzeit gekündigt werden.
                            Die Einwilligung in die Speicherung personenbezogener Daten, welche der Benutzer dem Verein für
                            den Newsletterversand erteilt hat, kann jederzeit widerrufen werden. Zum Zwecke des Widerrufs
                            der Einwilligung findet sich in jedem Newsletter ein entsprechender Link.
                        </p>
                        <h1>5. Gewährleistung</h1>
                        <p>Der Verein erbringt seine Leistungen im Rahmen seiner betrieblichen Ressourcen und der
                            vorhersehbaren Anforderungen sorgfältig und fachgerecht, soweit er nicht durch ihn nicht zu
                            vertretende Umstände daran gehindert wird.
                        </p>
                        <p>Der Benutzer ist sich bewusst, dass der Verein seine Leistungen über das Internet sowie unter
                            Inanspruchnahme von Kommunikationsnetzen erbringt. Namentlich aufgrund technischer Störungen,
                            Betriebsstörungen sowie Störung oder Unterbrechung von Kommunikationsnetzen und durch einen
                            Ausfall von IT-Infrastrukturen oder anderer Teile der zur Leistungserbringung beanspruchten
                            Infrastruktur kann es zu vorübergehenden Beeinträchtigungen oder Unterbrüchen der
                            Leistungserbringung vom Verein kommen. Der Verein gibt daher keine Gewähr für die
                            ununterbrochene Verfügbarkeit und Fehlerfreiheit seiner Leistungen.
                        </p>
                        <h1>6. Haftung des Vereins</h1>
                        <p>Die Haftung für vom Verein fahrlässig verursachten Schaden, sowie sämtliche weitere Schäden, wird
                            wegbedungen. Dies gilt insbesondere für indirekte und mittelbare Schäden und Folgeschäden wie
                            entgangenen Gewinn, nicht realisierte Einsparungen, Schäden aus Lieferverzug sowie jegliche
                            Handlungen und Unterlassungen des Vereins, sei dies vertraglich oder ausservertraglich.
                        </p>
                        <p>Der Verein lehnt jede Haftung für Schäden ab, welche durch eine vorübergehende oder dauerhafte
                            Unterbrechung von durch ihn erstellte oder bereitgestellte Services entstehen. Der Verein
                            übernimmt keinerlei Haftung oder Gewährleistung für den Bezug von Diensten oder Informationen
                            Dritter.
                        </p>
                        <p>Der Verein ist für die auf der Webseite sowie die im Newsletter enthaltenen Angebotsinhalte nicht
                            verantwortlich. Insbesondere ist der Verein nicht verpflichtet, die Inhalte auf mögliche
                            Rechtsverstösse hin zu überprüfen.
                        </p>
                        <p>Der Verein lehnt jede Haftung für Schäden ab, die dem Benutzer bei der Inanspruchnahme eines
                            Angebots eines Veranstalters entstehen.
                        </p>
                        <h1>7. Haftung des Benutzers</h1>
                        <p>Der Benutzer haftet gegenüber dem Verein unbeschränkt für durch rechtswidrige Absicht oder grobe
                            Fahrlässigkeit verursachte direkte und nachgewiesene Schäden.
                        </p>
                        <h1>8. Schlussbestimmungen</h1>
                        <p>Erfüllungsort für sämtliche Dienstleistungen ist Zürich.</p>
                        <p>Der Verein behält sich das Recht vor, die AGB jederzeit zu ändern. Massgebend ist jeweils die zum
                            Zeitpunkt der Bestellung geltende Version dieser AGB, welche für diese Dienstleistung nicht
                            einseitig geändert werden können. Entgegenstehende oder von diesen AGB abweichende Bedingungen
                            des Benutzers werden nicht anerkannt.
                        </p>
                        <p>Sollten einzelne Bestimmungen dieser AGB ungültig oder unwirksam sein, so hat dies keinen
                            Einfluss auf die Wirksamkeit der übrigen Bestimmungen und dieser AGB insgesamt.
                        </p>
                        <p>Der Benutzer verzichtet darauf, Forderungen gegenüber dem Verein zur Verrechnung zu bringen.
                        </p>
                        <h1>9. Anwendbares Recht und Gerichtsstand</h1>
                        <p>Sämtliche Dienstleistungen unterstehen materiellem Schweizer Recht unter Ausschluss des
                            Bundesgesetzes über das Internationale Privatrecht (IPRG) und des UN-Kaufrechts (CISG).
                        </p>
                        <p>Die Gerichte von Zürich sind für die Beurteilung sämtlicher Streitigkeiten zuständig. Der Verein
                            behält sich das Recht vor, allfällige Ansprüche gegen den Benutzer an dessen Domizil gerichtlich
                            durchzusetzen.
                        </p>
                        <p>Letzte Aktualisierung: November 2019
                        </p>
                    </React.Fragment>
                }></LegalContent>
                </div>
            </div>
        </Layout>
    );

};

export default AGB;
