import React from 'react';
import './ActivityInfoThumbnail.scss';
import Activity from '../../models/Activity.model';
import DateTime from '../DateTime/DateTime';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BookmarkIcon from '../BookmarkIcon/BookmarkIcon';
import { getAgesText } from '../../utils/AttributeUtils';
import ActivityImage from '../ActivityImage/ActivityImage';
import Distance from '../Distance/Distance';
import Category from '../Category/Category';
import AddToCalendarIcon from '../AddToCalendarIcon/AddToCalendarIcon';
import { getStyleForCategoryBg } from '../../utils/CategoryUtils';
import ReactPlaceholder from 'react-placeholder';

type ActivityInfoThumbnailProps = {
    activity: Activity | null,
    withImage: boolean
};

const ActivityInfoThumbnail = ({ activity, withImage, history }:
                          RouteComponentProps<{}> & ActivityInfoThumbnailProps) => {
  const hasImage = activity && ((activity.images && activity.images.length > 0) || activity.imageUrls && activity.imageUrls.length > 0);

  return (
      <div className={"ActivityInfoThumbnail " + getStyleForCategoryBg(activity ? activity.category : '')} onClick={() => {
          if (activity) {
              history.push(`/activity/${activity.id}/${activity.displayDate ? activity.displayDate.format('YYYY-MM-DD') : ''}/`);
          }
      }}>
          <div className={withImage && hasImage ? "ActivityInfoThumbnail__datetime--withimage" : "ActivityInfoThumbnail__datetime--noimage"}>
              <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!activity} color="#E0E0E0" style={{marginTop: 0, maxWidth: 100}}>
                  {activity && <DateTime dates={activity.dates} displayDate={activity.displayDate}/>}
              </ReactPlaceholder>
          </div>
          { (!activity || (withImage && hasImage)) &&
          <React.Fragment>
            <div className="ActivityInfoThumbnail__title--withimage">
              <div className="ActivityInfoThumbnail__title">
                <ReactPlaceholder showLoadingAnimation type='text' rows={2} ready={!!activity} color="#E0E0E0" style={{width: '80%'}}>
                    {activity ? activity.title : ''}
                </ReactPlaceholder>
              </div>
            </div>
            <div className="ActivityInfoThumbnail__image">
                <ActivityImage activity={activity}
                               imageSizeSm={{height: 88, width: 96}}
                               imageSizeMd={{height: 184, width: 186}} />
            </div>
          </React.Fragment>
          }
          { activity && (!withImage || !hasImage) &&
              <div className="ActivityInfoThumbnail__title--noimage">
                  {activity.title}
              </div>
          }
          <div className="ActivityInfoThumbnail__category">
              <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!activity} color="#E0E0E0" style={{marginTop: 0, maxWidth: 100}}>
                  {activity && <Category activity={activity}/>}
              </ReactPlaceholder>
          </div>
          <div className="ActivityInfoThumbnail__ages">
              <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!activity} color="#E0E0E0" style={{marginTop: 0, maxWidth: 50}}>
                  {activity && getAgesText(activity.ages)}
              </ReactPlaceholder>
          </div>
          <div className="ActivityInfoThumbnail__distance">
              <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!activity} color="#E0E0E0" style={{marginTop: 0, maxWidth: 80}}>
                  {activity && <Distance activity={activity}/>}
              </ReactPlaceholder>
          </div>
          {activity &&
              <div className="ActivityInfoThumbnail__actions d-none d-md-flex">
                <AddToCalendarIcon activity={activity}/>
                <BookmarkIcon activity={activity}/>
              </div>
          }
          <div className="ActivityInfoThumbnail__actions d-md-none">
              {activity && <BookmarkIcon activity={activity} />}
          </div>
      </div>
  );
};

export default withRouter(ActivityInfoThumbnail);
