import React from 'react';
import './BlogContent.scss';
import {
    AbstractBlock,
    Block,
    Blog,
    HeaderBlock,
    ImageBlock, ImageTextBlock,
    ParagraphBlock,
    QuoteBlock
} from '../../models/Blog.model';
import { nanoid } from 'nanoid'
import ArrowBack from '../ArrowBack/ArrowBack';
import BlogDate from '../BlogDate/BlogDate';
import BlogAuthor from '../BlogAuthor/BlogAuthor';
import { CloudinaryContext, Image, Transformation } from "cloudinary-react";
import SocialShareBlog from '../SocialShareBlog/SocialShareBlog';
import BlogThumbnail from '../BlogThumbnail/BlogThumbnail';
import Newsletter from '../Newsletter/Newsletter';
import cat from '../../assets/images/WowWows_Vectors_Katze.svg';
import FormResult from '../../models/FormResult.model';
import _ from 'lodash';

type BlogContentProps = {
    nextBlog: Blog | null,
    blog: Blog,
    subscribeToNewsletter: (email: string) => Promise<FormResult>
}

const BlogContent = ({blog, nextBlog, subscribeToNewsletter}: BlogContentProps) => {

    const getBlockContent = (block: Block, index: number) => {
        switch (block.type) {
            case 'abstract':
                const abstractBlock = block as AbstractBlock;
                return (
                    <p className="abstract" key={index}>
                        {abstractBlock.data.text}
                    </p>
                );
            case 'quote':
                const quoteBlock = block as QuoteBlock;
                return (
                    <blockquote key={index}>
                        {quoteBlock.data.text}
                    </blockquote>
                )
            case 'paragraph':
                const paragraphBlock = block as ParagraphBlock;
                return (
                    <p key={index} dangerouslySetInnerHTML={{
                        __html: paragraphBlock.data.text
                    }}>
                    </p>
                )
            case 'header':
                const headerBlock = block as HeaderBlock;
                const HeaderTag = `h${headerBlock.data.level}` as keyof JSX.IntrinsicElements;
                return (
                    <HeaderTag key={nanoid()}>{headerBlock.data.text}</HeaderTag>
                )
            case 'image':
                const imageBlock = block as ImageBlock;
                return (
                    <React.Fragment key={nanoid()}>
                        <div className="block__image d-none d-md-flex" key={nanoid()}  style={{maxWidth: 806}}>
                            <CloudinaryContext cloudName="wow-wow">
                                <Image publicId={imageBlock.data.url}>
                                    <Transformation dpr="2.0" gravity="face:center" width={806} crop="fill"/>
                                </Image>
                            </CloudinaryContext>
                            <div className="block__image__caption">
                                {imageBlock.data.caption}
                            </div>
                        </div>
                        <div className="block__image d-md-none" key={nanoid()}  style={{maxWidth: 416}}>
                            <CloudinaryContext cloudName="wow-wow">
                                <Image publicId={imageBlock.data.url}>
                                    <Transformation dpr="2.0" gravity="face:center" width={416} crop="fill"/>
                                </Image>
                            </CloudinaryContext>
                            <div className="block__image__caption">
                                {imageBlock.data.caption}
                            </div>
                        </div>
                    </React.Fragment>
                );
            case 'imageText':
                const textImageBlock = block as ImageTextBlock;
                return (
                    <React.Fragment>
                            <div className="block__img-txt d-none d-md-flex" key={nanoid()}>
                                <div className="block__img-txt__img" style={{maxWidth: 219}}>
                                    <CloudinaryContext cloudName="wow-wow">
                                        <Image publicId={textImageBlock.data.url}>
                                            <Transformation dpr="2.0" gravity="face:center" width={219} crop="fill"/>
                                        </Image>
                                    </CloudinaryContext>
                                </div>
                                <div className="block__img-txt__text" dangerouslySetInnerHTML={{
                                    __html: textImageBlock.data.text
                                }}>
                                </div>
                            </div>
                            <div className="block__img-txt d-md-none" key={nanoid()}>
                                <div className="block__img-txt__img" style={{maxWidth: 120}}>
                                    <CloudinaryContext cloudName="wow-wow">
                                        <Image publicId={textImageBlock.data.url}>
                                            <Transformation dpr="2.0" gravity="face:center" width={120} crop="fill"/>
                                        </Image>
                                    </CloudinaryContext>
                                </div>
                                <div className="block__img-txt__text" dangerouslySetInnerHTML={{
                                    __html: textImageBlock.data.text
                                }}>
                                </div>
                            </div>
                    </React.Fragment>
                )
        }
    }

    return (
        <div className="BlogContent">
            <div className="BlogContent__arrow-back d-none d-md-flex">
                <ArrowBack/>
            </div>
            <div className="BlogContent__date">
                <BlogDate date={blog.date}/>
            </div>
            <div className="BlogContent__title">
                {blog.title}
            </div>
            { blog.author &&
                <div className="BlogContent__about">
                  <BlogAuthor author={blog.author}/>
                </div>
            }
            { blog.header && blog.header.image &&
                <React.Fragment>
                  <div className="BlogContent__header-img d-none d-md-flex" style={{maxHeight: 391, maxWidth: 1391}}>
                    <CloudinaryContext cloudName="wow-wow">
                      <Image publicId={blog.header.image.url}>
                        <Transformation dpr="2.0" gravity="face:center" height={391} width={1391} crop="fill"/>
                      </Image>
                    </CloudinaryContext>
                  </div>
                  <div className="BlogContent__header-img d-md-none" style={{maxHeight: 120, maxWidth: 416}}>
                    <CloudinaryContext cloudName="wow-wow">
                      <Image publicId={blog.header.image.url}>
                        <Transformation dpr="2.0" gravity="face:center" height={120} width={416} crop="fill"/>
                      </Image>
                    </CloudinaryContext>
                  </div>
                  <div className="BlogContent__header-img__caption">
                      {blog.header.image.caption}
                  </div>
                </React.Fragment>
            }
            <div className="BlogContent__social-share">
                <SocialShareBlog text={blog.title}/>
            </div>
            <div className="BlogContent__other d-none d-md-flex">
                { nextBlog &&
                    <div className="BlogContent__other__blog">
                      <BlogThumbnail blog={nextBlog}/>
                    </div>
                }
                <div className="BlogContent__other__newsletter">
                    <Newsletter subscribeToNewsletter={subscribeToNewsletter}/>
                </div>
                <div className="BlogContent__other__cat">
                    <img src={cat} alt=""/>
                </div>
            </div>
            { blog.content && blog.content.blocks &&
                <div className="BlogContent__blocks">
                    {_.map(blog.content.blocks, (block, index) => getBlockContent(block, index)
                    )}
                </div>
            }
        </div>
    );
};

export default BlogContent;
