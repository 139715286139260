import React from 'react';
import './HighlightCard.scss';
import Activity from '../../models/Activity.model';
import ActivityImage from '../ActivityImage/ActivityImage';
import ActivityInfoHighlight from '../ActivityInfoHighlight/ActivityInfoHighlight';
import { RouteComponentProps, withRouter } from 'react-router';

type HighlightCardProps = {
    activity: Activity | null
}

const HighlightCard = ({activity}:  RouteComponentProps<{}> & HighlightCardProps) => {

    let link = activity ? `/activity/${activity.id}/${activity.displayDate ? activity.displayDate.format('YYYY-MM-DD') : ''}` : "#"
    return (
        <a className="card-link" href={link}>
            <div className="Highlight-card">
                <div className="Highlight-card__image">
                    <ActivityImage activity={activity}
                                   imageSizeSm={{height: 104, width: 320}}
                                   imageSizeMd={{height: 244, width: 200}}/>
                </div>
                <ActivityInfoHighlight activity={activity} />
            </div>
        </a>
    );
};

export default withRouter(HighlightCard);
