import React from 'react';
import './ActivityDetailSubtitle.scss';
import { getStyleForCategory } from '../../utils/CategoryUtils';

type ActivityDetailSubtitleProps = {
    text: string,
    category: string
}

const ActivityDetailSubtitle = ({ text, category }: ActivityDetailSubtitleProps) => {
  return (
      <div className={"ActivityDetailSubtitle " + getStyleForCategory(category)}>{text}</div>
  );
};

export default ActivityDetailSubtitle;
