import React from 'react';
import './SearchFieldText.scss';

type SearchFieldProps = {
    setSearchText: (text: string) => void,
    searchText: string
};

const SearchFieldText = ({setSearchText, searchText} : SearchFieldProps) => {

    const [value, setValue] = React.useState(searchText);

    const reset = () => {
        setValue('');
        setSearchText('');
    };

    const handleChange = (event: any) => {
        setValue(event.target.value);
        setSearchText(event.target.value);
    };

    return (
        <div className="SearchFieldText">
            <div className="SearchFieldText__label">Was</div>
            <input placeholder="Spielplatz, Kurs, Theater..." onChange={handleChange} value={value}/>
            { value &&
                <div className="SearchFieldText__reset-btn" onClick={() => reset()}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM8.35594 7.01311L12 10.6572L15.6441 7.01311L17 8.36904L13.3559 12.0131L17 15.6572L15.6441 17.0131L12 13.369L8.35594 17.0131L7 15.6572L10.6441 12.0131L7 8.36904L8.35594 7.01311Z" fill="#B0B0B0"/>
                  </svg>
                </div>
            }
        </div>
  );
};

export default SearchFieldText;
