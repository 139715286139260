import React, { useEffect } from 'react';
import './DateFilter.scss';
import { Moment } from 'moment';
import moment  from "moment";
import 'moment/locale/de-ch';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

type DateFilterProps = {
    date: Moment | undefined,
    weekdays: number[] | undefined,
    setDate: (date: Moment | undefined) => void,
    setWeekdays: (weekdays: number[] | undefined) => void,
}

const DateFilter = ({ date, weekdays, setDate, setWeekdays }: DateFilterProps) => {
  moment.locale('de-CH');

  type DateEntry = Moment | null;

  const { t } = useTranslation();
  const [displayMonth, setDisplayMonth] = React.useState<Moment>(date ? moment(date).date(1) : moment().date(1));
  const [dates, setDates] = React.useState<DateEntry[]>([]);

    useEffect(() => {
        initMonth(displayMonth);
    }, [displayMonth]);

  const initMonth = (date: Moment) => {
      const startDate = moment(date).utc().date(1).hours(0).minutes(0).seconds(0).milliseconds(0);
      const previousMonth = _.times(startDate.isoWeekday()-1, _.constant(null));
      const currentMonth = _.times(startDate.daysInMonth(), (i) => moment(startDate).add(i, 'day'));
      setDates(_.concat(previousMonth, currentMonth));
  };

  const selectWeekday = (weekday: number) => {
      if(_.includes(weekdays, weekday)) {
          const newWeekdays = _.filter(weekdays, (day) => day !== weekday);
          setWeekdays(newWeekdays);
      } else {
          setWeekdays(weekdays ? [...weekdays, weekday] : [weekday]);
          setDate(undefined);
      }
  };

  const getLocalMoment = (date?: Moment) => {
      const localDate = date ? moment(date) : moment();
      localDate.utc().set({hour:0,minute:0,second:0,millisecond:0});
      return localDate;
  };

  const selectDate = (date: DateEntry) => {
      if (date) {
          setDate(getLocalMoment(moment(date)));
          setWeekdays(undefined);
      }
  };

  const getWeekdayStyle = (weekday: number) => {
      if (_.includes(weekdays, weekday)) {
          return "DateFilter__table__button-weekday DateFilter__table__button-weekday--selected";
      } else {
          return "DateFilter__table__button-weekday";
      }
  };

  const nextMonth = () => {
      setDisplayMonth(moment(displayMonth).add(1, 'month'));
  };

    const previousMonth = () => {
        setDisplayMonth(moment(displayMonth).add(-1, 'month'));
    };

    const getDayStyle = (dateEntry: DateEntry) => {
        if (dateEntry) {
            if ((date && moment(date).isSame(dateEntry,'day')) || _.includes(weekdays, dateEntry.isoWeekday()-1)) {
                return "DateFilter__table__button DateFilter__table__button--selected";
            } else if (moment(dateEntry).isSame(moment(),'day')) {
                return "DateFilter__table__button DateFilter__table__button--today";
            } else if (moment(dateEntry).isBefore(moment())) {
                return "DateFilter__table__button DateFilter__table__button--past";
            } else {
                return "DateFilter__table__button";
            }
        } else {
            return "DateFilter__table__button__empty";
        }
    };

  return (
      <div className="DateFilter">
          <div className="DateFilter__header">
              <div className="DateFilter__header__nav">
                  { (displayMonth.year() > moment().year() || displayMonth.month() > moment().month()) &&
                  <div className="icon-button" onClick={() => previousMonth()}>
                    <svg width="24" height="24" viewBox="0 0 24 24">
                      <path d="M7.11963 11.997L18.2247 1.494C18.3951 1.335 18.4937 1.122 18.4997 0.888C18.5057 0.657 18.416 0.432 18.2606 0.27C18.1021 0.102 17.8809 0.006 17.6508 0H17.6358C17.4086 0 17.1964 0.084 17.041 0.237L5.26928 11.373C5.10188 11.535 5.00323 11.757 5.00024 11.985C4.99426 12.231 5.09889 12.468 5.26928 12.624L17.044 23.766C17.2113 23.919 17.4236 24 17.6388 24C17.7165 24 17.7943 23.988 17.869 23.961C18.1709 23.883 18.4011 23.64 18.4758 23.337C18.5446 23.04 18.4519 22.722 18.2307 22.509L7.11963 11.997Z" fill="#494949"/>
                    </svg>
                  </div>
                  }
              </div>
              <div className="DateFilter__header__title">
                  { displayMonth.format('MMMM') + " " + displayMonth.format('YYYY')  }
              </div>
              <div className="DateFilter__header__nav">
                  <div className="icon-button" onClick={() => nextMonth()}>
                      <svg width="24" height="24" viewBox="0 0 24 24">
                          <path d="M16.3804 12.003L5.27529 22.506C5.1049 22.665 5.00626 22.878 5.00028 23.112C4.9943 23.343 5.08398 23.568 5.23942 23.73C5.39785 23.898 5.61906 23.994 5.84923 24L5.86418 24C6.09136 24 6.3036 23.916 6.45904 23.763L18.2307 12.627C18.3981 12.465 18.4968 12.243 18.4998 12.015C18.5057 11.769 18.4011 11.532 18.2307 11.376L6.45605 0.233999C6.28865 0.0809991 6.07641 -1.0861e-06 5.86119 -1.10492e-06C5.78347 -1.11171e-06 5.70575 0.0119989 5.63102 0.0389989C5.3291 0.116999 5.09893 0.359999 5.0242 0.662999C4.95544 0.959999 5.04811 1.278 5.26932 1.491L16.3804 12.003Z" fill="#494949"/>
                      </svg>
                  </div>
              </div>
          </div>
          <div className="DateFilter__table">
              {_.times(7, (i) =>
                  <div key={i} className={getWeekdayStyle(i)} onClick={() => selectWeekday(i)}>{t(`WEEKDAY_${i}_SHORT`)}</div>
              )}
              {_.map(dates, (date, index) =>
                  <div key={`date_${index}`} className={getDayStyle(date)} onClick={() => selectDate(date)}>{date ? date.date() : ""}</div>
              )}
          </div>
      </div>
  );
};

export default DateFilter;
