import React from 'react';
import './SearchBarHeader.scss';
import fly from '../../assets/images/Header_hulafliege.png';
import bird from '../../assets/images/Header_Baer.png';
import Query from '../../models/Query.model';
import SearchBar from '../SearchBar/SearchBar';

type SearchBarProps = {
    query: Query,
    defaultQuery: Query,
    resultCount: number,
    applyFilter: (query: Query) => void,
    getResultCount: (query: Query) => Promise<number>,
    setIsSearching: (value: boolean) => void,
    toggleView: () => void,
    showUserMessage: (message: string) => void,
    isVisible: boolean,
    resetLocation: () => void
}

const SearchBarHeader = (props: SearchBarProps) => {

    return (
      <div className="SearchBarHeader">
          <div className="SearchBarHeader__bird d-none d-md-flex">
              <img src={bird} alt="bird"/>
          </div>
          <div className="SearchBarHeader__fly d-none d-md-flex">
              <img src={fly} alt="fly"/>
          </div>
          <div className="SearchBarHeader__container">
              <SearchBar {...props} />
          </div>
      </div>
  );
};

export default SearchBarHeader;
