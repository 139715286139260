import React from 'react';
import './ActivityInfoHighlight.scss';
import Activity from '../../models/Activity.model';
import DateTime from '../DateTime/DateTime';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BookmarkIcon from '../BookmarkIcon/BookmarkIcon';
import { getAgesText } from '../../utils/AttributeUtils';
import Distance from '../Distance/Distance';
import Category from '../Category/Category';
import ReactPlaceholder from 'react-placeholder';

type ActivityInfoHighlightProps = {
    activity: Activity | null
};

const ActivityInfoHighlight = ({ activity, history }:
                          RouteComponentProps<{}> & ActivityInfoHighlightProps) => {
  return (
      <div className="ActivityInfoHighlight" onClick={() => {
          if (activity) {
              history.push(`/activity/${activity.id}/${activity.displayDate ? activity.displayDate.format('YYYY-MM-DD') : ''}`);
          }
      }}>
          <div className="ActivityInfoHighlight__datetime">
              <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!activity} color="#E0E0E0" style={{marginTop: 0, maxWidth: 100}}>
                  {activity && <DateTime dates={activity.dates} displayDate={activity.displayDate} />}
              </ReactPlaceholder>
          </div>
          <div className="ActivityInfoHighlight__title">
              <ReactPlaceholder showLoadingAnimation type='text' rows={2} ready={!!activity} color="#E0E0E0" style={{width: '100%'}}>
                {activity && activity.title}
              </ReactPlaceholder>
          </div>
          <div className="ActivityInfoHighlight__category">
              <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!activity} color="#E0E0E0" style={{marginTop: 0}}>
                {activity && <Category activity={activity}/>}
              </ReactPlaceholder>
          </div>
          <div className="ActivityInfoHighlight__ages">
              <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!activity} color="#E0E0E0" style={{marginTop: 0, maxWidth: 50}}>
                  {activity && getAgesText(activity.ages)}
              </ReactPlaceholder>
          </div>
          <div className="ActivityInfoHighlight__distance">
              <ReactPlaceholder showLoadingAnimation type='textRow' ready={!!activity} color="#E0E0E0" style={{marginTop: 0, maxWidth: 80}}>
                {activity && <Distance activity={activity}/>}
              </ReactPlaceholder>
          </div>
          <div className="ActivityInfoHighlight__actions">
              {activity && <BookmarkIcon activity={activity} />}
          </div>
      </div>
  );
};

export default withRouter(ActivityInfoHighlight);
