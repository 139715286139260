import React from 'react';
import LegalContent from '../components/LegalContent/LegalContent';
import Layout from '../components/Layout/Layout';

const Impressum = () => {

    return (
        <Layout pageHeaderOnMobile={true} pageHeaderTitle="Impressum">
            <div className="fullwidth grey">
                <div className="centered">
                    <LegalContent title="Impressum" children={
                        <React.Fragment>
                            <h1>Kontakt</h1>
                            <p>Verein wow-wow<br/>
                                Spiegelgasse 5<br/>
                                8001 Zürich<br/>
                                <a href={"mailto:info@wow-wow.ch"}>info@wow-wow.ch</a><br/>
                            </p>
                            <p>
                                Handelsregister des Kantons Zürich: CHE-486.113.893
                            </p>
                        </React.Fragment>
                    }></LegalContent>
                </div>
            </div>
        </Layout>
    );
};

export default Impressum;
