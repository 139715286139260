import React from 'react';
import './SearchFieldFilter.scss';
import Query from '../../models/Query.model';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import AttributeFilter from '../AttributeFilter/AttributeFilter';
import {
    BUGGYFRIENDLY_OPTIONS,
    CATEGORY_OPTIONS,
    COSTFREE_OPTIONS,
    SURROUNDING_OPTIONS
} from '../../utils/AttributeUtils';
import PopupFilterFooter from '../PopupFilterFooter/PopupFilterFooter';
import Chip from '../Chip/Chip';
import AgeFilter from '../AgeFilter/AgeFilter';
import _ from 'lodash';

type SearchFieldProps = {
    query: Query,
    defaultQuery: Query,
    setQuery: (query: Query) => void,
    isSearchbarVisible: boolean
};

const SearchFieldFilter = ({query, defaultQuery, setQuery, isSearchbarVisible} : SearchFieldProps) => {
    const overlayRef: any = React.createRef();

    React.useEffect(() => {
        if(!isSearchbarVisible) {
            if (overlayRef && overlayRef.current) {
                overlayRef.current.handleHide();
            }
        }
    }, [isSearchbarVisible]);


    const reset = () => {
        setQuery(defaultQuery);
    };

    const closeFilter = () => {
        if (overlayRef && overlayRef.current) {
            overlayRef.current.handleHide();
        }
    };

    const isFilterActive = () => !!query.buggyFriendly || !!query.surrounding ||!!query.category ||
        !!query.costFree ||  (_.size(query.ages) != 0 && _.size(query.ages) != 12);

    const isDefaultQuery = query.category === defaultQuery.category && query.surrounding === defaultQuery.surrounding
        && query.buggyFriendly === defaultQuery.buggyFriendly && query.costFree === defaultQuery.costFree
        && query.parents === defaultQuery.parents && (_.size(query.ages) === 0 || _.size(query.ages) === 12);

    return (
        <OverlayTrigger
            ref={overlayRef}
            trigger="click"
            placement='bottom'
            overlay={<Popover id={`popover-positioned-bottom`}>
                <Popover.Content>
                    <div className="ModalAttributeFilter__content">
                        <AgeFilter ages={query.ages} setAges={(ages: number[] | undefined) => setQuery({...query, ages})}/>
                        <AttributeFilter key="surrounding" title="Umgebung" options={SURROUNDING_OPTIONS} value={query.surrounding} onSelect={(value) => {
                            setQuery({...query, surrounding: query.surrounding === value ? undefined : value as string})
                        }
                        }
                        />
                        <AttributeFilter key="costFree" title="Kosten" options={COSTFREE_OPTIONS} value={query.costFree} onSelect={(value) => {
                            setQuery({...query, costFree: query.costFree === value ? undefined : value as boolean})
                        }
                        }
                        />
                        <AttributeFilter key="buggyFriendly" title="Buggytauglich" options={BUGGYFRIENDLY_OPTIONS} value={query.buggyFriendly} onSelect={(value) => {
                            setQuery({...query, buggyFriendly: query.buggyFriendly === value ? undefined : value as boolean})
                        }
                        }
                        />
                        <AttributeFilter key="category" title="Kategorien" options={CATEGORY_OPTIONS} value={query.category} onSelect={(value) => {
                            setQuery({...query, category: query.category === value ? undefined : value as string})
                        }
                        }
                        />
                    </div>
                    <PopupFilterFooter isDefaultValue={isDefaultQuery} onReset={reset}/>
                </Popover.Content>
            </Popover>}
            rootClose={true}
            defaultShow={false}
        >
            <div className="SearchFieldFilter">
                <Chip title="FILTER" active={isFilterActive()}/>
            </div>
        </OverlayTrigger>
  );
};

export default SearchFieldFilter;
