import React from 'react';
import './UnderlineButton.scss';

type UnderlineButtonProps = {
    onClick: () => void,
    label: string
};

const UnderlineButton = ({ onClick, label }: UnderlineButtonProps) => {

    return (
        <div className="UnderlineButton" onClick={() => onClick()}>{label}</div>
    )
};

export default UnderlineButton;
