import React from 'react';
import Activity from '../../models/Activity.model';
import { getStyleForCategory } from '../../utils/CategoryUtils';

type CategoryProps = {
    activity: Activity,
}

const Category = ({ activity }: CategoryProps) => {
  return (
      <React.Fragment>
          {activity.category &&
            <span className={getStyleForCategory(activity.category)}>{activity.category}</span>
          }
      </React.Fragment>
  );
};

export default Category;
