import React from 'react';
import './ActivityTeaser.scss';
import Activity from '../../models/Activity.model';
import ActivityTeaserCard from '../ActivityTeaserCard/ActivityTeaserCard';
import ActivityTeaserCardMini from '../ActivityTeaserCardMini/ActivityTeaserCardMini';
import UnderlineButton from '../UnderlineButton/UnderlineButton';
import Query from '../../models/Query.model';
import ReactPlaceholder from 'react-placeholder';

type ActivityTeaserProps = {
    activities: Activity[] | null,
    category: string,
    query: Query,
    applyFilter: (query: Query) => void,
    teaserLoaded: boolean
};

const ActivityTeaser = ({ activities, teaserLoaded, category, query, applyFilter }: ActivityTeaserProps) => {
  return (
      <React.Fragment>
          { ((activities && activities.length > 3) || !teaserLoaded) &&
              <div className="ActivityTeaser">
                <div className="ActivityTeaser__title">
                  <ReactPlaceholder showLoadingAnimation type='textRow' ready={teaserLoaded} color="#E0E0E0" style={{marginTop: 0, maxWidth: 100}}>
                      {category}
                  </ReactPlaceholder>
                </div>
                <div className="ActivityTeaser__content">
                  <div className="ActivityTeaser__content__card">
                    <ActivityTeaserCard activity={activities ? activities[0] : null}/>
                  </div>
                  <div className="ActivityTeaser__content__minis">
                    <ActivityTeaserCardMini activity={activities ? activities[1] : null}/>
                    <ActivityTeaserCardMini activity={activities ? activities[2] : null}/>
                    <ActivityTeaserCardMini activity={activities ? activities[3] : null}/>
                    <div className="ActivityTeaser__content__minis__more">
                        {teaserLoaded && <UnderlineButton onClick={() => applyFilter({...query, category })} label="Alle Ereignisse ansehen"/>}
                    </div>
                  </div>
                </div>
              </div>
          }
      </React.Fragment>
  );
};

export default ActivityTeaser;
