import React from 'react';
import './ActivityInfo.scss';
import Activity from '../../models/Activity.model';
import DateTime from '../DateTime/DateTime';
import BookmarkIcon from '../BookmarkIcon/BookmarkIcon';
import { getAgesText } from '../../utils/AttributeUtils';
import Distance from '../Distance/Distance';
import Category from '../Category/Category';
import AddToCalendarIcon from '../AddToCalendarIcon/AddToCalendarIcon';

type ActivityInfoProps = {
    activity: Activity,
    horizontal?: boolean
};

const ActivityInfo = ({ activity, horizontal=false }: ActivityInfoProps) => {
  return (
      <div className={horizontal ? "ActivityInfo--horizontal" : "ActivityInfo"}>
          <div className="ActivityInfo__datetime">
              <DateTime dates={activity.dates} displayDate={activity.displayDate} />
          </div>
          <div className="ActivityInfo__title">{activity.title}</div>
          <div className="ActivityInfo__category">
              <Category activity={activity}/>
          </div>
          <div className="ActivityInfo__ages">{getAgesText(activity.ages)}</div>
          <div className="ActivityInfo__distance">
              <Distance activity={activity}/>
          </div>
          <div className="ActivityInfo__actions d-md-none">
              <AddToCalendarIcon activity={activity} />
              <BookmarkIcon activity={activity} />
          </div>
      </div>
  );
};

export default ActivityInfo;
