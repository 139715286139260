import React from 'react';
import './DateTime.scss';
import moment, { Moment } from "moment";
import { Dates } from '../../models/Dates.model';
import { getDateData } from '../../utils/DateUtils';

type DateTimeProps = {
    dates: Dates,
    displayDate: Moment | null
}

const DateTime = ({ displayDate, dates }: DateTimeProps) => {
  moment.locale('de-CH');

  return (
      <React.Fragment>
          {displayDate &&
              <div className="DateTime">
                  {`${moment(displayDate).format('DD.MM.YY')} | ${getDateData(dates, displayDate).displayTime}`}
              </div>
          }
          {!displayDate &&
            <div className="DateTime">--</div>
          }
      </React.Fragment>
  );
};

export default DateTime;
