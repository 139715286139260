import React from 'react';
import './ActivityMap.scss';
import Activity from '../../models/Activity.model';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { PIN_IMAGES, toGooglePoint } from '../../utils/LocationUtils';
import { getPinForCategory } from '../../utils/CategoryUtils';
import config from '../../config';
import Spinner from '../Spinner/Spinner';

type ActivityMapProps = {
    activity: Activity
};

const ActivityMap = ({ activity }: ActivityMapProps) => {

    const mapStyles = require("../../mapStyles.json");
    const [pins] = React.useState<any>(PIN_IMAGES);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: config.googleMapsApiKey,
    });

    const options = {
        styles: mapStyles,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        minZoom: 10,
        maxZoom: 16
    };

    const renderMap = () => {
        return (
            <div className="ActivityMap">
                <div className="ActivityMap__map">
                    <GoogleMap
                        center={toGooglePoint(activity.location)}
                        mapContainerStyle={{
                            height: '100%',
                            width: '100%',
                            boxSizing: 'border-box'
                        }}
                        options={options}
                        extraMapTypes={[]}
                        zoom={16}
                    >
                        <Marker key={activity.id}
                                position={toGooglePoint(activity.location)}
                                icon={pins[`${getPinForCategory(activity.category)}-default.svg`]}
                        />
                    </GoogleMap>
                </div>
            </div>
        )
    };

    if (loadError) {
        return <div>Die Karte kann zur Zeit nicht angezeigt werden.</div>
    }

    return isLoaded ? renderMap() : <Spinner />
};

export default ActivityMap;
