import React, { useEffect } from 'react';
import './LandingContent.scss';
import Activity from '../../models/Activity.model';
import FormResult from '../../models/FormResult.model';
import { Blog, BlogMap } from '../../models/Blog.model';
import Highlights from '../Highlights/Highlights';
import BlogThumbnail from '../BlogThumbnail/BlogThumbnail';
import Newsletter from '../Newsletter/Newsletter';
import cat from '../../assets/images/WowWows_Vectors_Katze.svg';
import _ from 'lodash';
import ActivityTeaser from '../ActivityTeaser/ActivityTeaser';
import Query from '../../models/Query.model';

type LandingContentProps = {
    highlights: Activity[],
    highlightsLoaded: boolean,
    teaserLoaded: boolean,
    blogsLoaded: boolean,
    teaserActivities: { [key: string]: Activity[] },
    blogs: BlogMap,
    subscribeToNewsletter: (email: string) => Promise<FormResult>,
    query: Query,
    applyFilter: (query: Query) => void
}


const LandingContent = ({highlights, highlightsLoaded, teaserActivities, teaserLoaded, blogs, blogsLoaded, subscribeToNewsletter, query, applyFilter}: LandingContentProps) => {

    return <div className="LandingContent">
        <div className="LandingContent__highlights">
            <Highlights activities={highlights} />
        </div>
        <div className="LandingContent__content">
            <div className="LandingContent__content__teasers">
                { _.map(teaserLoaded ? teaserActivities : {1: [], 2: [], 3: []}, (activities, key) => (
                    <ActivityTeaser key={key} activities={activities} category={key} query={query} applyFilter={applyFilter} teaserLoaded={teaserLoaded}/>
                ))}
            </div>
            <div className="LandingContent__content__other">
                { _.map(blogsLoaded ? _.values(blogs) : _.times(3, _.constant(null)), (blog, index) => (
                    <div key={index} className="LandingContent__content__other__blog">
                        <BlogThumbnail blog={blog}/>
                    </div>
                ))
                }
                <div className="LandingContent__content__other__newsletter">
                    <Newsletter subscribeToNewsletter={subscribeToNewsletter}/>
                </div>
                <div className="LandingContent__content__other__cat">
                    <img src={cat} alt=""/>
                </div>
            </div>
        </div>
    </div>
};

export default LandingContent;
