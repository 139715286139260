import React from 'react';
import FullwidthButton from '../ButtonFullwidth/FullwidthButton';

type ModalFilterFooterProps = {
    onSelect: () => void
}

const ModalFilterFooter = ({ onSelect }: ModalFilterFooterProps) => {
  return (
      <FullwidthButton text="Und Los!"
                       onSelect={onSelect} />
  );
};

export default ModalFilterFooter;
