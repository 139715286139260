import React from 'react';
import './Distance.scss';
import Activity from '../../models/Activity.model';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

type DistanceProps = {
    activity: Activity,
}

const Distance = ({ activity }: DistanceProps) => {
  const { t } = useTranslation();

  return (
      <React.Fragment>
          {activity.locationType !== "ONLINE" && activity.distance && !isNaN(activity.distance) && activity.distance < 100 &&
            <span className="Distance">{activity.distance.toLocaleString(undefined, {maximumFractionDigits:1})} km entfernt</span>
          }
          {activity.locationType === "ONLINE" && activity.urls && activity.urls.length > 0 &&
            <span  className="Distance">{_.join(_.map(_.uniq(_.map(activity.urls, url => url.type)), type => t(`URL_TYPE.${type}`)), ', ')}</span>
          }
      </React.Fragment>
  );
};

export default Distance;
