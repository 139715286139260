import React from 'react';
import NotFoundContent from '../components/NotFoundContent/NotFoundContent';
import Layout from '../components/Layout/Layout';

const NotFound404 = () => {

    return (
      <Layout>
          <div className="fullwidth grey">
              <div className="centered">
              <NotFoundContent />
              </div>
          </div>
      </Layout>
  );
};

export default NotFound404;
