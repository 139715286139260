import React from 'react';
import './ModalDateFilter.scss';
import Modal from "react-bootstrap/Modal";
import ModalHeader from '../ModalHeader/ModalHeader';
import { Moment } from 'moment';
import moment  from "moment";
import 'moment/locale/de-ch';
import Query from '../../models/Query.model';
import DateFilter from '../DateFilter/DateFilter';
import { DEFAULT_QUERY } from '../../utils/SearchUtils';

type ModalDateFilterProps = {
    show: boolean,
    query: Query,
    setDate: (date: Moment | undefined) => void,
    setWeekdays: (weekdays: number[] | undefined) => void,
    close: () => void,
    reset: () => void
}

const ModalDateFilter = ({ show, query, setDate, setWeekdays, reset, close }: ModalDateFilterProps) => {
  moment.locale('de-CH');

  const isDefaultQuery = query.date === DEFAULT_QUERY.date && query.weekdays === DEFAULT_QUERY.weekdays;

  return (
      <Modal className="modal" show={show} onHide={close}>
          <ModalHeader title="Datum" onClose={close} onReset={reset} isDefaultValue={isDefaultQuery} />
          <div className="DateFilterModal__content">
             <DateFilter date={query.date} weekdays={query.weekdays} setDate={setDate} setWeekdays={setWeekdays}/>
          </div>
      </Modal>
  );
};

export default ModalDateFilter;
