import React from 'react';
import './PopupFilterFooter.scss';

type PopupFilterFooterProps = {
    onReset: () => void,
    isDefaultValue: boolean,
}

const PopupFilterFooter = ({onReset, isDefaultValue}: PopupFilterFooterProps) => {
    return (
        <div className="PopupFilterFooter">
            <div className="PopupFilterFooter__reset">
                <button type="button" className="btn btn-link" onClick={onReset} disabled={isDefaultValue}>Löschen</button>
            </div>
        </div>
    );
};

export default PopupFilterFooter;
