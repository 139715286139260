import React from 'react';
import _ from 'lodash';
import Bookmark from './models/Bookmark.model';
import * as bookmarkService from './services/BookmarksService';
import * as geolocationService from './services/GeolocationService';
import Activity from './models/Activity.model';
import {
    getActivityCount,
    loadActivities, loadActivitiesByCategory,
    loadActivityById,
    loadBookmarkedActivites,
    loadHighlights
} from './services/ActivityService';
import {
    loadBlogs
} from './services/BlogService';
import Query from './models/Query.model';
import { DEFAULT_PAGE_SIZE, DEFAULT_QUERY, DEFAULT_SEARCH } from './utils/SearchUtils';
import GeoLocation from './models/GeoLocation.model';
import BoundingBox from './models/BoundingBox.model';
import { Moment } from 'moment';
import FormResult from './models/FormResult.model';
import * as feedbackService from './services/FeedbackService';
import { DEFAULT_MAP_ZOOM } from './utils/LocationUtils';
import moment from 'moment';
import WowWowContext from './models/WowWowContext.model';
import { BlogMap } from './models/Blog.model';
import * as newsletterService from './services/NewsletterService';


type Props = {
    children: React.ReactNode;
};

const DEFAULT_STATE: any = {
    highlights: [] as Activity[],
    activities: [] as Activity[],
    teaserActivities: {} as {[key: string]: Activity[]},
    blogs: {},
    blogsLoaded: false,
    selectedActivity: null,
    resultCount: 0,
    bookmarks: [],
    bookmarkedActivities: [] as Activity[],
    searchData: DEFAULT_SEARCH,
    isSearching: false,
    activityLoaded: false,
    activitiesLoaded: false,
    isLoadingMore: false,
    teaserLoaded: false,
    highlightsLoaded: false,
    mapZoom: DEFAULT_MAP_ZOOM,
    viewState: "LIST",
    showLandingPage: true,
    userMessage: '',
};

export const AppContext = React.createContext<WowWowContext | undefined>(undefined);

export default class Provider extends React.Component<
    Props,
    WowWowContext >
{
    constructor(props: Props) {
        super(props);
        const searchData = DEFAULT_SEARCH;
        const localSearchData = localStorage.getItem('searchData');
        if (localSearchData) {
            const { query: { location, currentCity }} = JSON.parse(localSearchData);
            if (location && currentCity) {
                searchData.query = { ...searchData.query, location, currentCity};
            }
        }
        this.state = {
            ...DEFAULT_STATE,
            mapZoom: DEFAULT_MAP_ZOOM,
            viewState: "LIST",
            searchData,
            addBookmark: this.addBookmark,
            removeBookmark: this.removeBookmark,
            isBookmarked: this.isBookmarked,
            applyFilter: this.applyFilter,
            applyBoundingBox: this.applyBoundingBox,
            updateMapData: this.updateMapData,
            getResultCount: this.getResultCount,
            showMore: this.showMore,
            setIsSearching: this.setIsSearching,
            toggleView: this.toggleView,
            setViewState: this.setViewState,
            getActivity: this.getActivity,
            setSelectedActivity: this.setSelectedActivity,
            setShowLandingPage: this.setShowLandingPage,
            mailFeedback: this.mailFeedback,
            mailChangeRequest: this.mailChangeRequest,
            searchStarted: this.searchStarted,
            resetQuery: this.resetQuery,
            resetLocation: this.resetLocation,
            showUserMessage: this.showUserMessage,
            subscribeToNewsletter: this.subscribeToNewsletter
        };
    }

    componentDidMount() {
        const bookmarks = bookmarkService.getBookmarks();
        loadBookmarkedActivites(bookmarks).then((data:any) => {
            this.setState({bookmarkedActivities: data, bookmarks: bookmarks});
        });
        this.loadData();
        this.loadHighlightData();
        this.loadBlogData();
        this.loadTeaserData();
    }

    searchStarted = (): boolean => {
        return this.queryChanged(this.state.searchData.query);
    };

    resetQuery = (): void => {
        const { searchData } = this.state;
        searchData.query = DEFAULT_QUERY;
        this.setState({searchData}, () => localStorage.removeItem('searchData'));
    };

    resetLocation = (): void => {
        console.log('RESET LOCATION');
        const { searchData } = this.state;
        searchData.query.location = undefined;
        searchData.query.currentCity = undefined;
        this.setState({searchData}, () => localStorage.setItem('searchData', JSON.stringify(searchData)));
    };

    showUserMessage = (message: string) => {
        this.setState({
            userMessage: message,
        });
    };

    queryChanged = (query: Query): boolean => {
        const previousQuery = DEFAULT_SEARCH.query;
        return query.ages !== previousQuery.ages ||
            query.date !== previousQuery.date ||
            query.parents !== previousQuery.parents ||
            query.costFree !== previousQuery.costFree ||
            query.buggyFriendly !== previousQuery.buggyFriendly ||
            query.surrounding !== previousQuery.surrounding ||
            query.category !== previousQuery.category ||
            query.text !== previousQuery.text ||
            query.weekdays !== previousQuery.weekdays ||
            query.currentCity !== undefined;
    };

    loadData = () => {
        console.log('*** LOAD DATA');
        this.setState({activitiesLoaded: false});
        loadActivities(this.state.searchData).then((data:any) => {
            this.setState({
                activities: data ? _.concat(this.state.activities, data.hits) : [],
                resultCount: data && data.total ? data.total.value : 0,
                isSearching: false,
                activitiesLoaded: true,
                isLoadingMore: false
            });
        });
    };

    loadTeaserData = () => {
        if (this.state.showLandingPage) {
            console.log('*** LOAD TEASER DATA');
            loadActivitiesByCategory().then((data: any) => {
                this.setState({
                    teaserActivities: data,
                    teaserLoaded: _.size(data) > 3
                })
            });
        }
    };

    getActivity = (id: string, date?: Moment): Promise<Activity | null> => {
        return loadActivityById(id, this.state.searchData.query.location, date).then((activity) => {
            this.setSelectedActivity(activity);
            return activity;
        });
    };

    setSelectedActivity = (activity: Activity | null) => this.setState({
        selectedActivity: activity
    });

    loadHighlightData = () => {
        console.log('*** LOAD HIGHLIGHT DATA');
        this.setState({highlightsLoaded: false});
        loadHighlights(this.state.searchData.query.location).then((data: Activity[]) => {
            this.setState({
                highlights: _.shuffle(data),
                highlightsLoaded: true
            });
        });
    };

    loadBlogData = () => {
        this.setState({blogsLoaded: false});
        loadBlogs().then((data: BlogMap) => {
            this.setState({
                blogs: data,
                blogsLoaded: true
            });
        });
    };

    applyFilter = (query: Query) => {
        console.log('APPLY FILTER', query);
        const { showLandingPage, searchData } = this.state;
        this.setState({
            showLandingPage: showLandingPage && !this.queryChanged(query),
            searchData: { ...searchData, query }
            }, () => this.filterActivity());
    };

    applyBoundingBox = (geoLocation: GeoLocation, boundingBox: BoundingBox | undefined) => {
        console.log('APPLY BOUNDING BOX', geoLocation);
        if (this.state.viewState === "MAP" || !this.state.showLandingPage) {
            const {searchData} = this.state;
            searchData.query.locationType = 'MAP';
            searchData.query.location = geoLocation;
            searchData.query.boundingBox = boundingBox;
            geolocationService.getCity(geoLocation).then((data:string) => {
                searchData.query.currentCity = data;
                this.setState({searchData}, () => this.filterActivity());
            });
        }
    };

    filterActivity = () => {
        localStorage.setItem('searchData', JSON.stringify(this.state.searchData));
        this.setState({
            resultCount: 0,
            searchData: {...this.state.searchData, from: 0},
            activities: []
        }, () => {
            this.loadData();
            this.loadHighlightData();
            if (this.state.showLandingPage) {
                this.loadTeaserData();
            }
        });
    };

    updateMapData = (zoom: number, geoLocation: GeoLocation) => {
        const {searchData} = this.state;
        searchData.query.location = geoLocation;
        searchData.query.mapZoom = zoom;
        this.setState({searchData});
    };

    setShowLandingPage = () => {
        this.setState({showLandingPage: true, searchData: DEFAULT_SEARCH });
    };

    getResultCount = (query: Query):Promise<number> => {
        return getActivityCount(query);
    };

    showMore = () => {
        this.setState({isLoadingMore: true, searchData: {...this.state.searchData,
                from: this.state.searchData.from + DEFAULT_PAGE_SIZE}, selectedActivity: null}, () => {
            this.loadData();
        });
    };

    setIsSearching = (status: boolean) => {
        this.setState({isSearching: status});
    };

    addBookmark = (activity: Activity) => {
        const bookmark = {
            activityId: activity.id,
            date: activity.displayDate ? activity.displayDate : null
        };
        this.setState({bookmarks: [...this.state.bookmarks, bookmark], bookmarkedActivities: [...this.state.bookmarkedActivities, activity]},
            () => bookmarkService.persistBookmarks(this.state.bookmarks));
    };

    removeBookmark = (activity: Activity) => {
        const newBookmarks = _.filter(this.state.bookmarks,
            (b: Bookmark) => (b.activityId !== activity.id && b.date && activity.displayDate && !moment(b.date).isSame(activity.displayDate, 'day')));
        const newBookmarkedActivties = _.filter(this.state.bookmarkedActivities,
            (a: Activity) => (a.id !== activity.id && a.displayDate !== activity.displayDate));
        this.setState({bookmarks: newBookmarks, bookmarkedActivities: newBookmarkedActivties},
            () => bookmarkService.persistBookmarks(this.state.bookmarks));
    };

    isBookmarked = (activity: Activity) => !!_.find(this.state.bookmarks, (b: Bookmark) => b.activityId === activity.id && (!activity.displayDate || (b.date && moment(b.date).isSame(activity.displayDate, 'day'))));

    toggleView = () => this.setState({viewState: this.state.viewState === "LIST" ? "MAP" : "LIST"},
        () => localStorage.setItem('viewState', this.state.viewState));

    setViewState = (viewState: string) => this.setState({viewState: viewState},
        () => localStorage.setItem('viewState', this.state.viewState));

    mailFeedback = (email: string, subject: string, message: string): Promise<FormResult> => {
        return feedbackService.mail(email, subject, message);
    };

    mailChangeRequest = (email: string, subject: string, message: string): Promise<FormResult> => {
        const { selectedActivity } = this.state;
        if (selectedActivity) {
            const activityInformation = `${selectedActivity.id} ${selectedActivity.title} (${selectedActivity.org_name}) \n ${window.location}`;
            message = `${message} \n\n ${activityInformation}`;
        }
        return feedbackService.mail(email, subject, message);
    };

    subscribeToNewsletter = (email: string): Promise<FormResult> => {
        return newsletterService.subscribe(email);
    };

    render() {
        return (
            <AppContext.Provider
                value={{
                    ...this.state,
                }}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}
