import React from 'react';
import '../ActivitySection/ActivitySection.scss';
import Activity from '../../models/Activity.model';
import _ from 'lodash';
import Openingtime from '../Openingtime/Openingtime';
import { OpeningTime, OpeningTimeData } from '../../models/Dates.model';
import ActivityDetailTitle from '../ActivityDetailTitle/ActivityDetailTitle';

type ActivityOpeningTimesProps = {
    activity: Activity
};

const ActivityOpeningTimes = ({ activity }: ActivityOpeningTimesProps) => {

    const [openingtimeData] = React.useState<OpeningTimeData | null>(activity.dates.type === 'OPENINGTIMES' ? activity.dates.data as OpeningTimeData : null);

    return (
        <React.Fragment>
            { openingtimeData && activity.futureDates.length > 0 &&
            <div className="ActivityOpeningTimes">
              <ActivityDetailTitle text="Öffnungszeiten"/>
              <div className="ActivityOpeningTimes__content">
                  {_.map(openingtimeData.dates, (openingTime: OpeningTime, index) => (
                      <Openingtime index={index} openingTime={openingTime} category={activity.category}/>
                  ))}
              </div>
            </div>
            }
        </React.Fragment>
    )
};

export default ActivityOpeningTimes;
