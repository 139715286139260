import React, { useContext, useEffect } from 'react';
import { AppContext } from '../Provider';
import SearchResults from '../components/SearchResults/SearchResults';
import LandingContent from '../components/LandingContent/LandingContent';
import FeedbackSticky from '../components/FeedbackSticky/FeedbackSticky';
import Layout from '../components/Layout/Layout';
import DonationSticky from '../components/DonationSticky/DonationSticky';

const Home = () => {
    const {searchData,
        resultCount,
        highlights,
        activities,
        teaserActivities,
        blogs,
        selectedActivity,
        highlightsLoaded,
        teaserLoaded,
        blogsLoaded,
        activitiesLoaded,
        isLoadingMore,
        viewState,
        showMore,
        applyFilter,
        applyBoundingBox,
        updateMapData,
        showLandingPage,
        mailFeedback,
        searchStarted,
        subscribeToNewsletter
    } = useContext(AppContext)!;

    const [mQuery, setMQuery] = React.useState<any>({
        matches: window.innerWidth > 768 ? true : false,
    });

    useEffect(() => {
        let mediaQuery = window.matchMedia("(min-width: 768px)");
        mediaQuery.addListener(setMQuery);
        // this is the cleanup function to remove the listener
        return () => mediaQuery.removeListener(setMQuery);
    }, []);

  return (
        <Layout searchBar={true} hideOnScroll={showLandingPage} hideFooter={!showLandingPage || viewState === "MAP"}>

            { mQuery && !mQuery.matches ?
                <React.Fragment>
                    <div className="d-md-none centered">
                        <SearchResults highlights={highlights}
                                       activities={activities}
                                       highlightsLoaded={highlightsLoaded}
                                       selectedActivity={selectedActivity}
                                       blogs={blogs}
                                       resultCount={resultCount}
                                       showMore={showMore}
                                       isLoadingMore={isLoadingMore}
                                       viewState={viewState}
                                       searchData={searchData}
                                       subscribeToNewsletter={subscribeToNewsletter}
                                       mailFeedback={mailFeedback}
                                       updateMapData={updateMapData}
                                       applyBoundingBox={applyBoundingBox}
                                       showHighlights={showLandingPage}
                                       activitiesLoaded={activitiesLoaded}
                        />
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <div className="d-none d-md-flex"><FeedbackSticky /></div>
                    <div className="d-none d-md-flex"><DonationSticky /></div>
                    {showLandingPage &&
                    <div className="d-none d-md-flex fullwidth">
                      <LandingContent highlights={highlights}
                                      highlightsLoaded={highlightsLoaded}
                                      teaserLoaded={teaserLoaded}
                                      blogsLoaded={blogsLoaded}
                                      blogs={blogs}
                                      subscribeToNewsletter={subscribeToNewsletter}
                                      teaserActivities={teaserActivities}
                                      query={searchData.query}
                                      applyFilter={applyFilter}
                      />
                    </div>
                    }
                    {!showLandingPage &&
                    <div className="d-none d-md-flex fullwidth white">
                      <SearchResults highlights={highlights}
                                     activities={activities}
                                     highlightsLoaded={highlightsLoaded}
                                     selectedActivity={selectedActivity}
                                     blogs={blogs}
                                     resultCount={resultCount}
                                     showMore={showMore}
                                     isLoadingMore={isLoadingMore}
                                     viewState={viewState}
                                     searchData={searchData}
                                     subscribeToNewsletter={subscribeToNewsletter}
                                     mailFeedback={mailFeedback}
                                     updateMapData={updateMapData}
                                     applyBoundingBox={applyBoundingBox}
                                     showHighlights={showLandingPage}
                                     activitiesLoaded={activitiesLoaded} />
                    </div>
                    }
                </React.Fragment>
            }
        </Layout>
    );
};

export default Home;
