import React from 'react';
import './ModalAttributeFilter.scss';
import Modal from "react-bootstrap/Modal";
import ModalHeader from '../ModalHeader/ModalHeader';
import ModalFilterFooter from '../ModalFilterFooter/ModalFilterFooter';
import AttributeFilter from '../AttributeFilter/AttributeFilter';
import Query from '../../models/Query.model';
import _ from 'lodash';
import {
    BUGGYFRIENDLY_OPTIONS,
    CATEGORY_OPTIONS,
    COSTFREE_OPTIONS,
    SURROUNDING_OPTIONS
} from '../../utils/AttributeUtils';
import AgeFilter from '../AgeFilter/AgeFilter';

type ModalAttributeFilterProps = {
    show: boolean,
    query: Query,
    defaultQuery: Query,
    resultCount: number,
    getResultCount: (query: Query) => Promise<number>,
    closeAndApplyFilter: (query: Query) => void,
    close: () => void
}

const ModalAttributeFilter = ({show, query, defaultQuery, resultCount, getResultCount, closeAndApplyFilter, close}: ModalAttributeFilterProps) => {

    const [count, setCount] = React.useState<number>(resultCount);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [category, setCategory] = React.useState(query.category);
    const [surrounding, setSurrounding] = React.useState(query.surrounding);
    const [buggyFriendly, setBuggyFriendly] = React.useState(query.buggyFriendly);
    const [costFree, setCostFree] = React.useState(query.costFree);
    const [parents, setParents] = React.useState(query.parents);
    const [ages, setAges] = React.useState<number[] | undefined>([]);

    React.useEffect(() => {
        setLoading(true);
        getResultCount({
            ...query,
            category: category,
            surrounding: surrounding,
            buggyFriendly: buggyFriendly,
            costFree: costFree,
            parents: parents,
            ages: ages
        }).then((resCount) => {
            setCount(resCount);
            setLoading(false);
        });
    }, [category, ages, surrounding, buggyFriendly, costFree, parents, getResultCount, query]);


    const closeFilter = () => closeAndApplyFilter({
        ...query,
        category: category,
        surrounding: surrounding,
        buggyFriendly: buggyFriendly,
        costFree: costFree,
        parents: parents,
        ages: ages
    });

    const reset = () => {
        setCategory(defaultQuery.category);
        setSurrounding(defaultQuery.surrounding);
        setBuggyFriendly(defaultQuery.buggyFriendly);
        setCostFree(defaultQuery.costFree);
        setParents(defaultQuery.parents);
        setAges(defaultQuery.ages);
    };


    const isDefaultQuery = category === defaultQuery.category && surrounding === defaultQuery.surrounding
        && buggyFriendly === defaultQuery.buggyFriendly && costFree === defaultQuery.costFree
        && parents === defaultQuery.parents && (_.size(ages) === 0 || _.size(ages) === 12);

    return (
        <Modal className="modal d-md-none" show={show} onHide={close}>
            <ModalHeader title="Filter" onClose={close} onReset={reset} isDefaultValue={isDefaultQuery}/>
            <div className="ModalAttributeFilter__content">
                <AgeFilter ages={ages} setAges={setAges}/>
                <AttributeFilter key="surrounding" title="Umgebung" options={SURROUNDING_OPTIONS} value={surrounding} onSelect={(value) => {
                        surrounding === value ? setSurrounding(undefined)
                        : setSurrounding(value as string)
                    }
                }
                />
                <AttributeFilter key="costFree" title="Kosten" options={COSTFREE_OPTIONS} value={costFree} onSelect={(value) => {
                    costFree === value ? setCostFree(undefined) : setCostFree(value as boolean)
                }
                }
                />
                <AttributeFilter key="buggyFriendly" title="Buggytauglich" options={BUGGYFRIENDLY_OPTIONS} value={buggyFriendly} onSelect={(value) => {
                        buggyFriendly === value ? setBuggyFriendly(undefined) : setBuggyFriendly(value as boolean)
                    }
                }
                />
                <AttributeFilter key="category" title="Kategorien" options={CATEGORY_OPTIONS} value={category} onSelect={(value) => {
                    category === value || value === undefined ? setCategory(undefined) : setCategory(value as string)
                }
                }
                />
            </div>
            <ModalFilterFooter onSelect={closeFilter} />
        </Modal>
    );
};

export default ModalAttributeFilter;
