import React from 'react';
import './Openingtime.scss';
import { OpeningTime, Time } from '../../models/Dates.model';
import _ from 'lodash';
import { getStyleForCategory } from '../../utils/CategoryUtils';
import { useTranslation } from 'react-i18next';

type OpeningTimeProps = {
    category: string,
    openingTime: OpeningTime,
    index: number
}

const Openingtime = ({ category, openingTime, index }: OpeningTimeProps) => {

  const { t } = useTranslation();

  return (
      <React.Fragment>
      { openingTime &&
        <div className={"Openingtime"} key={index}>
          <div className={"Openingtime__weekday " + getStyleForCategory(category)}>{t(`WEEKDAY_${index}`)}</div>
            {openingTime.open === false &&
            <div>Geschlossen</div>
            }
            {openingTime.open === true &&
            <div>{_.join(_.map(openingTime.times, (time: Time, timeIdx: number) => `${openingTime.times[timeIdx].from} - ${openingTime.times[timeIdx].to}`), ' / ')}
            </div>
            }
        </div>
      }
      </React.Fragment>
  );
};

export default Openingtime;
