//TODO: Find better solution than text keys

export const getStyleForCategoryBg = (category: string) => {
    return getStyleForCategory(category) + "__bg";
};

export const getStyleForCategory = (category: string) => {
    switch (category) {
        case "Shoppen":
            return "Category-shopping";
        case "Baby & Me":
            return "Category-baby-me";
        case "Feste & Anlässe":
            return "Category-events";
        case "Bewegung & Spiel":
            return "Category-move-play";
        case "Wissen":
            return "Category-knowledge";
        case "Naturerlebnis":
            return "Category-nature";
        case "Musik, Kunst & Kultur":
            return "Category-music-culture";
        case "Handarbeit & Werken":
            return "Category-handwork";
        default:
            return "Category-unknown";
    }
};


export const getPinForCategory = (category: string) => {
    switch (category) {
        case "Shoppen":
            return "Shoppen";
        case "Baby & Me":
            return "BabyMe";
        case "Feste & Anlässe":
            return "FesteAnlaesse";
        case "Bewegung & Spiel":
            return "BewegungSpiel";
        case "Wissen":
            return "Wissen";
        case "Naturerlebis":
            return "Naturerlebnis";
        case "Musik, Kunst & Kultur":
            return "MusikKunstKultur";
        default:
            return "HandarbeitWerken";
    }
};
