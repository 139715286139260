import React from 'react';
import './AgeFilter.scss';
import _ from 'lodash';
import Chip from '../Chip/Chip';
import { ALL_AGES } from '../../utils/SearchUtils';

type AgeFilterProps = {
    ages: number[] | undefined,
    setAges: (ages: number[] | undefined) => void,
}

const AgeFilter = ({ ages, setAges }: AgeFilterProps) => {
    const isAllSelected = ():boolean => ages === undefined || ages.length === 0 || _.isEqual([...ages].sort(), ALL_AGES);

    const selectAge = (age: number) => setAges(_.includes(ages, age) ?
        _.filter(ages, (o) => o !== age) : _.union(ages, [age] as number[]));
  return (
      <div className="AgeFilter">
          <div className="AttributeFilter__title">
              Alter
          </div>
          <div className="AgeFilter__ages">
              {_.map(ALL_AGES, (age: number) => (
                  <Chip key={age} title={String(age)} active={_.includes(ages, age)} onSelect={() => selectAge(age)} />
              ))}
          </div>
      </div>
  );
};

export default AgeFilter;
