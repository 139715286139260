import React from 'react';
import './AttributeFilter.scss';
import _ from 'lodash';
import AttributeChip from './AttributeChip/AttributeChip';
import AttributeOption from '../../models/AttributeOption.model';

type AttributeFilterProps = {
    value: string | boolean | undefined,
    title: string,
    options: AttributeOption[],
    onSelect: (option: string | boolean | undefined) => void
}

const AttributeFilter = ({value, title, options, onSelect}: AttributeFilterProps) => {

    const isActive = (attributeValue: any) => value === attributeValue;

    return (
        <div className="AttributeFilter">
            <div className="AttributeFilter__title">
                {title}
            </div>
            <div className="AttributeFilter__options">
                {_.map(options, (option, index) => (
                    <AttributeChip key={index} title={option.label} active={isActive(option.value)}
                                   onSelect={() => onSelect(option.value)}/>
                ))}
            </div>
        </div>
    );
};

export default AttributeFilter;
