import React from 'react';
import './ModalDonation.scss';
import Modal from "react-bootstrap/Modal";
import ModalHeader from '../ModalHeader/ModalHeader';
import { useTranslation } from 'react-i18next';

type ModalDonationProps = {
    show: boolean,
    close: () => void
}

const ModalDonation = ({show, close}: ModalDonationProps) => {
    return (
        <Modal className="modal" size="lg" show={show} onHide={close}>
            <ModalHeader title="Wie kann ich wow-wow unterstützen?" onClose={close}/>
            <div className="ModalDonation__content">
                <p>
                    Deine Spende fördert aktiv die Idee des Vereins «wow-wow», dein Kinderkompass von 0-12+ Jahren für alle zugänglich zu machen. Maximale kulturelle Teilhabe für Kinder. Vielen Dank für Deine Unterstützung!
                </p>
                <p className="ModalDonation__content__address">
                    Verein wow-wow Spiegelgasse 5, 8001 Zürich<br/>
                    Spendenkonto: Zürcher Kantonalbank <br/>CH73 0070 0114 8028 1519 9<br/>
                </p>
                <div className="ModalDonation__content__link">
                    <a href="https://donorbox.org/wow-wow" target="_blank">Hier kann man direkt spenden</a>
                </div>
                <p>
                    Noch Fragen? Melde dich bei:<br/>
                </p>
                <div className="ModalDonation__content__link">
                    <a href="mailto:anfrage@wow-wow.ch">anfrage@wow-wow.ch</a>
                </div>
            </div>
        </Modal>
    );
};

export default ModalDonation;
