import React, { useContext } from 'react';
import { AppContext } from '../Provider';
import BookmarkContent from '../components/BookmarkContent/BookmarkContent';
import Layout from '../components/Layout/Layout';

const Bookmarks = () => {

    const {bookmarkedActivities} = useContext(AppContext)!;

    return (
     <Layout searchBar={false} pageHeaderOnMobile={true} pageHeaderTitle="Merkliste">
         <div className="fullwidth white">
             <div className="centered">
                 <BookmarkContent bookmarkedActivities={bookmarkedActivities}/>
             </div>
         </div>
     </Layout>
  );
};

export default Bookmarks;
